import { AbsValveCalculationProcess } from "./AbsValveCalculationProcess";
import { ECalculationNotes } from "../../../errors_and_warnings/notes";
import { EUnits } from "../../../../enums/Units.enum";
import { UnitsConverter } from "../../../../UnitsConverter/UnitsConverter";

export class ValveDiameter extends AbsValveCalculationProcess {
  public calculate(calculationData: any) {
    calculationData.notes = [];
    calculationData.warnings = [];
    calculationData.errors = [];

    const {
      notes,
      warnings,
      errors,
      units,
      family,
      flowRate,
      maximumAllowedPressureLoss,
      limitedPressureLoss,
    } = calculationData;

    // request values from client side
    const requestParameters = {
      valve_family: family,
      flowRate,
      maximumAllowedPressureLoss,
      limitedPressureLoss,
    };

    if (units === EUnits.US_UNITS) {
      // if units are US then convert flowRate back to US units to send to the client
      requestParameters.flowRate = UnitsConverter.cubicToGallon(
        flowRate,
        false
      );
    }

    this.calculatePressureLossAndUpdateValves(calculationData);

    const filteredValves =
      this.filterValvesByMaxAndLimitedPressureLossValues(calculationData);

    // if no valves found add no valve found note
    if (filteredValves.length === 0 || !filteredValves) {
      this.addNote(
        notes,
        ECalculationNotes.NO_VALVE_FOUND_VALVE_DIAMETER_CALCULATION
      );
    } else {
      // if some valves found add default no angles included note
      this.addDefaultNoAnglesIncludedNote(notes);
    }

    const sortedValves = this.sortValves(filteredValves);

    const calcResults = {
      calculationResults: {
        valves: this.reduceValvesForResults(sortedValves, units),
        notes,
        warnings,
        errors,
        requestParameters,
      },
    };

    return { calcResults };
  }

  /**
   * Calculate pressure loss for each valve
   * and add to valve object
   */
  protected calculatePressureLossAndUpdateValves = (calculationData: any) => {
    const { valves, units } = calculationData;
    let { flowRate } = calculationData;
    if (units === EUnits.US_UNITS) {
      // if units are US then convert flowRate back to US units
      flowRate = UnitsConverter.cubicToGallon(flowRate, false);
    }

    for (let i = 0; i < valves.length; i++) {
      const valve = valves[i];
      const pressureLoss = Number(
        this.getPressureLoss({ valve, units, flowRate })
      ).toFixed(2);
      valve.pressure_loss = pressureLoss;
      valve.flow_rate = flowRate;
    }
  };

  /**
   * Filter valves.
   * get the valves with calculated pressure loss between limited pressure loss
   * and maximum allowed pressure loss
   */
  filterValvesByMaxAndLimitedPressureLossValues = (calculationData: any) => {
    const { limitedPressureLoss, maximumAllowedPressureLoss, valves } =
      calculationData;
    const filteredValves = valves.filter(
      ({ pressure_loss }) =>
        pressure_loss <= maximumAllowedPressureLoss &&
        pressure_loss >= limitedPressureLoss
    );
    return filteredValves;
  };

  /**
   * Sort valves
   * 1. Sort by Valve model (sort by name – A to Z)
   * 2. Sort by Valve diameter (mm) (sort by number – smallest to biggest.
   */
  protected sortValves = (valves: any) => {
    const sorted = valves.sort(this.compareValves);
    return sorted;
  };

  /**
   * Compare function for valves sorting
   */
  private compareValves = (valve1: any, valve2: any) => {
    const { valve_model: model1 } = valve1;
    const { valve_model: model2 } = valve2;
    if (model1.toLowerCase() < model2.toLowerCase()) {
      return -1;
    }
    if (model1.toLowerCase() > model2.toLowerCase()) {
      return 1;
    }
    // if valve model is the same for the compared valves
    // then compare by diameter
    return this.compareValveDiameter(valve1, valve2);
  };

  /**
   * Compare diameters function for valves sorting
   */
  private compareValveDiameter = (valve1: any, valve2: any) => {
    const { diameter_mm: diameter_1 } = valve1;
    const { diameter_mm: diameter_2 } = valve2;
    const diameter1 = parseInt(diameter_1, 10);
    const diameter2 = parseInt(diameter_2, 10);
    if (diameter1 < diameter2) {
      return -1;
    }
    if (diameter1 > diameter2) {
      return 1;
    }
    return 0;
  };

  /**
   * return valves with only the props that are relevant for results
   */
  private reduceValvesForResults = (valves: any, units: any) => {
    return valves.map(
      ({
        valve_family,
        valve_model,
        valve_material,
        kv,
        cv,
        diameter_inch,
        diameter_mm,
        pressure_loss,
        flow_rate,
      }) => ({
        valve_family,
        valve_model,
        valve_material,
        ...(units === EUnits.METRIC && { kv }),
        ...(units === EUnits.US_UNITS && { cv }),
        diameter_inch,
        diameter_mm,
        pressure_loss,
        flow_rate,
      })
    );
  };
}
