enum ECalculationWarnings {
    NONE = 0,
    PIPE_MAX_ALLOWED_PRESSURE,
    PIPE_MAX_ALLOWED_VELOCITY,
    USER_MAX_PRESSURE_LOSS_DEVIATION_WITH_EXTRA,
    MULTIPLE_PIPES,

    PRESSURE_LOSS_EXCEEDED_WARNING,
    FLOW_VARIATION_WARNING,
    EMISSION_UNIFORMITY_WARNING,
    TRAVEL_TIME_WARNING,
    EMITTER_DESIGN_PRESSURE_WARNING,
    EMITTER_TECHNICAL_PRESSURE_WARNING,

    FLUSHING_INLET_PRESSURE_BIGGER_THAN_INLET_PRRESURE_INPUT,

    INLET_PRESSURE_OUT_OF_USER_RANGE,
    NEGATIVE_PRESSURE,

    RECOMMENDED_FLOW_RATE_FOR_VALVE,

    CAVITATION_RISK_UPSTREAM_PRESSURE_IS_TOO_HIGH_METRIC,
    CAVITATION_RISK_UPSTREAM_PRESSURE_IS_TOO_HIGH_US_UNITS,
}

let warnings = [
    { Id: ECalculationWarnings.NONE, warning: `NONE`, id_translation: -1 },
    { Id: ECalculationWarnings.PIPE_MAX_ALLOWED_PRESSURE, warning: `Pressure exceeds the pipe's maximum allowed working pressure, recommended to change pipe's class or type or change flushing velocity.`, id_translation: 161 },
    { Id: ECalculationWarnings.PIPE_MAX_ALLOWED_VELOCITY, warning: `Velocity exceeds the pipe's allowed velocity, recommended to change pipe's type or flow rate.`, id_translation: 160 },
    { Id: ECalculationWarnings.USER_MAX_PRESSURE_LOSS_DEVIATION_WITH_EXTRA, warning: `Pressure loss result is deviating from user input for maximum pressure loss in less than 2.5%, recommended to follow the result despite the deviation.`, id_translation: 163 },
    { Id: ECalculationWarnings.MULTIPLE_PIPES, warning: `Results are based purely on hydraulic performance, if pipes length doesn’t suit your requirements – use one pipe along the submain using the largest diameter from results or switch to “pressure loss for selected pipe” question and review telescopic pipe performance in different lengths.`, id_translation: 162 },
    { Id: ECalculationWarnings.PRESSURE_LOSS_EXCEEDED_WARNING, warning: `Pressure loss exceeds the pipe's recommended pressure loss.`, id_translation: 424 },
    { Id: ECalculationWarnings.FLOW_VARIATION_WARNING, warning: `Flow variation exceeds recommended range.`, id_translation: 425 },
    { Id: ECalculationWarnings.EMISSION_UNIFORMITY_WARNING, warning: `Emission uniformity exceeds recommended range.`, id_translation: 426 },
    { Id: ECalculationWarnings.TRAVEL_TIME_WARNING, warning: `Travel time exceeds recommended time.`, id_translation: 431 },
    { Id: ECalculationWarnings.EMITTER_DESIGN_PRESSURE_WARNING, warning: `Value is technically possible but exceeds emitter's recommended working pressure range.`, id_translation: 427 },
    { Id: ECalculationWarnings.EMITTER_TECHNICAL_PRESSURE_WARNING, warning: `Value exceeds emitter technical pressure range.`, id_translation: 428 },
    { Id: ECalculationWarnings.FLUSHING_INLET_PRESSURE_BIGGER_THAN_INLET_PRRESURE_INPUT, warning: `Inlet pressure required for flushing is higher than inlet pressure input. Either change inlet pressure when in flushing mode or work with shorter pipes to maintain inlet pressure input also in flushing mode.`, id_translation: 649 },
    { Id: ECalculationWarnings.INLET_PRESSURE_OUT_OF_USER_RANGE, warning: `HydroCalc found no results with the inputs' entered. The results displayed are based on the closest possible inputs`, id_translation: 725 },
    { Id: ECalculationWarnings.NEGATIVE_PRESSURE, warning: `Pressure along the pipes is not operational. Please review and change inlet/ end pressure accordingly`, id_translation: 744 },
    { Id: ECalculationWarnings.RECOMMENDED_FLOW_RATE_FOR_VALVE, warning: `Note that the recommended flow rate range for the valve is {min} - {max} {units}. `, id_translation: 805 },
    { Id: ECalculationWarnings.CAVITATION_RISK_UPSTREAM_PRESSURE_IS_TOO_HIGH_METRIC, warning: `Upstream pressure is above 250. Contact us for this calculation`, id_translation: 913 },
    { Id: ECalculationWarnings.CAVITATION_RISK_UPSTREAM_PRESSURE_IS_TOO_HIGH_US_UNITS, warning: `Upstream pressure is above 375. Contact us for this calculation`, id_translation: 914 },
]


class CalculationWarnings {
    public static get(warningId: ECalculationWarnings) {
        return warnings[warningId];
    }
}

export { CalculationWarnings, ECalculationWarnings }