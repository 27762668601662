import { AbsValveCalculationProcess } from "./AbsValveCalculationProcess";
import { ECalculationNotes } from "../../../errors_and_warnings/notes";
import { EUnits } from "../../../../enums/Units.enum";
import { ECalculationWarnings } from "../../../errors_and_warnings/warnings";

export class ValveCavitationRisk extends AbsValveCalculationProcess {
    private readonly CAVITATION_RISK_MIN_VALUE = 1.5001;

    public calculate(calculationData: any) {
        calculationData.notes = [];
        calculationData.warnings = [];
        calculationData.errors = [];

        const {
            notes,
            warnings,
            errors,
            upstreamPressure,
            units
        } = calculationData;

        const cavitationRisk = this.calculateCavitationRisk(calculationData);

        const maxUpstreamPressure = units === EUnits.US_UNITS ? 375 : 250;
        if (upstreamPressure > maxUpstreamPressure) {
            const warning = units === EUnits.US_UNITS ? ECalculationWarnings.CAVITATION_RISK_UPSTREAM_PRESSURE_IS_TOO_HIGH_US_UNITS : ECalculationWarnings.CAVITATION_RISK_UPSTREAM_PRESSURE_IS_TOO_HIGH_METRIC;
            this.addWarning(warnings, warning);
        } else {
            if (cavitationRisk < this.CAVITATION_RISK_MIN_VALUE) {
                this.addNote(notes, ECalculationNotes.CAVITATION_RISK_THERE_MIGHT_BE_A_CAVITATION_RISK);
            } else {
                this.addNote(notes, ECalculationNotes.NO_CAVITATION_RISK);
            }
        }

        const calcResults = {
            calculationResults: {
                notes,
                warnings,
                errors,
            }
        };

        return { calcResults };
    }

    private calculateCavitationRisk(calculationData: any) {
        const { upstreamPressure, downstreamPressure, units } = calculationData;
        let cavitationRisk = 0;
        switch (units) {
            case EUnits.US_UNITS:
                cavitationRisk = this.calculateCavitationRiskUS(upstreamPressure, downstreamPressure);
                break;
            case EUnits.METRIC:
                cavitationRisk = this.calculateCavitationRiskMetric(upstreamPressure, downstreamPressure);
                break;
        }
        return cavitationRisk;
    }

    private calculateCavitationRiskUS(upstreamPressure: number, downstreamPressure: number): number {
        return (upstreamPressure + 13) / (upstreamPressure - downstreamPressure);
    }

    private calculateCavitationRiskMetric(upstreamPressure: number, downstreamPressure: number): number {
        return (upstreamPressure + 9) / (upstreamPressure - downstreamPressure);
    }
}