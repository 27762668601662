import { AbsCalculationProcess } from "../AbsCalculationProcess";
import { Segment } from "../../Segment";
import { PipeSection } from "../../PipeSection";
import { LateralCalculationType } from "../../../enums/LateralCalculationType.enum";
import { ErrorMsgs } from "../../../../models/Errors/ErrorMsgs";
import { SlopeSection } from "../../SlopeSection";
import { AbsLateralCalculationProcess } from "./AbsLateralCalculationProcess";
import { CalculationErrors, ECalculationErrors } from "../../../errors_and_warnings/errors";
import { BaseMaxLengthCaculationProcess } from "../BaseMaxLengthCaculationProcess";
import { EmitterSection } from "../../../../calculator/Entities/EmitterSection";
import { ECalculationWarnings } from "../../../errors_and_warnings/warnings";

export class MaxLengthForNonPCEmitters extends AbsLateralCalculationProcess {
    baseMaxLengthCaculationProcess: BaseMaxLengthCaculationProcess = new BaseMaxLengthCaculationProcess();

    public initSegments(data: any, segments: any, slopes: any, pipes: any, isFlushing: boolean = false) {
        if (isFlushing) {
            // Init all segments:
            let emitters = data.emitters;
            // Add Emitter section propeties to Segments:
            super.addEmitterSectionsToSegments(segments, emitters);
            // Add Slope section propeties to Segments:
            super.addSlopeSectionsToSegments(segments, slopes);
            // Add Pipe section propeties to Segments:
            super.addPipeSectionsToSegments(segments, pipes);
        }
        else {
            // Init 1 segment:
            let emitters = data.emitters;
            // Add Emitter section propeties to Segments:
            this.addEmitterSectionsToSegments(segments, emitters);
            // Add Slope section propeties to Segments:
            this.addSlopeSectionsToSegments(segments, slopes);
            // Add Pipe section propeties to Segments:
            this.addPipeSectionsToSegments(segments, pipes);
        }

    }

    public calculate(segments: any, pipes: any, slopes: any, data: any) {
        let isFlushing = data.isFlushingMode;
        if (isFlushing) {
            // Flushing calculation:
            return this.calcPressureLossForAGivenLateral(segments, pipes, slopes, data);
        }
        else {
            //Pipes max length for pc emitters:
            return this.calcMaxLengthForNonPCEmitters(segments, pipes, slopes, data);
        }
    }

    protected handleCalculationStops(data: any): any {
        let calcStopsResponse = {
            error: null,
            warning: null,
            isStopIteration: false,
            isCalcDone: false
        }
        let { pipes, slopes, calculationType, lengthSum,
            currSegment, segments, maxFlowRate, minFlowRate, maxFV, maxEU, spacing, index,
            isEndPressureCalculation, inletPressureUI, isFlushingMode,isCalcByFlowVariation } = data;
        let emitter = currSegment.EmitterSection;
        let emissionUniformityFromUser = maxEU;
        let totalLength: Number = data.totalLengthForCurrentSegments + data.spacing; //total submain length with current segment 

        if (calculationType == LateralCalculationType.MAX_LENGTH_FOR_NON_PC_EMITTERS) {
            // Design/Technical pressures:
            let currSegmentInletPressure = currSegment.InletPressure;
            let currEmitter = currSegment.EmitterSection;
            if (currSegmentInletPressure > currEmitter.MaxAllowedPressure) {
                if (currSegmentInletPressure > currEmitter.MaxTechnicalPressure) {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_TECHNICAL_PRESSURE_WARNING;
                }
                else {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_DESIGN_PRESSURE_WARNING;
                }
            }
            // Minimum pressures:
            if (currSegmentInletPressure < currEmitter.MinAllowedPressure) {
                if (currSegmentInletPressure > currEmitter.MinTechnicalPressure) {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_TECHNICAL_PRESSURE_WARNING;
                }
                else {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_DESIGN_PRESSURE_WARNING;
                }
            }
            let currFV = this.calcFlowVariation(minFlowRate, maxFlowRate);

            if (currSegment.Id == 1) {
                // Last segment, check EU:
                let sum = 0;

                segments.map(s => { sum = Math.round((sum + s.Length) * 1e12) / 1e12 });

                let numOfEmitters = Math.floor(sum / spacing);
                let emittersAvgFlowRate: any = Number((((segments[0].FlowRate) * 1000) / numOfEmitters));
                let cv = emitter.cv ? Number(emitter.cv) : emitter.CV;
                let currEU = this.calcEmissionUniformity(cv, minFlowRate * 1000, emittersAvgFlowRate);

                // Emission Uniformity::
                if (emissionUniformityFromUser && (currEU < emissionUniformityFromUser)) {
                    // End calculation with 1 segment shorter:
                    if (segments.length <= 0) {
                        // No Results
                        calcStopsResponse.error = ECalculationErrors.NO_RESULTS;
                    }
                    else {
                        // Set slopes and pipes sections data:
                        this.setSectionsAggregatedData({ pipes, slopes, segments });
                    }
                    // End Calculation
                    calcStopsResponse.isCalcDone = true;
                    calcStopsResponse.isStopIteration = true;
                    return calcStopsResponse;
                }
            }
            //add extra 0.5 to maxFV
            maxFV = Number(maxFV) + 0.5

            // Flow variation stop or max length lateral stop or Inlet pressure UI stop:
            if (((maxFV && (currFV > maxFV) && isCalcByFlowVariation) || Number(AbsCalculationProcess.MAX_LENGTH_LATERAL) < totalLength && index == 0)){
          
              // End calculation with 1 segment shorter:
                let shiftedSeg = segments.shift();
                if (segments.length <= 0) {
                    // No Results
                    calcStopsResponse.error = ECalculationErrors.NO_RESULTS;
                }
                else {
                    // Set slopes and pipes sections data:
                    this.setSectionsAggregatedData({ pipes, slopes, segments, shiftedSeg });
                }
                // End Calculation
                calcStopsResponse.isCalcDone = true;
                calcStopsResponse.isStopIteration = true;
                return calcStopsResponse;
            }
        }
        else if (calculationType == LateralCalculationType.FV_EU_FOR_SELECTED_LATERAL) {
            // Flushing calculation
            let currSegmentInletPressure = currSegment.InletPressure;
            let currEmitter = currSegment.EmitterSection;
            if (currSegmentInletPressure > currEmitter.MaxAllowedPressure) {
                if (currSegmentInletPressure > currEmitter.MaxTechnicalPressure) {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_TECHNICAL_PRESSURE_WARNING;
                }
                else {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_DESIGN_PRESSURE_WARNING;
                }
            }
        }

        if (this.isSegmentIsOnLateral(lengthSum, data.spacing)) {
            this.setSegmentTravelTime(currSegment, data.spacing);
        }

        return calcStopsResponse;
    }

    protected postCalcActions(data: any): any {
        let calcStopsResponse = {
            error: null,
            warning: null,
            isStopIteration: false,
            isCalcDone: false
        }
        let { calculationType, segmentsCalcData } = data;
        let minFlowRate = segmentsCalcData.minFlowRate;
        let maxFlowRate = segmentsCalcData.maxFlowRate;

        // Calc Flow variation:
        let flowVariation = this.calcFlowVariation(minFlowRate, maxFlowRate);
        let fvFromUser = segmentsCalcData.calcData.maxAllowedFlowVariation || null;
        if (fvFromUser && flowVariation / fvFromUser > 0.995) {
            // calculated fv is close as 0.5%, show user input:
            flowVariation = fvFromUser;
        }
        segmentsCalcData.calcData.flowVariation = flowVariation;

        if (calculationType == LateralCalculationType.MAX_LENGTH_FOR_NON_PC_EMITTERS) {
            calcStopsResponse.isStopIteration = true;
        }

        return calcStopsResponse;
    }

    public createCalculationSegments(data: any, blockChars: any, topographyChars: any, pipeChars: any, isFlushing: boolean, oldSegments: any = null) {
        return this.baseMaxLengthCaculationProcess.createCalculationSegments(data, blockChars, topographyChars, pipeChars, isFlushing, oldSegments);
    }

    protected setTopoChars(data: any, _slopes: any) {
        this.baseMaxLengthCaculationProcess.setTopoChars(data, _slopes);
    }

    protected addSlopeSectionsToSegments(segments: Segment[], slopes: SlopeSection[]) {
        this.baseMaxLengthCaculationProcess.addSlopeSectionsToSegments(segments, slopes);
    }

    protected addPipeSectionsToSegments(segments: Segment[], pipes: PipeSection[]) {
        this.baseMaxLengthCaculationProcess.addPipeSectionsToSegments(segments, pipes);
    }

    protected addEmitterSectionsToSegments(segments: Segment[], emitters: EmitterSection[]) {
        this.baseMaxLengthCaculationProcess.addEmitterSectionsToSegments(segments, emitters);
    }

    // ---------------------------------------- PRAIVATE METHODS ----------------------------------------

    private calcMaxLengthForNonPCEmitters(segments: Segment[], pipes: PipeSection[], slopes: SlopeSection[], calcData: any) {
        let lastEmitter = calcData.emitters[calcData.emitters.length - 1];
        let lastFlowRate = this.calcLastEmitterFlowRate(lastEmitter, calcData.blockChars.end_pressure);
        let isCalcDone = false;
        let systemPL: number;
        let emitter = calcData.emitters[0];
        let spacing = Number(calcData.blockChars.distance_between_laterals);
        // Set segment calculation data:        
        let segmentsCalcData: any = { calcData, pipes, slopes, segments, lastFlowRate, type: LateralCalculationType.MAX_LENGTH_FOR_NON_PC_EMITTERS };
        let segment_index_for_desired_inlet_pressure: number;
        // differentiate between calc by Flow Variation and Emission uniformity 
        calcData.isCalcByFlowVariation = calcData.maxFV !== 0
        
        do {
            // Init CalculationData:
            this.resetSegmentsCalcData(segmentsCalcData);
            calcData.emitter = emitter;
            // set sections length:
            pipes[0].SectionLength = segments.length * calcData.blockChars.distance_between_laterals;
            pipes[0].SectionLength = Number(pipes[0].SectionLength.toFixed(2));
            slopes[0].SectionLengthOnMap = segments.length * calcData.blockChars.distance_between_laterals;
            slopes[0].SectionLengthOnMap = Number(slopes[0].SectionLengthOnMap.toFixed(2));
            slopes[0].SectionRealLength = slopes[0].SectionLengthOnMap;
            slopes[0].SectionRealLength = Number(slopes[0].SectionRealLength.toFixed(2));

            // Handle segments calculation:
            let res = this.handleSegmentsCalculation(segmentsCalcData);
            isCalcDone = res.isCalcDone;
            systemPL = res.systemPL;
            segment_index_for_desired_inlet_pressure = res.segment_index_for_desired_inlet_pressure

            if (!isCalcDone) {
                // Add Segment:
                let seg = new Segment(1);
                seg.Length = calcData.blockChars.distance_between_laterals;
                seg.PipeSection = pipes[0];
                seg.SlopeSection = slopes[0];
                seg.EmitterSection = emitter;
                segments.unshift(seg);
            }
        } while (!isCalcDone);

        let results: any = {};

        let err = segmentsCalcData.errors || [];
        if (err.length > 0) {
            results.errors = err;
            return results;
        }

        results = this.summarizeResults(segments, pipes, systemPL, calcData.maxVelocity, calcData.isFlushingMode, calcData.end_velocity, segment_index_for_desired_inlet_pressure);
        pipes.forEach(pipe => {
            pipe.MaxFlowRate *= 1000;
        });
        results.emitter = emitter;
        results.flowVariation = calcData.flowVariation;
        results.endPressure = pipes[pipes.length - 1].EndPressure;
        results.totalLength = Number(pipes[0].SectionLength.toFixed(2));
        let sum = 0;
        segments.map(s => { sum = Math.round((sum + s.Length) * 1e12) / 1e12 });

        let numOfEmitters = Math.floor(sum / spacing);
        results.numOfEmitters = numOfEmitters;
        let avgFlowRate: any = Number((((segments[0].FlowRate) * 1000) / numOfEmitters));
        let cv = lastEmitter.cv ? Number(lastEmitter.cv) : lastEmitter.CV;
        results.emissionUniformity = this.calcEmissionUniformity(cv, segmentsCalcData.minFlowRate * 1000, avgFlowRate)    
        results.emittersAvgFlowRate = Number(avgFlowRate.toFixed(2));
        results.travelTime = this.calcTravelTime(segments, spacing);

        // Lateral warnings:
        if (results.totalPressureLoss > AbsLateralCalculationProcess.MAX_TOTAL_PL) {
            this.addWarning(results.warnings, ECalculationWarnings.PRESSURE_LOSS_EXCEEDED_WARNING);
        }
        if (results.flowVariation > AbsLateralCalculationProcess.MAX_TOTAL_FV) {
            this.addWarning(results.warnings, ECalculationWarnings.FLOW_VARIATION_WARNING);
        }
        if (results.emissionUniformity < AbsLateralCalculationProcess.MIN_TOTAL_EU) {
            this.addWarning(results.warnings, ECalculationWarnings.EMISSION_UNIFORMITY_WARNING);
        }
        // if (results.travelTime > AbsLateralCalculationProcess.MAX_TOTAL_TRAVEL_TIME) {
        //     this.addWarning(results.warnings, ECalculationWarnings.TRAVEL_TIME_WARNING);
        // }

        let diffMeters = results.totalLength * (slopes[0].HeightDiffPrecent / 100);

        slopes[0].HeightDiffInMeters = Number(diffMeters.toFixed(2));

        segmentsCalcData.warnings.forEach(warn => {
            results.warnings.push(warn);
        });

        return results;

    }

    private checkUiInletPressureStop(isFlushingMode, isEndPressureCalculation, inletPressureUI, currSegmentInletPressure) {
        return (!isFlushingMode && !isEndPressureCalculation && inletPressureUI && currSegmentInletPressure > inletPressureUI)
    }

}