import { RegionsDAL } from "../DAL/RegionsDAL";
import { FlushingVelocityDAL } from "../DAL/FlushingVelocityDAL";
import { EUnits } from "../enums/Units.enum";
import { UnitsConverter } from "../UnitsConverter/UnitsConverter";
import { LanguagesDAL } from "../DAL/LanguagesDAL";
import { TranslationsDAL } from "../DAL/TranslationsDAL";
import { ELanguages } from "../enums/Languages.enum";
import { Regions } from "../enums/Regions.enum";
let AWS = require('aws-sdk');
import axios, { AxiosRequestConfig } from 'axios'
export class GeneralService {
    public async getCountries(user: any = null): Promise<any> { //TODO CHANGE ORAN
        let regionsDAL: RegionsDAL = new RegionsDAL();
        let translationsDAL: TranslationsDAL = new TranslationsDAL();
        let isGetTranslation = user && user.language != ELanguages.ENGLISH;
        
        let tasks = [
            regionsDAL.getCountries()
        ]

        if (isGetTranslation) {
            let language = user.language;
            if (language != ELanguages.ENGLISH) {
                tasks.push(translationsDAL.getTranslationsByLanguage(language));
            }
        }

        let res = await Promise.all(tasks);
        let countries = res[0];

        // if (isGetTranslation) {
        //     let translations = res[1];
        //     translations = translations.filter(tr => tr.group_id == 2);
        //     // Set region name translation:
        //     regions.forEach(reg => {
        //         let idStr = this.getIdStrByRegion(reg.id);
        //         let trans = translations.filter(t => t.translation_id_str == idStr)[0];
        //         reg.region_name = trans.value !== ' ' ? trans.value : reg.region_name;
        //     });
        // }
        return countries;
    }
    public async getRegions(user: any = null, isOffline: boolean = false): Promise<any> {

        let regionsDAL: RegionsDAL = new RegionsDAL();
        let translationsDAL: TranslationsDAL = new TranslationsDAL();
        let isGetTranslation = user && user.language != ELanguages.ENGLISH;

        let tasks = [
            regionsDAL.getRegions(isOffline)
        ]

        if (isGetTranslation) {
            let language = user.language;
            if (language != ELanguages.ENGLISH) {
                tasks.push(translationsDAL.getTranslationsByLanguage(language, isOffline));
            }
        }

        let res = await Promise.all(tasks);
        let regions = res[0];

        if (isGetTranslation) {
            let translations = res[1];
            translations = translations.filter(tr => tr.group_id == 2);
            // Set region name translation:
            regions.forEach(reg => {
                let idStr = this.getIdStrByRegion(reg.id);
                let trans = translations.filter(t => t.translation_id_str == idStr)[0];
                reg.region_name = trans.value !== ' ' ? trans.value : reg.region_name;
            });
        }
        return regions;
    }

    public async getRegion(regionId: any): Promise<any> {
        let regionsDAL: RegionsDAL = new RegionsDAL();

        let region = await regionsDAL.getRegion(regionId);
        return region;
    }

    public async getLanguages(isOffline: boolean): Promise<any> {
        let languagesDAL: LanguagesDAL = new LanguagesDAL();

        let languages = await languagesDAL.getLanguages(isOffline);
        // Remove Korean && jappanesse:
        languages = languages.filter(l => l.row_id !== ELanguages.KOREAN.toString() && l.row_id !== ELanguages.DUTCH.toString())
        return languages;
    }

    public async getFlushingVelocityTable(units: any, isOffline?): Promise<any> {
        let flushingVelocityDAL = new FlushingVelocityDAL();

        let table = await flushingVelocityDAL.getFlushingVelocityTable(isOffline);
        if (units == EUnits.US_UNITS) {
            table = UnitsConverter.converFlushingVelocityTable(table);
        }
        return table;
    }

    public async sendUserMarketingSystem(serviceData: any): Promise<any> {

         // don't use Eloqua for now

    //     let host = process.env.Eloqua_siteBaseUrl;
    //     let path = process.env.Eloqua_addContactUrl;
    //     let auth = process.env.Eloqua_authToken

    //     const data = {
    //         firstName: serviceData.firstName,
    //         lastName: serviceData.lastName,
    //         emailAddress: serviceData.email,
    //         country: serviceData.region,
    //         fieldValues: [
    //             // Company
    //             { id: "100004", value: serviceData.company },
    //             // Position
    //             { id: "100200", value: serviceData.position },
    //             // Is Receive updates:
    //             { id: "100228", value: serviceData.isReceiveUpdates },
    //             {
    //                 _comment: "HydroCalc",
    //                 id: "100045",
    //                 value: "HydroCalc"
    //             }
    //         ]
    //     }

    //     const options: AxiosRequestConfig = {
    //         method: 'POST',
    //         data,
    //         url: host + path,
    //         headers: {
    //             'Authorization': auth
    //         }
    //     }

     
    // let res = await axios(options)// request(options);

        return true;
    }

    public async setContentToPreviewToRegion(serviceData: any): Promise<any> {
        let regionsDAL: RegionsDAL = new RegionsDAL();
        let updateData = { regionId: serviceData.regionId, content: serviceData.content }
        let isUpdated = await regionsDAL.updateRegion(updateData);
        return isUpdated;
    }


    // -------------------------------------- TRANSLATIONS -----------------------------------
    public async getTranslationsByLanguage(languageId: number, isOffline: boolean = false): Promise<any> {
        let translationsDAL: TranslationsDAL = new TranslationsDAL();

        let translations = await translationsDAL.getTranslationsByLanguage(languageId, isOffline);
        return translations;
    }

    public async getEmptyTranslations(): Promise<any> {
        let translationsDAL: TranslationsDAL = new TranslationsDAL();

        let translations = await translationsDAL.getEmptyTranslations();
        return translations;
    }

    public async addNewTranslationToLanguage(transData: any, language: ELanguages) {
        let translationsDAL: TranslationsDAL = new TranslationsDAL();
        transData.language = language;
        let isSucceeded = await translationsDAL.addNewTranslationToLanguage(transData);
        return isSucceeded;
    }

    private getIdStrByRegion(id: number) {
        switch (id) {
            case Regions["Global (corporate)"]:
                return 'region_label_global'
            case Regions["USA"]:
                return 'region_label_USA'
            case Regions["Mexico"]:
                return 'region_label_mexico'
            case Regions["Peru/Ecuador/Colombia"]:
                return 'region_label_latin_america'
            case Regions["Chile"]:
                return 'region_label_chile'
            case Regions["Brazil/Argentina"]:
                return 'region_label_brazil'
            case Regions["West-Europe"]:
                return 'region_label_west_europe'
            case Regions["East-Europe"]:
                return 'region_label_east_europe'
            case Regions["Turkey"]:
                return 'region_label_turkey'
            case Regions["Africa"]:
                return 'region_label_africa'
            case Regions["South-Africa"]:
                return 'region_label_south_africa'
            case Regions["India"]:
                return 'region_label_india'
            case Regions["China"]:
                return 'region_label_china'
            case Regions["Netherlands"]:
                return 'region_label_netherlands'
            case Regions["Asia-Pacific"]:
                return 'region_label_asia_pacific'
            case Regions["Australia"]:
                return 'region_label_australia'
            case Regions["Middle-East"]:
                return 'region_label_middle_east'

            default:
                break;
        }
    }
}

