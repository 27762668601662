import { AbsCalculationProcess } from "../AbsCalculationProcess";
import { LateralCalculationType } from "../../../enums/LateralCalculationType.enum";
import { PipeSection } from "../../PipeSection";
import { Segment } from "../../../Entities/Segment";
import { SlopeSection } from "../../../Entities/SlopeSection";
import { EmitterSection } from "../../../Entities/EmitterSection";
import { ECalculationWarnings } from "../../../errors_and_warnings/warnings";

export abstract class AbsLateralCalculationProcess extends AbsCalculationProcess {
    public static readonly TRAVEL_TIME_A_CO = 3.1416;
    public static readonly MAX_TOTAL_PL = 40;
    public static readonly MAX_TOTAL_FV = 15;
    public static readonly MIN_TOTAL_EU = 85;
    public static readonly MAX_TOTAL_TRAVEL_TIME = 60;
    public static readonly EMITTERS_MAX_VELOCITY = 1.5;

    /**
    * calcLastLateralFlowRateForCalculation
    * calc Last lateral Flow Rate for calculation use - for a given block shape, last lateral/total flow rate and Number of laterals
    * 
    */
    public calcLastLateralFlowRateForCalculation(numOfLaterals: number, isReqtangular: boolean, lastLateralFlowRate: number, totalLateralFlowRate: number = null, lastLateralLength: number = null, firstLateralLength: number = null): number {
        let flowRate: number = (lastLateralFlowRate) / 1000;;

        return flowRate;
    }

    protected calcLastEmitterFlowRate(lastEmitter: any, end_pressure: any) {
        let flowRate = this.calcSegmentFlowRateForNonPCEmitter(lastEmitter, null, end_pressure);

        return flowRate;
    }

    protected calcPressureLossForAGivenLateral(segments: Segment[], pipes: PipeSection[], slopes: SlopeSection[], calcData: any) {
        let endPressure = Number(calcData.blockChars.end_pressure);
        let isCalcDone = false;
        let systemPL: number;
        let isFlushing = calcData.isFlushingMode;
        let lastCalculationSegmentIndex = isFlushing ? segments.length - 2 : segments.length - 1;
        let emitter = calcData.emitters[0];
        let lastEmitter = calcData.emitters[calcData.emitters.length - 1];
        let isPc = lastEmitter.IsEmitterPC;
        let lastFlowRate = isPc ? (Number(calcData.blockChars.last_lateral_flow_rate) / 1000) : this.calcLastEmitterFlowRate(lastEmitter, calcData.blockChars.end_pressure);
        let spacing = Number(calcData.blockChars.distance_between_laterals);
        let lateralLength = this.calcPipeTotalLength(pipes);
        let numOfEmitters = Math.floor(lateralLength / spacing);
        let segment_index_for_desired_inlet_pressure
        let iscalcStopInletPressureUIstop = false

        calcData.lateralLength = lateralLength;
        calcData.spacing = spacing;
        calcData.numOfEmitters = numOfEmitters;
        calcData.emitter = emitter;
        // Init last calculation segment:
        segments[lastCalculationSegmentIndex].FlowRate = lastFlowRate;
        segments[lastCalculationSegmentIndex].AtomicFlowRate = lastFlowRate;
        segments[lastCalculationSegmentIndex].EndPressure = endPressure;
        if (isFlushing) {
            segments[lastCalculationSegmentIndex].FlowRate += calcData.flushing_end_flow_rate;
        }

        // Set segment calculation data:     
        let segmentsCalcData: any = { calcData, pipes, slopes, segments, lastFlowRate, type: LateralCalculationType.PRESSURE_LOSS_FOR_SELECTED_LATERAL };
        do {
            // Handle segments calculation:
            let res = this.handleSegmentsCalculation(segmentsCalcData);
            isCalcDone = res.isCalcDone;
            systemPL = res.systemPL;
            segment_index_for_desired_inlet_pressure = res.segment_index_for_desired_inlet_pressure
            iscalcStopInletPressureUIstop = res.isInletPressureUIstop
        } while (!isCalcDone);


        let results: any = {};

        let err = segmentsCalcData.errors || [];
        if (err.length > 0) {
            results.errors = err;
            return results;
        }

        results = this.summarizeResults(segments, pipes, systemPL, calcData.maxVelocity, calcData.isFlushingMode, calcData.end_velocity, segment_index_for_desired_inlet_pressure);
        pipes.forEach(pipe => {
            pipe.MaxFlowRate *= 1000;
        });
        results.emitter = emitter;
        results.flowVariation = calcData.flowVariation;
        results.endPressure = pipes[pipes.length - 1].EndPressure;

        let sum = 0;
        segments.map(s => { sum = Math.round((sum + s.Length) * 1e12) / 1e12 });
        results.totalLength = iscalcStopInletPressureUIstop ? sum : lateralLength;

        let numOfEmitter = Math.floor(sum / spacing);
        results.numOfEmitters = numOfEmitter;
        let avgFlowRate: any = Number((((segments[0].FlowRate) * 1000) / numOfEmitter));
        let cv = lastEmitter.cv ? Number(lastEmitter.cv) : lastEmitter.CV;
        results.emissionUniformity = this.calcEmissionUniformity(cv, segmentsCalcData.minFlowRate * 1000, avgFlowRate)
        results.emittersAvgFlowRate = Number(avgFlowRate.toFixed(2));
        results.travelTime = this.calcTravelTime(segments, spacing);

        // Lateral warnings:
        if (results.totalPressureLoss > AbsLateralCalculationProcess.MAX_TOTAL_PL) {
            this.addWarning(results.warnings, ECalculationWarnings.PRESSURE_LOSS_EXCEEDED_WARNING);
        }
        if (results.flowVariation > AbsLateralCalculationProcess.MAX_TOTAL_FV) {
            this.addWarning(results.warnings, ECalculationWarnings.FLOW_VARIATION_WARNING);
        }
        if (results.emissionUniformity < AbsLateralCalculationProcess.MIN_TOTAL_EU) {
            this.addWarning(results.warnings, ECalculationWarnings.EMISSION_UNIFORMITY_WARNING);
        }
       
        let diffMeters = results.totalLength * (slopes[0].HeightDiffPrecent / 100);
        slopes[0].HeightDiffInMeters = slopes[0].HeightDiffInMeters ? slopes[0].HeightDiffInMeters : Number(diffMeters.toFixed(2));
        results.errors = segmentsCalcData.errors || [];
        segmentsCalcData.warnings.forEach(warn => {
            results.warnings.push(warn);
        });

        return results;
    }

    /**
     * Gets pressure loss caused by changes in pipe
     * like drippers, connectors, etc. (local friction, stored in database)
     * 
     * @param data 
     */
    protected getLocalHeadLoss(calcData: any, segment: any): number {
        let K = AbsCalculationProcess.K2_LOCAL_LOSS_COEFFICIENT_LATERAL;
        let pipeInternalDiameter = segment.PipeSection.InternalDiameter;
        let flowRate = segment.FlowRate * 1000; // TO l/h 
        let KD = segment.PipeSection.KD || 0;

        return KD * K * Math.pow(flowRate, 2) * Math.pow(pipeInternalDiameter, -4)
    }

    /**
     * Diskin - for Lateral calculations
     * @param flowRate - Q
     * @param pipeInternalDiameter - D
     * @param distanceBetweenLaterals - R
     * @returns  
     */
    protected diskin(data: any): number {
        let flowRate = data.segment.FlowRate;
        let pipeInternalDiameter = data.segment.PipeSection.InternalDiameter;
        let segmentLength = data.segment.Length;
        if (pipeInternalDiameter > 40) {
            return AbsCalculationProcess.DISKIN_COEFFICIENT * Math.pow(flowRate, 1.81) * Math.pow(pipeInternalDiameter, -4.81) * segmentLength;
        }
        else {
            return AbsCalculationProcess.DISKIN_SMALL_DIAMETERS_COEFFICIENT * Math.pow(flowRate * 1000, 1.76) * Math.pow(pipeInternalDiameter, -4.76) * segmentLength; // No flow rate conversion
        }
    }

    /**
     * Hazen - for Lateral calculations
     * 
     * @param flowRate - Q
     * @param pipeInternalDiameter - D
     * @param distanceBetweenLaterals - R   
     * @param roughnessCoefficient - C
     * @returns
     */
    protected hazen(data: any): number {
        let flowRate = data.segment.FlowRate;
        let pipeInternalDiameter = data.segment.PipeSection.InternalDiameter;
        let segmentLength = data.segment.Length;
        let roughnessCoefficient = data.calcData.pipeRoughnessChw || data.segment.PipeSection.Roughness;

        return AbsCalculationProcess.HAZEN_COEFFICIENT * Math.pow((flowRate / roughnessCoefficient), AbsCalculationProcess.HAZEN_POW1_COEFFICIENT) * Math.pow(pipeInternalDiameter, AbsCalculationProcess.HAZEN_POW2_COEFFICIENT) * segmentLength;
    }

    protected calcPrevSegFlowRate(data: any) {
        let { lengthSum, calcData, segments, index, currSegment, lastFlowRate } = data;
        let emitter = currSegment.EmitterSection;

        if (!segments[index - 1].FlowRate) {
            if (this.isSegmentIsOnLateral(lengthSum, calcData.blockChars.distance_between_laterals)) { //is the segment ends on emitter(LATERAL)
                if (emitter.IsEmitterPC) {
                    if (this.isPressureInEmittersRegulationRange(emitter, currSegment.InletPressure)) {
                        // Emitter segment, PC - out of regulation range - calc flow rate:
                        let flow = this.calcSegmentFlowRateForPCEmitterOutOfRegulationRange(emitter, currSegment, null);
                        segments[index - 1].FlowRate = currSegment.FlowRate + flow;
                        segments[index - 1].AtomicFlowRate = flow;
                    }
                    else {
                        // Emitter segment, PC - in regulation range - add flow rate:
                        if (!segments[index - 1].FlowRate || segments[index - 1].FlowRate === 0) {
                            segments[index - 1].FlowRate = currSegment.FlowRate + lastFlowRate;
                            segments[index - 1].AtomicFlowRate = lastFlowRate;
                        }
                    }
                }
                else {
                    // Emitter segment, NON-PC, calc and set flow rate: 
                    let flow = this.calcSegmentFlowRateForNonPCEmitter(emitter, currSegment, null);
                    segments[index - 1].FlowRate = currSegment.FlowRate + flow;
                    segments[index - 1].AtomicFlowRate = flow;
                }
            }
            else {
                // Segment's flow rate is as curr segment
                if (!segments[index - 1].FlowRate || segments[index - 1].FlowRate === 0) {
                    segments[index - 1].FlowRate = currSegment.FlowRate;
                    segments[index - 1].AtomicFlowRate = lastFlowRate;
                }
            }
        }

        return segments[index - 1].AtomicFlowRate;
    }
    private isPressureInEmittersRegulationRange(emitter: any, InletPressure: any) {  //return true if the pressure is out of range
        return InletPressure < emitter.MinTechnicalPressure
    }

    protected resetSegmentsCalcData(segmentsCalcData: any) {
        // Reset Segments:
        for (let index = 0; index < segmentsCalcData.segments.length; index++) {
            const seg = segmentsCalcData.segments[index];
            seg.Id = index + 1;
            seg.AtomicFlowRate = 0;
            seg.EndPressure = 0;
            seg.FlowRate = 0;
            seg.InletPressure = 0;
            seg.Pressureloss = 0;
            if (segmentsCalcData.type != LateralCalculationType.VERIFICATION && segmentsCalcData.type != LateralCalculationType.MAX_LENGTH_FOR_NON_PC_EMITTERS && segmentsCalcData.type != LateralCalculationType.MAX_LENGTH_FOR_PC_EMITTERS) {
                seg.PipeSection = null;
            }
        }
        // Reset Slopes:
        segmentsCalcData.slopes.forEach(slope => {
            slope.InletPressure = 0;
            slope.EndPressure = 0;
        });
        // Reset Pipes:
        segmentsCalcData.pipes.forEach(p => {
            p.MaxFlowRate = 0;
            p.MaxPressure = 0;
            p.MaxVelocity = 0;
            p.InletPressure = 0;
            p.EndPressure = 0;
            p.PressureLoss = 0;

            if (segmentsCalcData.type != LateralCalculationType.VERIFICATION && segmentsCalcData.type != LateralCalculationType.MAX_LENGTH_FOR_NON_PC_EMITTERS && segmentsCalcData.type != LateralCalculationType.MAX_LENGTH_FOR_PC_EMITTERS) {
                p.SectionLength = 0;
            }
        });

        // Reset Last segment:
        let lastSegment = segmentsCalcData.segments[segmentsCalcData.segments.length - 1];
        let endPressure = segmentsCalcData.calcData.blockChars.end_pressure;

        lastSegment.FlowRate = segmentsCalcData.lastFlowRate;
        lastSegment.AtomicFlowRate = segmentsCalcData.lastFlowRate;
        lastSegment.EndPressure = endPressure;
        if (segmentsCalcData.type != LateralCalculationType.VERIFICATION && segmentsCalcData.type != LateralCalculationType.MAX_LENGTH_FOR_NON_PC_EMITTERS && segmentsCalcData.type != LateralCalculationType.MAX_LENGTH_FOR_PC_EMITTERS) {
            lastSegment.PipeSection = segmentsCalcData.pipes[segmentsCalcData.pipesIndex];
        }
    }

    protected createEmittersSections(emittersdata: any): EmitterSection[] {
        let numOfSections = emittersdata.length;
        let pipes = emittersdata.pipes;

        let emitters: EmitterSection[] = [];
        emittersdata = emittersdata.sort((a, b) => Number(b.flow_rate) - Number(a.flow_rate));
        pipes = pipes.sort((a, b) => Number(b.NominalDiameter) - Number(a.NominalDiameter));

        for (let index = 0; index < numOfSections; index++) {
            let emitter = new EmitterSection();
            emitter.Id = index + 1;
            let emitterData = emittersdata[index];
            let pipe = pipes[index];
            let rough = emitterData.roughness || emitterData.Roughness || pipe.Roughness;
            rough = Number(rough) > Number(AbsCalculationProcess.MAX_ROUGHNESS) ? AbsCalculationProcess.MAX_ROUGHNESS : rough;

            emitter.Type = emitterData.emitter_type || emitterData.Type;
            emitter.Model = emitterData.model || emitterData.Model;


            emitter.WallThickness = emitterData.wall_thickness || emitterData.WallThickness;
            emitter.NominalDiameter = emitterData.nominal_diameter ? Number(emitterData.nominal_diameter) : Number(emitterData.NominalDiameter)
            emitter.InternalDiameter = emitterData.internal_diameter ? Number(emitterData.internal_diameter) : Number(emitterData.InternalDiameter)
            emitter.SectionLength = emitterData.section_length || emitterData.SectionLength || 0;
            emitter.KD = emitterData.kd_local_friction_factor ? Number(emitterData.kd_local_friction_factor) : Number(emitterData.KD) || 0;
            emitter.Roughness = rough;
            emitter.CV = emitterData.cv ? Number(emitterData.cv) : Number(emitterData.CV) || 0;
            let exponent = emitterData.x_exponent || emitterData.x_exponent || emitterData.XEmitterExponent || 0;
            emitter.XEmitterExponent = Number(exponent);
            emitter.KEmitterConstant = emitterData.k_emitter_constant ? Number(emitterData.k_emitter_constant) : Number(emitterData.KEmitterConstant) || null;
            emitter.K2EmitterConstant = emitterData.k2 ? Number(emitterData.k2) : Number(emitterData.K2EmitterConstant) || null;
            emitter.IsEmitterPC = emitterData.is_emitter_pc || emitterData.IsEmitterPC || false;

            emitter.MaxPressure = 0;
            emitter.MaxAllowedVelocity = AbsLateralCalculationProcess.EMITTERS_MAX_VELOCITY;
            emitter.MaxAllowedPressure = emitterData.max_design_pressure ? Number(emitterData.max_design_pressure) : null
            emitter.MaxAllowedPressure = emitter.MaxAllowedPressure || Number(emitterData.MaxAllowedPressure) || 0;
            emitter.MinAllowedPressure = emitterData.min_design_pressure ? Number(emitterData.min_design_pressure) : emitterData.min_design_pressue ? Number(emitterData.min_design_pressue) : null;
            emitter.MinAllowedPressure = emitter.MinAllowedPressure || Number(emitterData.MinAllowedPressure) || 0;
            emitter.MaxTechnicalPressure = emitterData.max_technical_pressure ? Number(emitterData.max_technical_pressure) : Number(emitterData.MaxTechnicalPressure) || 0;
            emitter.MinTechnicalPressure = emitterData.min_technical_pressure ? Number(emitterData.min_technical_pressure) : Number(emitterData.MinTechnicalPressure) || 0;

            emitters.push(emitter);
        }

        return emitters;
    }

    protected calcFlowVariation(minFlowRate, maxFlowRate) { //oran
        // FV = ((Qmax - Qmin)/ Qmax) * 100 
        let check1 = Math.round((maxFlowRate - minFlowRate) * 1e12) / 1e12
        let check2 = maxFlowRate - minFlowRate
        return ((check1) / maxFlowRate) * 100
    }

    protected calcEmissionUniformity(cv: number, minFlowRate: number, avgFlowRate: any): any {
        // EU = (1 - (1.27 * cv)) * (Qmin / Qavg) * 100
        return (1 - (1.27 * cv)) * (minFlowRate / avgFlowRate) * 100
    }

    protected setSegmentTravelTime(currSegment: Segment, spacing: any) {
        let r = currSegment.PipeSection.InternalDiameter / 2;
        let A = AbsLateralCalculationProcess.TRAVEL_TIME_A_CO * r * r;
        let V = (((currSegment.FlowRate * 1000) / A) / 3600) * 1000;
        let segTT = spacing / V;
        currSegment.TravelTime = segTT;
    }

    protected calcTravelTime(segments: any, spacing: any): any {
        let travelTime = 0;
        segments.forEach(seg => {
            travelTime += seg.TravelTime;
        });

        return travelTime / 60; // to Hours
    }

    protected setFlushingSettings(data: any, pipes: any): any {
        let lastPipeSectionDiameter = pipes[pipes.length - 1].InternalDiameter;
        let flushingVelocity: any = data.flushingVelocity || 0.4;
        let lastOutletFlowRate = this.calcFlowRateForFlushing(lastPipeSectionDiameter, flushingVelocity)
        data.isFlushingMode = true;

        let isAtmosphere = this.isAtmosphere(data);
        let isPC = data.emitters[0].IsEmitterPC;
        data.flushing_end_flow_rate = lastOutletFlowRate;
        data.calcType = isPC ? LateralCalculationType.PRESSURE_LOSS_FOR_SELECTED_LATERAL : LateralCalculationType.FV_EU_FOR_SELECTED_LATERAL;
        data.flushing_end_pressure = isAtmosphere ? AbsCalculationProcess.ATMOSPHERE_PRESSURE : (data.flushingPressure ? Number(data.flushingPressure) : AbsCalculationProcess.COLLECTOR_PRESSURE);
        data.end_velocity = flushingVelocity

        return true
    }

    protected handleNegativeFlushing(flushingResults: any, flushingPipes: any, data: any) {
        let minPressure = 0;
        let diff = 0;
        let isAtmospherePressure = flushingPipes[flushingPipes.length - 1].EndPressure == AbsCalculationProcess.ATMOSPHERE_PRESSURE && !data.flushingPressure
        // Get min pressure:
        flushingPipes.forEach(pipe => {
            if (pipe.MinPressure < minPressure) {
                minPressure = pipe.MinPressure;
            }
        });
        if (minPressure < 0) {
            diff = isAtmospherePressure ? AbsCalculationProcess.ATMOSPHERE_PRESSURE - minPressure : (data.flushingPressure ? Number(data.flushingPressure) - minPressure : AbsCalculationProcess.COLLECTOR_PRESSURE - minPressure);
            flushingResults.totalMaxPressure += diff;
            flushingResults.totalMinPressure += diff;
            flushingPipes[0].InletPressure += diff;
        }
    }

    // --------------------------------------------- PRIVATE METHODS ---------------------------------------------
    private calcSegmentFlowRateForNonPCEmitter(emitter: any, currSegment: any, pressure: any) {
        let x = emitter.XEmitterExponent;
        // Q=K*P^X
        let endPressure = pressure ? pressure : currSegment.InletPressure; // P - Pressure at outlet
        let flowRate
        if (endPressure < 0) {
            flowRate = 0.1
        } else {
            let emitterConstant = emitter.KEmitterConstant // K - Emitter constant
            let emitterIndex = x; // X - Emitter index
            let d = Math.pow(endPressure, emitterIndex);
            flowRate = emitterConstant * Math.pow(endPressure, emitterIndex) / 1000; // Q - Flow rate
        }
        return flowRate;
    }

    private calcSegmentFlowRateForPCEmitterOutOfRegulationRange(emitter: any, currSegment: any, pressure: any) {
        let x = 0.5;
        // Q=K*P^X
        let endPressure = pressure ? pressure : currSegment.InletPressure; // P - Pressure at outlet
        let flowRate
        if (endPressure < 0) {
            flowRate = 0.1
        } else {
            let emitterConstant = emitter.K2EmitterConstant // K - Emitter constant
            let emitterIndex = x; // X - Emitter index
            let d = Math.pow(endPressure, emitterIndex);
            flowRate = emitterConstant * Math.pow(endPressure, emitterIndex) / 1000; // Q - Flow rate
        }

        return flowRate;
    }

    private isAtmosphere(data: any) {
        return data.isAtmospherePressure || (!data.isCollectorPressure && !data.isAtmospherePressure && !data.flushingPressure);
    }
}