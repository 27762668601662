import { AbsRequestHandler } from "../Base/AbsRequestHandler";
import { CalculationsService } from "../../services/CalculationsService";
import { ErrorMsgs } from "../../models/Errors/ErrorMsgs";
import { GeneralService } from "../../services/GeneralService";
import { PipesService } from "../../services/PipesService";
import { EPublicityStatus } from "../../enums/PublicityStatus.enum";
import { CalculatorTypes } from "../../enums/calculatorTypes.enum";
import { EUnits } from "../../enums/Units.enum";
import { UnitsConverter } from "../../UnitsConverter/UnitsConverter";
import { CalculationErrors, ECalculationErrors } from "../../calculator/errors_and_warnings/errors";

export abstract class BaseCalculatorHandler extends AbsRequestHandler {
    // public abstract async getPipesDataFromDB(pipeChars, calcType, units, region, isOffline);
    public abstract  getCalculatorPageData(reqData: any);
    public abstract buildCalculationResult(resultData: any);
    protected abstract getUserCalcData(calculationReqData: any);
    protected abstract  getDevicesData(calculationData: any, calculationReqData: any);
    public abstract  getAllCalculatorsData(reqData: any);

    public static readonly EMITTER_MAX_ROUUGHNESS = "150";
    // ---------------------------------- COMMON CALCULATION METHODS ------------------------------
    public async calculate(reqData: any) {
        try { 
            let calculationsService =  new CalculationsService();
            let calculationReqData = reqData.calculationData;
            let user = reqData.user;
            let isCalculate = true;
            let result: any;

            calculationReqData.calculator = reqData.calculator;
            calculationReqData.units = user.units;
            calculationReqData.region = user.region;
            calculationReqData.language = user.language
            let calculationData = await this.buildCalculationData(calculationReqData);
            if (!calculationData) {
                throw new ErrorMsgs('Error - Invalid Calculation params received');
            }
            if (calculationData.inletPressureCalcFailure) {
                isCalculate = false;
                result = {
                    calcResults: {
                        errors: [
                            CalculationErrors.get(ECalculationErrors.INLET_PRESSURE_BELOW_TECHNICAL_PRESSURE)
                        ]
                    }
                }
            }
            if(calculationReqData.isFlushingMode) {
              calculationData.isFlushingMode = calculationReqData.isFlushingMode;
            }
           if(!calculationData.calculator) {
              calculationData.calculator = calculationReqData.calculator;
            }
          
            if (isCalculate) {
                // Calculate:
                result = await calculationsService.calculate(calculationData);
                if (!result) {
                    throw new ErrorMsgs('Error - Error occured while calculating result');
                }

                if (calculationData.units === EUnits.US_UNITS && calculationData.calculator !== CalculatorTypes.VALVE) {
                    //add calculator id to results
                    result.calculator = calculationData.calculator
                    // Convert calculation results to US-Units:
                    result = this.convertCalculationResultsToUSUnits(result);
                }
            }
            return result;
        } catch (error) {
            let err = error.message ? new ErrorMsgs('Internal server error - Please try again later', error.message) : new ErrorMsgs(error.ErrorToClient, error.ErrorToDeveloper);
            if (error.error) {
                err = error;
            }
            throw err;
        }
    }

    public async buildCalculationData(calculationReqData: any) {
        let calculationsService = new CalculationsService();
        let calculationData = this.getUserCalcData(calculationReqData);
        let endPressure = calculationReqData.blockChars.end_pressure;
        calculationData.calculator = calculationReqData.calculator;
        calculationData.language = calculationReqData.language;
        calculationData.calcType = calculationReqData.calcType

        // Get Pipes and Emitters data from DB 
        await this.getDevicesData(calculationData, calculationReqData);

        if (calculationData.units == EUnits.US_UNITS) {
            // Convert calculation inputs to Metric:
            calculationData = this.convertCalculationInputsToMetric(calculationData);
            calculationData.isUnitsSet = true;
        }


        calculationData.calcType = calculationReqData.calcType;
        calculationData.maxFV = calculationReqData.maxAllowedFlowVariation;
        calculationData.maxEU = calculationReqData.maxAllowedEmissionUniformity;
        calculationData.isFlushingMode = calculationReqData.isFlushingMode;
        calculationData.isAtmospherePressure = calculationReqData.isAtmospherePressure;
        calculationData.isCollectorPressure = calculationReqData.isCollectorPressure;
        calculationData.flushingVelocity = await this.getFlushingVelocity(calculationReqData, calculationData);

        if (!endPressure) {
            // Calc based on inlet pressure:
            let inletPressure = calculationData.blockChars.inlet_pressure;
            calculationData.calculator = calculationReqData.calculator;
            let calculatedPressures = calculationsService.calcSystemEndPressure(inletPressure, calculationData);
            if (!calculatedPressures) {
                return null;
            }
            if (calculatedPressures.inletPressureCalcFailure) {
                return calculatedPressures;
            }
            calculationData.blockChars.end_pressure = calculatedPressures.endPressure;
            calculationData.blockChars.inlet_pressure = calculationData.inletPressureOutOfUserRange ? null : inletPressure;
        }

        return calculationData;
    }

    private convertCalculationResultsToUSUnits(result: any): any {
        let converted = result;
        converted = UnitsConverter.convertCalculationResultsToUSUnits(result);
        return converted;
    }

    private convertCalculationInputsToMetric(calculationData: any): any {
        let converted = calculationData;
        let isReverse = true;

        // Submain pipe length:
        let submainLength = calculationData.blockChars.submain_pipe_length || null;
        if (submainLength) {
            submainLength = UnitsConverter.metersToFt(submainLength, isReverse, false);
            calculationData.blockChars.submain_pipe_length = submainLength;
        }
        // first_lateral_length:
        let firstLateralLength = calculationData.blockChars.first_lateral_length || null;
        if (firstLateralLength) {
            firstLateralLength = UnitsConverter.metersToFt(firstLateralLength, isReverse, false);
            calculationData.blockChars.first_lateral_length = firstLateralLength;
        }
        // last_lateral_length:
        let lastLateralLength = calculationData.blockChars.last_lateral_length || null;
        if (lastLateralLength) {
            lastLateralLength = UnitsConverter.metersToFt(lastLateralLength, isReverse, false);
            calculationData.blockChars.last_lateral_length = lastLateralLength;
        }
        // Flushing velocity:
        let flushingVelocity = calculationData.flushingVelocity;
        if (flushingVelocity) {
            flushingVelocity = UnitsConverter.metersPerSecondToFtPerSecond(flushingVelocity, isReverse, true);
            calculationData.flushingVelocity = flushingVelocity;
        }

        // Pressure for flushing:
        let flushingPressure = calculationData.pressure_for_flushing;
        if (flushingPressure) {
            flushingPressure = UnitsConverter.metersToPSI(flushingPressure, isReverse, false);
            calculationData.pressure_for_flushing = flushingPressure;
        }
        // End pressure:
        let endPressure = calculationData.blockChars.end_pressure || null;
        if (endPressure) {
            endPressure = UnitsConverter.metersToPSI(endPressure, isReverse, false);
            calculationData.blockChars.end_pressure = endPressure;
        }
        let inletPressure = calculationData.blockChars.inlet_pressure || null;
        if (inletPressure) {
            inletPressure = UnitsConverter.metersToPSI(inletPressure, isReverse, false);
            calculationData.blockChars.inlet_pressure = inletPressure;
        }
        // Max allowed pressure loss:
        let maxAllowedPressureLoss = calculationData.max_allowed_pressure_loss || calculationData.maxPressureLoss || null;
        if (maxAllowedPressureLoss) {
            maxAllowedPressureLoss = UnitsConverter.metersToPSI(maxAllowedPressureLoss, isReverse, false);
            calculationData.maxPressureAllowed = maxAllowedPressureLoss;
        }

        // Max pressure loss:
        let maxPressureLoss = calculationData.maxPressureLoss || null;
        if (maxPressureLoss) {
            maxPressureLoss = UnitsConverter.metersToPSI(maxPressureLoss, isReverse, false);
            calculationData.maxPressureLoss = maxPressureLoss;
        }

        // Max velocity loss:
        let maxVelocityAllowed = calculationData.maxVelocityAllowed || null;
        if (maxVelocityAllowed) {
            maxVelocityAllowed = UnitsConverter.metersPerSecondToFtPerSecond(maxVelocityAllowed, isReverse, false);
            calculationData.maxVelocityAllowed = maxVelocityAllowed;
        }

        // Spacing:
        let spacing = calculationData.blockChars.distance_between_laterals || null;
        if (spacing) {
            if (calculationData.calculator === CalculatorTypes.LATERAL && calculationData.units === EUnits.US_UNITS && (calculationData.emitterChars[0].emitter_type === 'Driplines' || calculationData.emitterChars[0].emitter_type === 'Online Drippers')) {
                spacing = UnitsConverter.metersToInch(spacing, isReverse, true);
            } else {
                spacing = UnitsConverter.metersToFt(spacing, isReverse, true);
            }
            calculationData.blockChars.distance_between_laterals = spacing;
        }
        // Pipes:
        let pipes = calculationData.pipeChars || [];
        if (pipes.pipes) {
            pipes = pipes.pipes
        }
        pipes.forEach(pipe => {
            // Nominal diameter:
            let nominalDiameter = pipe.nominal_diameter || null;
            if (nominalDiameter) {
                nominalDiameter = UnitsConverter.mmToInch(nominalDiameter, isReverse, true);
                pipe.nominal_diameter = nominalDiameter;
            }
            // Internal diameter:
            let internalDiameter = pipe.internal_diameter || null;
            if (internalDiameter) {
                internalDiameter = UnitsConverter.mmToInch(internalDiameter, isReverse, false);
                pipe.internal_diameter = internalDiameter;
            }
            // Section length:
            let sectionLength = pipe.section_length || null;
            if (sectionLength) {
                sectionLength = UnitsConverter.metersToFt(sectionLength, isReverse, false);
                pipe.section_length = sectionLength;
            }
            // segment_length:
            let segment_length = pipe.segment_length || null;
            if (segment_length) {
                segment_length = UnitsConverter.metersToFt(segment_length, isReverse, false);
                pipe.section_length = segment_length;
                pipe.segment_length = segment_length
            }

            // section flow rate
            let flow_rate = pipe.section_flow_rate
            if (flow_rate) {
                flow_rate = UnitsConverter.cubicToGallon(flow_rate, isReverse, false)
                pipe.section_flow_rate = flow_rate
            }
        });
        if (calculationData.pipeChars.pipes) {
            calculationData.pipeChars.pipes = pipes;
        } else {
            calculationData.pipeChars = pipes;
        }

        if (calculationData.all_pipes_by_user_type) {
            let all_pipes_by_user_type = calculationData.all_pipes_by_user_type
            all_pipes_by_user_type.forEach(pipe => {
                // Nominal diameter:
                let nominalDiameter = pipe.nominal_diameter || null;
                if (nominalDiameter) {
                    nominalDiameter = UnitsConverter.mmToInch(nominalDiameter, isReverse, true);
                    pipe.nominal_diameter = nominalDiameter;
                }
                // Internal diameter:
                let internalDiameter = pipe.internal_diameter || null;
                if (internalDiameter) {
                    internalDiameter = UnitsConverter.mmToInch(internalDiameter, isReverse, false);
                    pipe.internal_diameter = internalDiameter;
                }
            });
        }


        // Total Flow rate:
        let totalflowRate = calculationData.blockChars.total_lateral_flow_rate || null;
        if (totalflowRate) {
            // Submain flow rate:
            totalflowRate = UnitsConverter.cubicToGallon(totalflowRate, isReverse, false);
            calculationData.blockChars.total_lateral_flow_rate = totalflowRate;

            let submainflowRate = calculationData.blockChars.last_lateral_flow_rate || null;
            if (submainflowRate) {
                submainflowRate = UnitsConverter.literToGalon(submainflowRate, isReverse, false);
                calculationData.blockChars.last_lateral_flow_rate = submainflowRate;
            }
        }
        else {
            // Lateral Flow rate:
            let flowRate = calculationData.blockChars.last_lateral_flow_rate || null;
            if (flowRate) {
                let calculator = calculationData.calculator;

                flowRate = calculator == CalculatorTypes.SUBMAIN ? UnitsConverter.literToGalon(flowRate, isReverse, false) : UnitsConverter.lateralFlowRateConversion(flowRate, isReverse, false);
                calculationData.blockChars.last_lateral_flow_rate = flowRate;
            }
        }

        // Topo length:
        let topoChars = calculationData.topographyChars || [];
        topoChars.forEach(topo => {
            // Real length:
            let realLength = topo.real_length || null;
            if (realLength) {
                realLength = UnitsConverter.metersToFt(realLength, isReverse, false);
                topo.real_length = realLength;
            }
            // Height diff in meters:
            let diffMeters = topo.height_diff_meters || null;
            if (diffMeters) {
                diffMeters = UnitsConverter.metersToFt(diffMeters, isReverse, false);
                topo.height_diff_meters = diffMeters;
            }
        });
        calculationData.topographyChars = topoChars;

        return converted;
    }

    protected async getPipes(region: any, type: any, pClass: any = null, diameter: any = null, isOffline: boolean, isRequestFromCalculatorAndUsUnits?: any) {
        try {
            let service = new PipesService();
            let localStatus = EPublicityStatus.PUBLISH;
            let isItCalculator = true
            let ps = await service.getPipes({ type, pClass, diameter, region, localStatus, isRequestFromCalculatorAndUsUnits, isOffline }, isItCalculator);
            return ps;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    protected async getPipe(pipe, region = null, isOffline: boolean, isRequestFromCalculatorAndUsUnits?: boolean) {
        try {
            let service = new PipesService();
            let type = pipe.type ? pipe.type : pipe.pipe_materail;
            let pClass = pipe.class ? pipe.class : pipe.pipe_class;
            let p = await service.getPipe({ type, pClass, nominalDiameter: pipe.nominal_diameter, region, isRequestFromCalculatorAndUsUnits, isOffline });
            return p;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    private async getFlushingVelocity(calculationReqData: any, calculationData: any) {
        let generalService = new GeneralService();
        let flushingVelocity = calculationData.flushingVelocity || null;
        let isOffline = calculationReqData.isOffline;
        if (!flushingVelocity || calculationData.isFlushingMode == false) {
            // Get recommended flushing velocity from DB 
            let flushingTable = await generalService.getFlushingVelocityTable(calculationData.units, isOffline);
            if (!flushingTable) {
                throw new ErrorMsgs('Error - Internal server error - no flushing velocites table found', null, false);
            }
            calculationData.flushingVelocitiesTable = flushingTable;
        }

        return flushingVelocity;
    }

}