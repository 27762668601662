import {
  CalculationNotes,
  ECalculationNotes,
} from "../../../errors_and_warnings/notes";
import { ValvePressureLossFormula } from "../../../../enums/ValvePressureLossFormula.enum";
import { ICalculationProcess } from "../../Interfaces/ICalculationProcess";
import {
  CalculationWarnings,
  ECalculationWarnings,
} from "../../../errors_and_warnings/warnings";
import { CalculationErrors, ECalculationErrors } from "../../../errors_and_warnings/errors";

export abstract class AbsValveCalculationProcess
  implements ICalculationProcess {
  public abstract calculate(calculationData: any);

  public start(calculationData: any) {
    return this.calculate(calculationData);
  }

  protected addDefaultNoAnglesIncludedNote = (notes: any) => {
    this.addNote(notes, ECalculationNotes.NO_ANGLES_INCLUDED_IN_CALCULATION);
  };

  protected addNote(notes: any, noteType: ECalculationNotes) {
    const note = CalculationNotes.get(noteType);
    if (!notes.find(({ id }) => id === note.id)) {
      notes.push(note);
    }
  }

  protected addWarning(
    warnings: any,
    warningType: ECalculationWarnings
  ) {
    const warning = CalculationWarnings.get(warningType);
    if (!warnings.find(({ Id }) => Id === warnings.Id)) {
      warnings.push(warning);
    }
  }

  protected addError(errors: any, errorType: ECalculationErrors) {
    const error = CalculationErrors.get(errorType);
    if (!errors.find(({ Id }) => Id === errors.Id)) {
      errors.push(error);
    }
  }

  protected getPressureLoss(calcData: any): number {
    let pressureLoss: number;
    switch (calcData.units) {
      case ValvePressureLossFormula.US:
        pressureLoss = this.usFormula(calcData);
        break;

      case ValvePressureLossFormula.METRIC:
        pressureLoss = this.metricFormula(calcData);
        break;

      default:
        throw new Error(`Error - Unknown calc formula received`);
    }
    return pressureLoss;
  }

  private usFormula(calcData: any): number {
    const { cv } = calcData.valve;
    const { flowRate } = calcData;
    return Math.pow(flowRate / cv, 2);
  }

  private metricFormula(calcData: any): number {
    const { kv } = calcData.valve;
    const { flowRate } = calcData;
    return 10.2 * Math.pow(flowRate / kv, 2);
  }
}
