import { Component, OnInit } from '@angular/core';
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import { CalculatorService } from 'src/app/services/calculator.service';
import { OfflineService } from "src/app/services/offline.service";
import { EtransaltionGroups } from 'src/app/shared/enum';
import { ValvesMainPageCard } from './valves-main-page.interfaces';
import { valvesCardsData } from './valves-main-page.data';
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'app-valves-main-page',
  templateUrl: './valves-main-page.component.html',
  styleUrls: ['./valves-main-page.component.scss']
})
export class ValvesMainPageComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  valvesCards: ValvesMainPageCard[] = [];
  mySubscription: any;
  user_id;
  technicalLibrary: string = "";

  constructor(
    public router: Router,
    public dataService: DataService,
    public textService: TextService,
    public offlineService: OfflineService,
    public calculatorService: CalculatorService,
  ) {
    this.dataService.resetValveCalculator.subscribe((res) => {
      this.router.navigateByUrl("calc/valve");
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnInit(): void {
    this.getValveCalculatorData();
  }

  async getValveCalculatorData() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
    } else {
      this.calculatorService.getCalculatorPageDataValve().subscribe((resp) => {
        this.dataService.showLoader = false;
        this.setResponseValveCalculatorPageData(resp);
      });
    }
  }

  setResponseValveCalculatorPageData(resp) {
    if (resp.success) {
      this.user_id = resp.result.page_data.user.id;
      // this.analyticsEvent();
      this.dataService.user_name =
        resp.result.page_data.user.first_name +
        " " +
        resp.result.page_data.user.last_name;

      this.dataService.regions_list = resp.result.page_data.regions;
      this.dataService.selected_units = this.dataService.units_list.find(
        (value) => {
          return value.value === Number(resp.result.page_data.user.units);
        }
      );
      this.dataService.selected_region = this.dataService.regions_list.find(
        (value) => {
          return resp.result.page_data.user.region === value.region_id;
        }
      );
      this.dataService.languages_list = resp.result.page_data.languages;
      this.dataService.selected_language = this.dataService.languages_list.find(
        (value) => {
          return (
            value.row_id === resp.result.page_data.user.language.toString()
          );
        }
      );
      this.technicalLibrary = resp.result.page_data.technicalLibrary
      this.getTexts();
      this.setCalcsCards();
    }
  }

  getTexts() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = this.textService.getResourceTextOffline();
      this.setTextResponse({
        result: response,
        success: response ? true : false,
      });
      this.dataService.showLoader = false;
    } else {
      this.textService
        .getResourceText(this.dataService.selected_language.row_id)
        .subscribe((res) => {
          this.dataService.showLoader = false;
          this.setTextResponse(res);
        });
    }
  }

  setTextResponse(res) {
    if (res.success) {
      this.textService.translation_text = res.result.translations
        ? res.result.translations
        : res.result;
      localStorage.setItem(
        "resource_txt",
        JSON.stringify(this.textService.translation_text)
      );
      this.textService.setUnitsDictionary();

      this.dataService.logic_text_or = this.textService
        .getText(this.EtransaltionGroups.SUBMAIN, "submain_or_1")
        .toString();
    }
  }

  setCalcsCards() {
    this.valvesCards = valvesCardsData.reduce((acc, cardData) => {
      let { calcName, isDisabled, titleKey, textKey, mobileTitleKey } = cardData;
      const url = calcName !== 'technical-library' ? `calc/valve/${calcName}` : this.technicalLibrary
      // build calc card
      const title: string = this.textService.getText(
        this.EtransaltionGroups.VALVE,
        this.dataService.isItMobile ? mobileTitleKey : titleKey
      );
      const text: string = this.textService.getText(
        this.EtransaltionGroups.VALVE,
        textKey
      );
      return [...acc, { title, text, calcName, isDisabled, url }];
    }, [] as ValvesMainPageCard[]);
  }

}
