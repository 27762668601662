import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import {
  Ecalculator,
  EtransaltionGroups,
  EvalveQueryTypes,
  EvalveSteps,
} from "src/app/shared/enum";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { OfflineService } from "src/app/services/offline.service";
import { CalculatorService } from "src/app/services/calculator.service";
import { ValveService } from "src/app/services/valve.service";
import { NavigationEnd, Router } from "@angular/router";
import { CALCULATOR_SECTION } from "src/app/shared/consts";
import { Subject } from "rxjs";
import { ValveResultsComponent } from "src/app/components/valve-results/valve-results.component";
import { CalculatorSection } from "src/app/shared/types";
import { PopupTypes } from "src/app/models/popuptype";
import { BreadcrumbItem } from "src/app/components/breadcrumbs/breadcrumbs.component"

@Component({
  selector: "app-valve-calculator-page",
  templateUrl: "./valve-calculator-page.component.html",
  styleUrls: ["./valve-calculator-page.component.scss"],
})
export class ValveCalculatorPageComponent implements OnInit {
  user_id; //for analytics events

  @ViewChild("results") resultsView: ValveResultsComponent;

  // for mobile
  calculator_section = CALCULATOR_SECTION;
  show_section_mobile: CalculatorSection = CALCULATOR_SECTION.QUESTION;
  str_id_step_text = ["valve_calculation_Q_title"];

  errorPopup = PopupTypes.error;

  QuestionsConfig: any[] = [];
  questionSelectedIndex: number;
  valveFamilyOptions: string[] = [];
  valveModelOptions: string[] = [];
  valveForm: FormGroup;
  calculationResults: any;
  mySubscription: any;
  tableColumns: any[] = [];
  breadcrumbs: BreadcrumbItem[];


  EtransaltionGroups = EtransaltionGroups;
  EvalveSteps = EvalveSteps;
  EqueryTypes = EvalveQueryTypes;
  Ecalculator = Ecalculator;

  exportPdfFromMobile = new Subject<any>();


  //validation limits

  // max limit for usa units (gpm) is 16000 and for metric (m^3/h) is 3600
  flowRateValidationMax =
    this.dataService.selected_units === this.dataService.units_list[1]
      ? 16000
      : 3600;

  // max limit for usa units (psi) is 15 and for metric (m) is 10
  pressureLossValidationMax =
    this.dataService.selected_units === this.dataService.units_list[1]
      ? 15
      : 10;

  cavitationRiskPressureMin = this.dataService.selected_units === this.dataService.units_list[1] ? 1 : 2;
  cavitationRiskPressureMax = 999;

  quickReliefValvePressureMin = this.dataService.selected_units === this.dataService.units_list[1] ? 0.50 : 0.1;
  quickReliefValvePressureMax = this.dataService.selected_units === this.dataService.units_list[1] ? 16000.0 : 3600.0;
  setPressureMin = this.dataService.selected_units === this.dataService.units_list[1] ? 30.0 : 20.0;
  setPressureMax = this.dataService.selected_units === this.dataService.units_list[1] ? 230.00 : 160.00;

  constructor(
    public router: Router,
    public dataService: DataService,
    public textService: TextService,
    public calculatorService: CalculatorService,
    public offlineService: OfflineService,
    public valveService: ValveService,
    private fb: FormBuilder
  ) {
    this.dataService.resetValveCalculator.subscribe((res) => {
      this.router.navigateByUrl("calc/valve/hydraulic-calculator");

    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnInit(): void {
    this.dataService.inputs_contents_has_changed = false;
    this.dataService.showAlertMessage = false;
    this.dataService.alert_msg_text = ''
    this.getPageData();
    this.initializeQuestionsConfig();
    this.initValidatorsOnFormFields();
    this.setBreadcrumbs();
  }

  async getPageData() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
    } else {
      this.calculatorService.getCalculatorPageDataValve().subscribe((resp) => {
        this.dataService.showLoader = false;
        this.setResponsePageData(resp);
      });
    }
  }

  setResponsePageData(resp) {
    if (resp.success) {
      this.user_id = resp.result.page_data.user.id;
      // this.analyticsEvent();
      this.dataService.user_name =
        resp.result.page_data.user.first_name +
        " " +
        resp.result.page_data.user.last_name;
      this.valveFamilyOptions =
        resp.result.page_data.valve_characteristics_data.families;

      this.dataService.regions_list = resp.result.page_data.regions;
      this.dataService.selected_units = this.dataService.units_list.find(
        (value) => {
          return value.value === Number(resp.result.page_data.user.units);
        }
      );
      this.dataService.selected_region = this.dataService.regions_list.find(
        (value) => {
          return resp.result.page_data.user.region === value.region_id;
        }
      );
      this.dataService.languages_list = resp.result.page_data.languages;
      this.dataService.selected_language = this.dataService.languages_list.find(
        (value) => {
          return (
            value.row_id === resp.result.page_data.user.language.toString()
          );
        }
      );
      this.getTexts();
      this.initializeQuestionsConfig();
    }
  }

  getTexts() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = this.textService.getResourceTextOffline();
      this.setTextResponse({
        result: response,
        success: response ? true : false,
      });
      this.dataService.showLoader = false;
    } else {
      this.textService
        .getResourceText(this.dataService.selected_language.row_id)
        .subscribe((res) => {
          this.dataService.showLoader = false;
          this.setTextResponse(res);
        });
    }
  }

  setTextResponse(res) {
    if (res.success) {
      this.textService.translation_text = res.result.translations
        ? res.result.translations
        : res.result;
      localStorage.setItem(
        "resource_txt",
        JSON.stringify(this.textService.translation_text)
      );
      this.textService.setUnitsDictionary();

      this.dataService.logic_text_or = this.textService
        .getText(this.EtransaltionGroups.SUBMAIN, "submain_or_1")
        .toString();
    }
  }

  initializeQuestionsConfig() {
    this.QuestionsConfig = [
      {
        title: this.textService.getText(
          this.EtransaltionGroups.VALVE,
          "valve_calculation_Q_pressure_loss"
        ),
        subTitle: this.textService.getText(
          this.EtransaltionGroups.VALVE,
          "valve_calculation_Q_pressure_loss_subtitle"
        ),
        placeholder: "",
        hint: "",
        isSelected: false,
        isSimple: true,
        control: new FormControl("", {
          validators: [Validators.requiredTrue],
          updateOn: "blur",
        }),
      },
      {
        title: this.textService.getText(
          this.EtransaltionGroups.VALVE,
          "valve_calculation_Q_diameters"
        ),
        subTitle: this.textService.getText(
          this.EtransaltionGroups.VALVE,
          "valve_calculation_Q_diameters_subtitle"
        ),
        placeholder: "",
        hint: "",
        isSelected: false,
        isSimple: true,
        control: new FormControl("", {
          validators: [Validators.requiredTrue],
          updateOn: "blur",
        }),
      },
      {
        title: this.textService.getText(
          this.EtransaltionGroups.VALVE,
          "valve_calculation_Q_cavitation_risk"
        ),
        subTitle: this.textService.getText(
          this.EtransaltionGroups.VALVE,
          "valve_calculation_Q_cavitation_risk_subtitle"
        ),
        placeholder: "",
        hint: "",
        isSelected: false,
        isSimple: true,
        control: new FormControl("", {
          validators: [Validators.requiredTrue],
          updateOn: "blur",
        }),
      },
      {
        title: this.textService.getText(
          this.EtransaltionGroups.VALVE,
          "valve_calculation_Q_quick_relief"
        ),
        subTitle: this.textService.getText(
          this.EtransaltionGroups.VALVE,
          "valve_calculation_Q_quick_relief_subtitle"
        ),
        placeholder: "",
        hint: "",
        isSelected: false,
        isSimple: true,
        control: new FormControl("", {
          validators: [Validators.requiredTrue],
          updateOn: "blur",
        }),
      },
    ];
  }

  updateSelectedQuestion(event, index) {
    this.calculationResults = null;
    this.dataService.inputs_contents_has_changed = false;
    this.dataService.showAlertMessage = false;
    this.dataService.alert_msg_text = ''
    for (let i = 0; i < this.QuestionsConfig.length; i++) {
      this.QuestionsConfig[i].control.reset();
      if (event == false && index == i) {
        this.QuestionsConfig[i].isSelected = true;
        this.questionSelectedIndex = i;
        this.QuestionsConfig[i].control.setValue(
          this.QuestionsConfig[i].isSelected
        );
        this.initValidatorsOnFormFields();
        if (this.dataService.isItMobile) {
          this.str_id_step_text[1] = this.getStrBasedOnQuestion();
        }
        this.dataService.changeValveQuestionForm.next();
      } else {
        this.QuestionsConfig[i].isSelected = false;
      }
    }
  }

  initValidatorsOnFormFields() {
    this.valveForm = this.fb.group({
      ...(this.questionSelectedIndex === EvalveQueryTypes.pressure_loss || this.questionSelectedIndex === EvalveQueryTypes.valve_diameter) && {
        valveFamily: [
          "",
          {
            validators: [Validators.compose([Validators.required])],
            updateOn: "blur",
          },
        ],
        ...(this.questionSelectedIndex === EvalveQueryTypes.pressure_loss && {
          valveModel: [
            "",
            {
              validators: [Validators.compose([Validators.required])],
              updateOn: "blur",
            },
          ],
        }),
        ...(this.questionSelectedIndex === EvalveQueryTypes.pressure_loss && {
          valveDiameterMm: [
            "",
            {
              validators: [Validators.compose([Validators.required])],

              updateOn: "blur",
            },
          ],
        }),
        ...(this.questionSelectedIndex === EvalveQueryTypes.pressure_loss && {
          valveDiameterInch: [
            "",
            {
              validators: [Validators.compose([Validators.required])],

              updateOn: "blur",
            },
          ],
        }),
        flowRate: [
          "",
          {
            validators: [
              Validators.compose([
                Validators.required,
                Validators.pattern("[-+]?[0-9]+(.[0-9][0-9]?)?"),
                Validators.min(0.1),
                Validators.max(this.flowRateValidationMax),
              ]),
            ],
            updateOn: "change",
          },
        ],
        ...(this.questionSelectedIndex === EvalveQueryTypes.valve_diameter && {
          maxAllowedPressureLoss: [
            "",
            {
              validators: [
                Validators.compose([
                  Validators.required,
                  Validators.pattern("[-+]?[0-9]+(.[0-9][0-9]?)?"),
                  Validators.min(0.1),
                  Validators.max(this.pressureLossValidationMax),
                ]),
              ],
              updateOn: "change",
            },
          ],
        }),
        ...(this.questionSelectedIndex === EvalveQueryTypes.valve_diameter && {
          limitedPressureLoss: [
            "",
            {
              validators: [Validators.compose([Validators.required])],
              updateOn: "change",
            },
          ],
        }),
      },
      ...(this.questionSelectedIndex === EvalveQueryTypes.cavitation_risk && {
        upstreamPressure: [
          "",
          {
            validators: [Validators.compose([
              Validators.required,
              Validators.pattern("[-+]?[0-9]+"),
              Validators.max(this.cavitationRiskPressureMax),
              Validators.min(this.cavitationRiskPressureMin)
            ])],

            updateOn: "change",
          },
        ],
        downstreamPressure: [
          "",
          {
            validators: [Validators.compose([
              Validators.required,
              Validators.pattern("[-+]?[0-9]+"),
              Validators.max(this.cavitationRiskPressureMax),
              Validators.min(1)
            ])],
            updateOn: "change",
          },
        ],
      }),
      ...(this.questionSelectedIndex === EvalveQueryTypes.quick_relief_valve && {
        reliefFlow: [
          "",
          {
            validators: [Validators.compose([
              Validators.required,
              Validators.pattern(`[-+]?[0-9]+(.[0-9]${this.dataService.selected_units === this.dataService.units_list[1] ? '[0-9]' : ''}?)?`),
              Validators.max(this.quickReliefValvePressureMax),
              Validators.min(this.quickReliefValvePressureMin)
            ])],
            updateOn: "change",
          },
        ],
        setPressure: [
          "",
          {
            validators: [Validators.compose([
              Validators.required,
              Validators.pattern(`[-+]?[0-9]+(.[0-9]${this.dataService.selected_units === this.dataService.units_list[1] ? '[0-9]' : ''}?)?`),
              Validators.max(this.setPressureMax),
              Validators.min(this.setPressureMin)
            ])],
            updateOn: "change",
          },
        ],
      }),
    });
  }

  setResultText() {
    if (this.questionSelectedIndex === EvalveQueryTypes.quick_relief_valve) {
      this.tableColumns = [
        {
          display: this.textService.getText(
            EtransaltionGroups.VALVE,
            "valve_relief_flow"
          ),
          units: this.textService.getUnitsAsText("m3h"),
        },
        {
          display: this.textService.getText(
            EtransaltionGroups.VALVE,
            "valve_relief_set_pressure"
          ),
          units: this.textService.getUnitsAsText("m_pressure"),
        },
        {
          display: this.textService.getText(
            EtransaltionGroups.VALVE,
            "valve_relief_optimal_diameter"
          ),
        },
      ];
    } else {
      this.tableColumns = [
        {
          display: this.textService.getText(
            EtransaltionGroups.VALVE,
            "valve_results_family_label"
          ),
        },
        {
          display: this.textService.getText(
            EtransaltionGroups.VALVE,
            "valve_results_model_label"
          ),
        },
        {
          display: this.textService.getText(
            EtransaltionGroups.VALVE,
            "valve_results_diameter_label"
          ),
          units: "(mm)",
        },
        {
          display: this.textService.getText(
            EtransaltionGroups.VALVE,
            "valve_results_diameter_label"
          ),
          units: "(inch)",
        },
        {
          display: this.textService.getText(
            EtransaltionGroups.VALVE,
            "valve_results_material_label"
          ),
        },
        //by units
        ...(this.dataService.selected_units === this.dataService.units_list[0]
          ? [
            {
              display: this.textService.getText(
                EtransaltionGroups.VALVE,
                "valve_results_kv_label"
              ),
            },
          ]
          : []),
        ...(this.dataService.selected_units === this.dataService.units_list[1]
          ? [
            {
              display: this.textService.getText(
                EtransaltionGroups.VALVE,
                "valve_results_cv_label"
              ),
            },
          ]
          : []),
        {
          display: this.textService.getText(
            EtransaltionGroups.VALVE,
            "valve_results_flow_rate_label"
          ),
          units: this.textService.getUnitsAsText("m3h"),
        },
        {
          display: this.textService.getText(
            EtransaltionGroups.VALVE,
            "valve_results_head_loss_label"
          ),
          units: this.textService.getUnitsAsText("m_pressure"),
        },
      ];
    }
  }

  setBreadcrumbs() {
    this.breadcrumbs = [
      {
        label: this.textService.getText(
          EtransaltionGroups.HOME,
          "home_valve"
        ),
        link: "/calc/valve"
      },
      {
        label: this.textService.getText(
          EtransaltionGroups.VALVE,
          "valves_hydraulic_calculator_title"
        ),
      }
    ]
  }

  async calculate() {
    //initialize result text
    this.calculationResults = null;
    await this.setResultText();
    const requestBody = this.getBodyForCalculateRequest();
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      // implement offline
    } else {
      this.valveService.calculate(requestBody).subscribe(
        (resp) => {
          if (this.questionSelectedIndex === this.EqueryTypes.quick_relief_valve && resp.result?.calculation_results?.errors?.length) {
            this.dataService.showLoader = false;
            this.dataService.error_result_msg = resp.result?.calculation_results?.errors[0].error;
            this.dataService.showErrorPopup = true;
          } else {
            this.dataService.showLoader = false;
            this.setCalculateResponse(resp);
          }
        },
        (err) => {
          this.dataService.showLoader = false;
          if (err.message) {
            this.dataService.error_result_msg = err.message;
          }
          if (err.ErrorToClient) {
            this.dataService.error_result_msg = err.ErrorToClient;
          }
          this.dataService.showErrorPopup = true;
        }
      );
    }
  }

  setCalculateResponse(resp) {
    if (resp.success) {
      const {
        pressure_loss: head_loss,
        flow_rate,
        valves,
        warnings,
        notes,
        requestParameters,
        diameter_inch,
        diameter_mm,
      } = resp.result.calculation_results;
      this.dataService.inputs_contents_has_changed = false;
      this.dataService.showAlertMessage = false;
      this.dataService.alert_msg_text = ''


      // on query 2 calc valves already have flow rate and pressure loss props
      // on query 1 they need to be added to valve
      if (this.questionSelectedIndex === this.EqueryTypes.pressure_loss) {
        // one valve
        valves.forEach((valve) => {
          valve.flow_rate = flow_rate;
          valve.head_loss = head_loss;
        });
      }

      this.calculationResults = { valves, notes, warnings, requestParameters, diameter_inch, diameter_mm };
    }
  }

  calculatorChangeHandler() {
    this.dataService.inputs_contents_has_changed = true;
    this.dataService.showAlertMessage = true;
  }

  getBodyForCalculateRequest() {
    const calcType = this.questionSelectedIndex + 1;
    const body = {
      calcType,
    };
    // for query 1 & 2
    if (
      this.questionSelectedIndex === EvalveQueryTypes.pressure_loss ||
      this.questionSelectedIndex === EvalveQueryTypes.valve_diameter
    ) {
      const family = this.valveForm.get("valveFamily").value;
      const flowRate = Number(this.valveForm.get("flowRate").value);
      body["family"] = family;
      body["flowRate"] = flowRate;
    }

    // for query 1
    if (this.questionSelectedIndex === EvalveQueryTypes.pressure_loss) {
      const model = this.valveForm.get("valveModel").value;
      const diameter_inch = this.valveForm.get("valveDiameterInch").value;
      const diameter_mm = this.valveForm.get("valveDiameterMm").value;
      const diameters = { diameter_inch, diameter_mm };
      body["model"] = model;
      body["diameters"] = diameters;
    }

    // for query 2
    if (this.questionSelectedIndex === EvalveQueryTypes.valve_diameter) {
      const maximumAllowedPressureLoss = Number(
        this.valveForm.get("maxAllowedPressureLoss").value
      );
      const limitedPressureLoss = this.valveForm.get(
        "limitedPressureLoss"
      ).value;

      body["limitedPressureLoss"] = limitedPressureLoss;
      body["maximumAllowedPressureLoss"] = maximumAllowedPressureLoss;
    }

    // for query 3
    if (this.questionSelectedIndex === EvalveQueryTypes.cavitation_risk) {
      const upstreamPressure = Number(
        this.valveForm.get("upstreamPressure").value
      );
      const downstreamPressure = Number(
        this.valveForm.get("downstreamPressure").value
      );

      body["upstreamPressure"] = upstreamPressure;
      body["downstreamPressure"] = downstreamPressure;
    }

    // for query 4
    if (this.questionSelectedIndex === EvalveQueryTypes.quick_relief_valve) {
      const reliefFlow = Number(this.valveForm.get("reliefFlow").value);
      const setPressure = Number(this.valveForm.get("setPressure").value);

      body["reliefFlow"] = reliefFlow;
      body["reliefPressure"] = setPressure;
    }
    return body;
  }

  // mobile related functions

  getStrBasedOnQuestion() {
    switch (this.questionSelectedIndex) {
      case EvalveQueryTypes.pressure_loss:
        return "valve_calculation_Q_flow_pressure_loss";
      case EvalveQueryTypes.valve_diameter:
        return "valve_calculation_Q_flow_diameters";
      case EvalveQueryTypes.cavitation_risk:
        return "valve_calculation_Q_cavitation_upstream_and_downstream";
      case EvalveQueryTypes.quick_relief_valve:
        return "valve_calculation_Q_quick_relief_flow_rate_and_pressure";
      default:
        break;
    }
  }

  nextStepMobile() {
    switch (this.show_section_mobile) {
      case this.calculator_section.QUESTION:
        this.show_section_mobile = this.calculator_section.VALVE;
        this.scrollToTop();
        break;

      case this.calculator_section.VALVE:
        this.show_section_mobile = this.calculator_section.RESULT;
        this.calculate();
        break;

      default:
        break;
    }
  }

  backStepMobile() {
    switch (this.show_section_mobile) {
      case this.calculator_section.VALVE:
        this.show_section_mobile = this.calculator_section.QUESTION;
        this.scrollToTop();
        break;
      case this.calculator_section.RESULT:
        this.dataService.restorePreviousDropdownValues.next();
        this.show_section_mobile = this.calculator_section.VALVE;
        this.scrollToTop();
        break;
      default:
        this.show_section_mobile = this.calculator_section.QUESTION;
        break;
    }
  }

  validationByStep() {
    switch (this.show_section_mobile) {
      case this.calculator_section.QUESTION:
        return (
          !this.QuestionsConfig[1].control.valid &&
          !this.QuestionsConfig[0].control.valid &&
          !this.QuestionsConfig[2].control.valid &&
          !this.QuestionsConfig[3].control.valid
        );

      case this.calculator_section.VALVE:
        return !this.valveForm.valid;

      default:
        break;
    }
  }

  onExportPdfMobile() {
    this.resultsView.exportToPDF();
  }

  onClickBackToHome() {
    this.router.navigateByUrl("calc/home");
  }

  scrollToTop() {
    setTimeout(() => {
      const element_elevation = document.querySelector("#scroll_top");
      element_elevation.scrollIntoView({ behavior: "smooth" });
    });
  }

  // unsubscribe

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}
