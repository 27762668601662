import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ValvesMainPageCard } from "../valves-main-page.interfaces";

@Component({
  selector: 'app-valves-card',
  templateUrl: './valves-card.component.html',
  styleUrls: ['./valves-card.component.scss']
})
export class ValvesCardComponent implements OnInit {
  @Input() card: ValvesMainPageCard;

  constructor(private router: Router) { }

  navigateToUrl() {
    if (!this.card.isDisabled) {
      if (this.card.url.includes('https')) {
        window.open(this.card.url, '_blank');
      } else {
        this.router.navigateByUrl(this.card.url)
      }
    }
  }

  ngOnInit(): void {
  }
}
