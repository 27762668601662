import { IExternalTool } from "../../hydrocalc-code/db/interfaces/externalTools.interface";
import { ExternalToolsDAL } from "../../hydrocalc-code/DAL/ExternalToolsDAL";

export class ExternalToolsService {
  toolsDal = new ExternalToolsDAL();
  public async getAllExternalTools(): Promise<IExternalTool[]> {
    const tools = await this.toolsDal.getAllExternalTools();
    return tools;
  }

  public async getSelectedExternalTools(): Promise<IExternalTool[]> {
    const tools = await this.toolsDal.getSelectedExternalTools();
    return tools;
  }

  public async updateExternalTools(
    toolsToUpdate: IExternalTool[]
  ): Promise<boolean> {
    const isUpdated = await this.toolsDal.updateExternalTools(toolsToUpdate);
    return isUpdated;
  }
}
