import { ValveCalculationType } from "../../enums/ValveCalculationType.enum";
import { PressureLossForValve } from "../CalculationProcess/Valve/PressureLossForValve";
import { ValveDiameter } from "../CalculationProcess/Valve/ValveDiameter";
import { ICalculationProcess } from "../Interfaces/ICalculationProcess";
import { ValveCavitationRisk } from "../CalculationProcess/Valve/ValveCavitationRisk";
import { ReliefValve } from "../CalculationProcess/Valve/ReliefValve";

export class ValveCalculationsFactory {
  static getCalcProcess(calcType: ValveCalculationType): ICalculationProcess {
    let calcProcess: ICalculationProcess = null;
    switch (calcType) {
      case ValveCalculationType.PRESSURE_LOSS:
        calcProcess = new PressureLossForValve();
        break;

      case ValveCalculationType.VALVE_DIAMETERS:
        calcProcess = new ValveDiameter();
        break;

      case ValveCalculationType.CAVITATION_RISK:
        calcProcess = new ValveCavitationRisk();
        break;

      case ValveCalculationType.RELIEF_VALVE:
        calcProcess = new ReliefValve();
        break;
    }

    return calcProcess;
  }
}
