import { FileSystemCredentials } from "aws-sdk";
import { AbsDAL } from "./Base/AbsDAL";
let AWS = require('aws-sdk');

export class MarketingContentDAL extends AbsDAL {
    // public static readonly MARKETING_CONTENT_TABLE_NAME = "MarketingContents";
    public static readonly MARKETING_CONTENT_TABLE_NAME = "MarketingContents2";
    public static readonly GLOBAL_REGION_STR = "GLOBAL";
    public static readonly WEST_EUROPE_STR = "5f831d9b8263536fc9ca5b08"

    /**
     * Gets Marketing content
     * 
     */
    public async getContent(filters: any) {
        try {
            let { region, language } = filters
            let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
            let table = this.getTableName(MarketingContentDAL.MARKETING_CONTENT_TABLE_NAME);
            // if selected region is west europe and language does not belong to region than default to Global

            region = (!region || (region === MarketingContentDAL.WEST_EUROPE_STR && !language)) ? MarketingContentDAL.GLOBAL_REGION_STR : region.toString()

            // if region is west europe then query content by region and languages
            const queryStr = `region_id = :hkey ${(region === MarketingContentDAL.WEST_EUROPE_STR && language) ? 'and language_id = :language' : ''}`
            console.log(queryStr);

            // Get all Regions params:
            let params = {
                TableName: table,
                KeyConditionExpression: queryStr,
                ExpressionAttributeValues: {
                    // partition key
                    ':hkey': region,
                    // sort key
                    ...((region === MarketingContentDAL.WEST_EUROPE_STR && language) && { ':language': language })
                }
            };
            console.log('params-', params);

            let res = await docClient.query(params).promise();

            return res.Items[0];
        } catch (error) {
            throw error;
        }
    }

    /**
    * Updates global content data
    * 
    */
    public async updateGlobalContent(updateContentData: any) {
        try {
            let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
            let table = this.getTableName(MarketingContentDAL.MARKETING_CONTENT_TABLE_NAME);
            let region = MarketingContentDAL.GLOBAL_REGION_STR;
            let language = 0 // in db global languages is 0
            let links = [];
            if (updateContentData.links) {
                updateContentData.links.forEach(link => {
                    let linkw = { "S": link };
                    links.push(linkw);
                });
            }

            // Get all Regions params:
            let params: any = {
                TableName: table,
                Key: {
                    'region_id': region,
                    'language_id': language
                },
                UpdateExpression: 'set media_type = :val1, title = :val3, subtitle = :val4, body = :val5, global_publicity_status = :val6, description_guide = :val8',
                ExpressionAttributeValues: {
                    ":val1": updateContentData.mediaType || -1,
                    ":val3": updateContentData.title || '',
                    ":val4": updateContentData.subtitle || '',
                    ":val5": updateContentData.body || '',
                    ":val6": updateContentData.publicityStatus || -1,
                    ":val8": updateContentData.descriptionGuide || ''

                },
                ReturnValues: 'ALL_NEW'
            };
            if (updateContentData.mediaUrl) {
                params.UpdateExpression += ', media_url = :val2';
                params.ExpressionAttributeValues[":val2"] = updateContentData.mediaUrl;
            }

            params.UpdateExpression += ', links = :val7';
            params.ExpressionAttributeValues[":val7"] = updateContentData.links;


            let res = await docClient.update(params).promise();

            return true;
        } catch (error) {
            throw error;
        }
    }

    /**
    * Updates local content data
    * 
    */
    public async updateLocalContent(updateContentData: any) {
        try {

            let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
            let table = this.getTableName(MarketingContentDAL.MARKETING_CONTENT_TABLE_NAME);
            let region = updateContentData.regionId.toString();
            let language = updateContentData.languageId
            updateContentData = updateContentData.contentData;
            console.log('languagee- ', language);

            // Get all Regions params:
            let params: any = {
                TableName: table,
                Key: {
                    'region_id': region,
                    'language_id': language
                },
                UpdateExpression: 'set media_type = :val1, title = :val3, subtitle = :val4, body = :val5',
                ExpressionAttributeValues: {
                    ":val1": updateContentData.mediaType || -1,
                    ":val3": updateContentData.title || '',
                    ":val4": updateContentData.subtitle || '',
                    ":val5": updateContentData.body || ''
                },
                ReturnValues: 'ALL_NEW'
            };
            if (updateContentData.mediaUrl) {
                params.UpdateExpression += ', media_url = :val2';
                params.ExpressionAttributeValues[":val2"] = updateContentData.mediaUrl;
            }
            params.UpdateExpression += ', links = :val7';
            params.ExpressionAttributeValues[":val7"] = updateContentData.links;


            let res = await docClient.update(params).promise();

            return true;
        } catch (error) {
            throw error;
        }
    }

    /**
     * Sets Global Content Publicity Status
     * 
     */
    public async setGlobalContentPublicityStatus(globalPublicityStatus: any) {
        try {
            let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
            let table = this.getTableName(MarketingContentDAL.MARKETING_CONTENT_TABLE_NAME);
            let region = MarketingContentDAL.GLOBAL_REGION_STR;

            // Get all Regions params:
            let params = {
                TableName: table,
                Key: {
                    'region_id': region
                },
                UpdateExpression: 'set global_publicity_status = :val1',
                ExpressionAttributeValues: {
                    ":val1": globalPublicityStatus,
                },
                ReturnValues: 'ALL_NEW'
            };

            let res = await docClient.update(params).promise();

            return true;
        } catch (error) {
            throw error;
        }
    }
}

