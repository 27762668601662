export enum CalculatorTypes {
    NONE = 0,
    SUBMAIN,
    LATERAL,
    MAINLINE,
    VALVE,
    IRRIGATION,
    SHIFT,
    ENERGY

}