import { ICalculationProcess } from "../Interfaces/ICalculationProcess";
import { Segment } from "../Segment";
import { SlopeSection } from "../SlopeSection";
import { PipeSection } from "../PipeSection";
import { FrictionPressureLossFormula } from "../../../../hydrocalc-code/enums/FrictionPressureLossFormula.enum";
import { SubmainCalculationType } from "../../enums/SubmainCalculationType.enum";
import { LateralCalculationType } from "../../enums/LateralCalculationType.enum";
import { MainlineCalculationType } from "../../enums/MainlineCalculationType.enum";
import { ErrorMsgs } from "../../../../hydrocalc-code/models/Errors/ErrorMsgs";
import { CalculationErrors, ECalculationErrors } from "../../errors_and_warnings/errors";
import { CalculationWarnings, ECalculationWarnings } from "../../errors_and_warnings/warnings";
import { CalculatorTypes } from "../../../../hydrocalc-code/enums/calculatorTypes.enum";
import { EmitterSection } from "../EmitterSection";
import { IsNumber } from "class-validator";
import { EmitterTypes } from "../../../../hydrocalc-code/enums/Lateral/emitterTypes.enum";
import * as _ from "lodash"




export abstract class AbsCalculationProcess implements ICalculationProcess {
    public static readonly DISKIN_COEFFICIENT = 9 * Math.pow(10, 4);
    public static readonly DISKIN_SMALL_DIAMETERS_COEFFICIENT = 0.4364;
    public static readonly HAZEN_COEFFICIENT = 1.131 * Math.pow(10, 9);
    public static readonly K2_LOCAL_LOSS_COEFFICIENT_SUBMAIN = 6.376 * Math.pow(10, 3);
    public static readonly K2_LOCAL_LOSS_COEFFICIENT_LATERAL = 6.376 * Math.pow(10, -3);
    public static readonly VELOCITY_COEFFICIENT = 353.677;
    public static readonly MAX_PRESSURE_LOSS_PRECENT = 1.025;
    public static readonly HAZEN_POW1_COEFFICIENT = 1.852;
    public static readonly HAZEN_POW2_COEFFICIENT = -4.8655;
    public static readonly ATMOSPHERE_PRESSURE = 0.3;
    public static readonly COLLECTOR_PRESSURE = 1.5;
    public static readonly FLUSHING_VELOCITY_OTHER = "OTHER";
    public static readonly PIPES_ROUGHNESS_DEFAULT_VALUE = 150;
    public static readonly MAX_ROUGHNESS = "150";
    public static readonly MAX_LENGTH_SUBMAIN = 1000;
    public static readonly MAX_LENGTH_LATERAL = 1000;

    public static readonly SUBMAIN_CALC_Q2_MIN_PIPE_SECTION_LENGTH = 20;


    public abstract initSegments(data: any, segments, slopes, pipes, isFlushing);
    public abstract calculate(segments, pipes, slopes, data);
    protected abstract diskin(data: any): number;
    protected abstract hazen(data: any): number;
    protected abstract getLocalHeadLoss(calcData: any, segment: any);
    protected abstract calcPrevSegFlowRate(data: any);
    protected abstract handleNegativeFlushing(flushingResults: any, flushingPipes: any, data: any);

    // -------------------------------------------------- COMMON METHODS -----------------------------------------------

    public start(data: any) {
        let isFlushing = false;
        let flushingResults: any;
        let flushingPipes: any;
        let flushingSlopes: any;
        let flushingSegments: any;
        let userIsFlushing = data.isFlushingMode;

        //add user input for flushin
        data.userIsFlushing = userIsFlushing

        let calculationType = data.calcType;

        //initiate segment - divide to segments
        let calcEntities = this.createCalcEntities(data, isFlushing, null, null);
        let pipes = calcEntities.pipes;
        let slopes = calcEntities.slopes;
        let segments = calcEntities.segments;
        let isItFlushing = true
        // Init segments
        this.initSegments(data, segments, slopes, pipes, isFlushing);
        // calculate result for each segment, start from last
        data.isFlushingMode = false;
        let calculationResults: any = this.calculate(segments, pipes, slopes, data);

        //round results to pipes 2 numbers  agter the point
        pipes = this.roundPipesResults(calculationResults, pipes, data.inletPressureUI, !isItFlushing, data.calculator);

        let emitter = calculationResults.emitter;
        // Flushing calculation;
        let isToCalcFlushing = this.isToCalcFlushing(data, userIsFlushing, calculationResults.errors);

        if (isToCalcFlushing) {
            //update total length for data

            if (data.lateralLength && calculationResults.totalLength < data.lateralLength) {
                data.lateralLength = calculationResults.totalLength
            }

            if (data.blockChars.totalLength && calculationResults.totalLength < data.blockChars.totalLength) {
                data.blockChars.totalLength = calculationResults.totalLength
            }
            if (data.blockChars.totalLengthWithFlushing) {
                data.blockChars.totalLength = data.blockChars.totalLengthWithFlushing
            }

            this.setFlushingSettings(data, pipes); //get parameters for flushing calculation (lasst flow rate, end velocity, end pressure)
            isFlushing = true;
            let segmentsCopy = JSON.parse(JSON.stringify(segments));
            let pipesCopy = JSON.parse(JSON.stringify(pipes));
            calcEntities = this.createCalcEntities(data, isFlushing, pipesCopy, slopes, segmentsCopy);
            flushingPipes = calcEntities.pipes;
            if (data.calculator != CalculatorTypes.MAINLINE) {
                flushingPipes.sort((a, b) => b.NominalDiameter - a.NominalDiameter);
            }
            flushingSlopes = calcEntities.slopes;
            flushingSegments = calcEntities.segments;
            if (flushingSegments.length < 2) {
                flushingResults = null
            }
            else {
                // Init segments
                this.initSegments(data, flushingSegments, flushingSlopes, flushingPipes, isFlushing);
                // calculate result for each segment, start from last
                flushingResults = this.calculate(flushingSegments, flushingPipes, flushingSlopes, data);
                flushingResults.totalLength = calculationResults.totalLength || calculationResults.submainLength;
                this.handleNegativeFlushing(flushingResults, flushingPipes, data);
                this.handleFlushingWarning(flushingPipes, data, calculationResults);
                // Reset warnings: 
                flushingResults.warnings = [];
                flushingResults.errors = [];
                flushingPipes = this.roundPipesResults(flushingResults, flushingPipes, data.inletPressureUI, !isItFlushing, data.calculator);
            }
        }
        this.handlePressuresWarnings(calculationResults, data);

        calculationResults.inletPressure = data.blockChars.inlet_pressure || null;
        let { calcResults, flushing } = this.wrapResults(calculationResults, segments, slopes, pipes, flushingResults, flushingPipes, flushingSlopes, flushingSegments, emitter)


        // Return results:
        return { calcResults, flushing };
    }

    protected handleFlushingWarning(flushingPipes: any, data: any, calculationResults) {
        if (data.inletPressureUI && data.inletPressureUI < flushingPipes[0].InletPressure) {
            // Proceed calculation with warning:
            this.addWarning(calculationResults.warnings, ECalculationWarnings.FLUSHING_INLET_PRESSURE_BIGGER_THAN_INLET_PRRESURE_INPUT);
        }
    }


    private handlePressuresWarnings(calculationResults: any, calculationData: any) {
        if (calculationResults.warnings) {
            let technicalPressures = calculationResults.warnings.filter(warn => warn.Id == ECalculationWarnings.EMITTER_TECHNICAL_PRESSURE_WARNING);
            if (technicalPressures.length > 0) {
                // remove design pressure warnings:
                let newWrnings = calculationResults.warnings.filter(warn => warn.Id != ECalculationWarnings.EMITTER_DESIGN_PRESSURE_WARNING);
                calculationResults.warnings = newWrnings;
            }
        }

        if (calculationData.inletPressureOutOfUserRange) {
            this.addWarning(calculationResults.warnings, ECalculationWarnings.INLET_PRESSURE_OUT_OF_USER_RANGE);
        }
    }

    protected handleSegmentsCalculation(segmentsCalcData: any): any {
        let calcData: any = segmentsCalcData.calcData;
        let isFlushing = calcData.isFlushingMode;
        let isLastSectionForFlushing = calcData.addSectionForFlushing;
        let calculationType = segmentsCalcData.type;
        let pipes: PipeSection[] = segmentsCalcData.pipes;
        let slopes: PipeSection[] = segmentsCalcData.slopes;
        let segments: Segment[] = segmentsCalcData.segments;
        let lastFlowRate: number = segmentsCalcData.lastFlowRate;
        let maxFlowRate = lastFlowRate;
        let minFlowRate = lastFlowRate;
        let isReqtangle = calcData.blockChars.is_rectangular;
        let spacing = calcData.blockChars.distance_between_laterals;
        let maxFV = calcData.maxAllowedFlowVariation;
        let maxEU = calcData.maxAllowedEmissionUniformity;
        let totalLengthForFlushing = calcData.blockChars.totalLengthWithFlushing || calcData.blockChars.totalLength || calcData.lateralLength || 0;
        let lengthSum = segmentsCalcData.calcData.isFlushingMode ? totalLengthForFlushing : this.calcPipeSumLength(segmentsCalcData);
        let totalLengthForCurrentSegments = segmentsCalcData.calcData.isFlushingMode ? totalLengthForFlushing : this.calcPipeSumLength(segmentsCalcData);

        let pipesIndex: number = segmentsCalcData.pipesIndex || 0;
        let maxPressureLoss = calcData.maxPressureLoss || null;
        let currPipeSection = this.getCurrPipeSectionInit(calculationType, pipes, pipesIndex, segments);
        let pressureLossSum = 0;
        segmentsCalcData.warnings = [];
        segmentsCalcData.errors = [];

        let minPressure = segments[segments.length - 1].EndPressure;
        let maxPressure = segments[segments.length - 1].EndPressure;
        let minPressureSegementId = segments[segments.length - 1].Id
        let maxPressureSegementId = segments[segments.length - 1].Id
        let systemGeneralPressureLoss = 0;

        //desired inlet pressure is the inlet pressure user's input
        let segment_index_for_desired_inlet_pressure = undefined

        if (calculationType == SubmainCalculationType.VERIFICATION) {
            pipes.forEach(pipe => {
                pipe.MinPressure = Number(pipe.MaxAllowedPressure);
            });
        }
        // Calc segment PL:
        for (let index = segments.length - 1; index >= 0; index--) {
            let currSegment = segments[index];
            lengthSum -= currSegment.Length;
            lengthSum = Number(lengthSum.toFixed(2))

            if (index != segments.length - 1) {
                // Curr segment end pressue is prev segment inlet pressure:
                currSegment.EndPressure = segments[index + 1].InletPressure;
            }

            // initSingleSegCalc:
            this.initSegmentPipeSection({ segment: currSegment, pipe: pipes[pipesIndex], calculationType, segmentsCalcData });

            if (calculationType == SubmainCalculationType.VERIFICATION) {
                currPipeSection = currSegment.PipeSection;
            }

            // End calculation if there is no pipe for the segments
            if (calculationType == MainlineCalculationType.PIPES_DIAMETERS && segmentsCalcData.errors[0] && segmentsCalcData.errors[0].Id == ECalculationErrors.NO_RESULTS) {
                return { isCalcDone: true, systemPL: systemGeneralPressureLoss };
            }

            // Calc pressure loss:
            let segPerssureLoss = this.calcSegmentPressureLoss(currSegment, calcData);;

            // Update segment:
            currSegment.Pressureloss = segPerssureLoss;
            currSegment.InletPressure = currSegment.EndPressure + currSegment.Pressureloss;

            // Get relevant index when segment's inlet pressure =< to user's inlet pressure input
            if (calcData.inletPressureUI && currSegment.InletPressure > calcData.inletPressureUI && segment_index_for_desired_inlet_pressure == undefined) {
                segment_index_for_desired_inlet_pressure = index + 1 >= segments.length ? undefined : index + 1;
            }

            if (index != segments.length - 1 && (currSegment.PipeSection.Id != segments[index + 1].PipeSection.Id)) {
                // Section switch:
                segments[index + 1].PipeSection.InletPressure = segments[index + 1].InletPressure;
            }

            if (index != segments.length - 1 && (currSegment.SlopeSection.Id != segments[index + 1].SlopeSection.Id)) {
                // Slope switch:
                segments[index + 1].SlopeSection.InletPressure = segments[index + 1].InletPressure;
            }

            // Negative pressure stop:
            if ((currSegment.InletPressure <= 0 || currSegment.EndPressure <= 0) && !calcData.isEndPressureCalculation && isFlushing == false) {
                this.addWarning(segmentsCalcData.warnings, ECalculationWarnings.NEGATIVE_PRESSURE)
            }


            // Update min/max pressure:     
            let pressures = this.handlePressures(minPressure, maxPressure, minPressureSegementId, maxPressureSegementId, currSegment);

            minPressureSegementId = pressures.minPressureSegementId
            maxPressureSegementId = pressures.maxPressureSegementId
            minPressure = pressures.minPressure;
            maxPressure = pressures.maxPressure;

            //calculate system Pressure Loss and checking from what segments we get min and max pressure
            if (maxPressureSegementId > minPressureSegementId) {
                systemGeneralPressureLoss = minPressure - maxPressure;
            } else {
                systemGeneralPressureLoss = maxPressure - minPressure;
            }

            // Calculation process stops:
            let calcStopsRes = this.handleCalculationStops({
                pipes, slopes, calculationType, currPipeSection, currSegment, pressureLossSum, segPerssureLoss, systemGeneralPressureLoss, maxPressureLoss,
                segments, maxFlowRate, minFlowRate, maxFV, maxEU, spacing, totalLengthForCurrentSegments, index, lengthSum, isEndPressureCalculation: calcData.isEndPressureCalculation,
                inletPressureUI: calcData.inletPressureUI, isFlushingMode: calcData.isFlushingMode,
                maxPressureAllowed: calcData.maxPressureAllowed, isCalcByFlowVariation:calcData.isCalcByFlowVariation
            });
            if (calcStopsRes.error && !isFlushing) {
                // End calculation with error:
                this.addError(segmentsCalcData.errors, calcStopsRes.error);
                return { isCalcDone: calcStopsRes.isCalcDone, systemPL: systemGeneralPressureLoss };
            }
            if (calcStopsRes.warning && !isFlushing) {
                // Proceed calculation with warning:
                this.addWarning(segmentsCalcData.warnings, calcStopsRes.warning);
            }
            if (calcStopsRes.isStopIteration && !isFlushing) {
                return {
                    isCalcDone: calcStopsRes.isCalcDone, systemPL: systemGeneralPressureLoss - segPerssureLoss, isInletPressureUIstop: calcStopsRes.InletPressureUIstop,
                    isStopIteration: calcStopsRes.isStopIteration, update_current_segment: calcStopsRes.update_current_segment, segmentIndex: calcStopsRes.segmentIndex
                };
            }

            // Sum Pressure loss:
            pressureLossSum += segPerssureLoss;
            // Calc next calculation seg flow rate:
            if (index > 0) {
                let flowRate = this.calcPrevSegFlowRate({ isReqtangle, lengthSum, calcData, segments, index, currSegment, lastFlowRate })
                let flowRates = { minFlowRate, maxFlowRate, flowRate }
                flowRates = this.setMinMaxFlowRates(flowRates);
                minFlowRate = flowRates.minFlowRate;
                maxFlowRate = flowRates.maxFlowRate;
            }

            // Update curr Segments Pipe Section data: 
            this.updateSegmentPipeSection(currSegment);
        }





        // Set slopes and pipes sections data:
        this.setSectionsAggregatedData({ pipes, slopes, segments, isFlushing, isLastSectionForFlushing });
        segmentsCalcData.minFlowRate = minFlowRate;
        segmentsCalcData.maxFlowRate = maxFlowRate;
        // Post calculation process actions:
        let postCalcRes = this.postCalcActions({ calculationType, pipes, maxPressureLoss, segmentsCalcData });
        if (postCalcRes.error && !isFlushing) {
            // End calculation with error:
            this.addError(segmentsCalcData.errors, postCalcRes.error);
            return { isCalcDone: postCalcRes.isCalcDone, systemPL: systemGeneralPressureLoss };
        }
        if (postCalcRes.warning && !isFlushing) {
            // Proceed calculation with warning:
            this.addWarning(segmentsCalcData.warnings, postCalcRes.warning);
        }
        if (postCalcRes.isStopIteration) {
            return { isCalcDone: postCalcRes.isCalcDone, systemPL: systemGeneralPressureLoss };
        }

        // Segments calculation ended successfully:
        if (segmentsCalcData.type == SubmainCalculationType.VERIFICATION && pipes.length > 1) {
            // Proceed calculation with warning:
            this.addWarning(segmentsCalcData.warnings, ECalculationWarnings.MULTIPLE_PIPES);
        }

        return { isCalcDone: true, systemPL: systemGeneralPressureLoss, segment_index_for_desired_inlet_pressure };
    }



    private isToCalcFlushing(data: any, userIsFlushing: boolean, calcErrors: any) {
        let calculator = data.calculator;
        let isToCalcFlushing = false;
        if (data.isEndPressureCalculation) {
            isToCalcFlushing = false;
        }
        else {
            switch (calculator) {
                case CalculatorTypes.SUBMAIN:
                    if (userIsFlushing == false) {
                        isToCalcFlushing = false;
                    }
                    else {
                        isToCalcFlushing = !data.isEndPressureCalculation && calcErrors.length <= 0;
                    }
                    break;
                case CalculatorTypes.LATERAL:
                    let emitterType = data.emitter ? data.emitter.Type : data.emitters[0].Type;
                    let isSprinkler = ["Sprinklers", "Micro Sprinklers", "Online Drippers"].includes(emitterType);

                    if (userIsFlushing || (!isSprinkler && !data.isEndPressureCalculation)) {
                        isToCalcFlushing = calcErrors.length <= 0;
                    }
                    break;
                case CalculatorTypes.MAINLINE:
                    if (userIsFlushing == false) {
                        isToCalcFlushing = false;
                    }
                    else {
                        isToCalcFlushing = !data.isEndPressureCalculation && calcErrors.length <= 0;
                    }
                    break;
                default:
                    break;
            }
        }

        return isToCalcFlushing;
    }

    protected calcPipeTotalLength(pipes: any) {
        let length = 0;
        pipes.forEach(pipe => {
            length += pipe.SectionLength || pipe.segment_length || pipe.section_length
        });

        return length;
    }

    private wrapResults(calculationResults: any, segments: Segment[], slopes: SlopeSection[], pipes: PipeSection[], flushingResults: any, flushingPipes: any, flushingSlopes: any, flushingSegments: any, emitter: any): { calcResults: any; flushing: any; } {
        let calcResults = {
            calculationResults, segments, slopes, pipes, emitter, errors: calculationResults.errors
        }
        let flushing = !flushingResults ? null : {
            flushingResults, flushingSegments, flushingSlopes, flushingPipes, errors: flushingResults.errors
        }

        return { calcResults, flushing }
    }

    protected setFlushingSettings(data: any, pipes: any): any { //calculate last flow rate and get flushing end velocity
        let lastPipeSectionDiameter = pipes[pipes.length - 1].InternalDiameter;
        let flushingVelocity: any = this.getFlushingVelocityFromData(data);
        let lastOutletFlowRate = this.calcFlowRateForFlushing(lastPipeSectionDiameter, flushingVelocity)
        data.isFlushingMode = true;
        data.end_velocity = flushingVelocity

        return lastOutletFlowRate
    }

    protected getFlushingVelocityFromData(data: any) {
        let flushingTable = data.flushingVelocitiesTable;
        let flushingVelocity = data.flushingVelocity;
        if (!flushingVelocity) {
            let pipes = data.pipeChars.pipes || data.pipeChars;
            let lastPipeSection = pipes[pipes.length - 1];
            let lastPipeType = lastPipeSection.type || lastPipeSection.pipe_material;
            flushingVelocity = this.getRecommendedFlushingVelocity(flushingTable, lastPipeType);
        }

        return flushingVelocity
    }

    private getRecommendedFlushingVelocity(flushingTable: any, lastPipeType: any): any {
        let elem = flushingTable.find((fv) => fv.pipe_type_name === lastPipeType);
        if (!elem) {
            elem = flushingTable.find((fv) => fv.pipe_type_name === AbsCalculationProcess.FLUSHING_VELOCITY_OTHER);
        }
        return elem.flushing_velocity;
    }

    protected calcFlowRateForFlushing(lastPipeSectionDiameter: number, flushingVelocity: number) {
        return ((flushingVelocity * (lastPipeSectionDiameter * lastPipeSectionDiameter)) / AbsCalculationProcess.VELOCITY_COEFFICIENT);
    }

    private roundPipesResults(calculationResults: any, pipes: any, inletPressureUI: number, isFlushing: boolean = false, calculatorType: number) {

        if (calculationResults.errors.length <= 0) {
            // Pipes results:
            pipes = pipes.filter((p) => p.SectionLength > 0);
            if (calculatorType != CalculatorTypes.MAINLINE) {
                pipes.sort((a, b) => b.NominalDiameter - a.NominalDiameter);
            }
            pipes.forEach(pipe => {
                const maxPresurreCheck = pipe.Id == 1 && !isFlushing && inletPressureUI && Number(pipe.MaxPressure) < Number(inletPressureUI)
                const minPressureCheck = pipe.Id == pipes.length && !isFlushing && calculationResults.endPressure
                pipe.MaxFlowRate = IsNumber(pipe.MaxFlowRate) ? Number((pipe.MaxFlowRate).toFixed(2)) : null;
                pipe.MaxPressure = IsNumber(pipe.MaxPressure) ? (maxPresurreCheck ? inletPressureUI : Number(pipe.MaxPressure.toFixed(2))) : null;
                pipe.MinPressure = pipe.MinPressure && IsNumber(pipe.MinPressure) ?
                    (minPressureCheck && Number(pipe.MaxPressure) > Number(Number(calculationResults.endPressure).toFixed(2)) ?
                        Number(calculationResults.endPressure).toFixed(2) : Number(pipe.MinPressure.toFixed(2)))
                    : null;
                pipe.PressureLoss = isNaN(pipe.PressureLoss) == false ? Number(pipe.PressureLoss.toFixed(2)) : null;
                pipe.MaxVelocity = IsNumber(pipe.MaxVelocity) ? Number(pipe.MaxVelocity.toFixed(2)) : null;
                pipe.EndPressure = pipe.EndPressure ? Number(pipe.EndPressure.toFixed(2)) : null;
                pipe.InletPressure = pipe.InletPressure ? Number(pipe.InletPressure.toFixed(2)) : null;
                pipe.AtomicFlowRate = IsNumber(pipe.atomicFlowRate) ? Number(pipe.atomicFlowRate) : null
            });
        }

        return pipes;


    }

    protected createCalcEntities(data: any, isFlushing: boolean, _pipes: any, _slopes: any, oldSegments: any = null) {
        let segments: any;
        let slopes: any;
        let pipes: any;
        let emitters: any = data.emitters;
        let userIsFlushing = data.userIsFlushing
        let isLastSectionForFlushing = data.addSectionForFlushing
        if (!emitters) {
            emitters = []
        }

        if (isFlushing) {
            let totalLength = data.blockChars.totalLength || data.lateralLength;
            segments = this.resetSegmentsForFlushing(oldSegments, totalLength, data, data.blockChars, data.topographyChars, data.pipeChars, isFlushing,);
            pipes = this.resetPipesForFlushing({ _pipes, lastFlowRate: data.flushing_end_flow_rate, isFlushingWithClosedValves: data.isFlushingWithClosedValves });
            slopes = this.resetSlopesForFlushing(_slopes);
            emitters = this.resetEmittersForFlushing(emitters);
            // Set last calc\flushing segments:
            let lastFlushingSegment = segments[segments.length - 1];
            // Last flushing segment:
            lastFlushingSegment.EndPressure = data.flushing_end_pressure;
            lastFlushingSegment.AtomicFlowRate = data.flushing_end_flow_rate;
            lastFlushingSegment.FlowRate = data.flushing_end_flow_rate;
        }
        else {
            // Create new segments:
            segments = this.createCalculationSegments(data, data.blockChars, data.topographyChars, data.pipeChars, isFlushing, oldSegments);
            slopes = this.createSlopeSections(data.topographyChars);
            let pipesdata = data.pipeChars.pipes ? data.pipeChars.pipes : data.pipeChars;
            let emittersdata = data.emitterChars || data.emitters;
            pipes = this.createPipeSections(pipesdata, isLastSectionForFlushing, segments);
            if (emittersdata) {
                emittersdata.pipes = pipes;
            }
            emitters = this.createEmittersSections(emittersdata);
            data.emitters = emitters;
            delete data.emitterChars;
        }

        return { segments, slopes, pipes, emitters }
    }

    protected createEmittersSections(emittersdata: any): EmitterSection[] {
        return null;
    }

    private setPipesChars(data: any, _pipes: any) {
        if (_pipes && _pipes.length > 0) {
            data.pipeChars = [];

            _pipes.forEach(pipe => {
                let pipeChars = {
                    class: pipe.Class,
                    internal_diameter: pipe.InternalDiameter,
                    kd_local_friction_factor: pipe.KD,
                    max_allowable_design_pressure: pipe.MaxAllowedPressure,
                    max_velocity: pipe.MaxVelocity,
                    nominal_diameter: pipe.NominalDiameter,
                    roughness: pipe.Roughness,
                    segment_length: pipe.SectionLength,
                    type: pipe.Type
                }
                data.pipeChars.push(pipeChars);
            });
        }
    }

    protected setTopoChars(data: any, _slopes: any) {
    }

    private setTopoRealLength(data: any) {
        let pipesTotalLength = 0;
        let slopesTotalLength = 0;
        let slopes = data.topographyChars;
        let pipes = data.pipeChars.pipes ? data.pipeChars.pipes : data.pipeChars;

        pipes.forEach(pipe => {
            pipesTotalLength += pipe.segment_length;
        });

        slopes.forEach(slope => {
            slopesTotalLength += slope.real_length;
        });

        if (pipesTotalLength !== slopesTotalLength) {
            let dif = pipesTotalLength - slopesTotalLength;
            let lastSlope = slopes[slopes.length - 1];
            // Extend last slope:
            lastSlope.real_length += dif;
        }
    }

    protected postCalcActions(data: any): any {
        return {}; 
    }

    protected handleCalculationStops(data: any): any {
        return {};
    }

    protected getCurrPipeSectionInit(calculationType: any, pipes: PipeSection[], pipesIndex: number, segments: any = null) {
        return pipes[pipesIndex];
    }

    protected initSegmentPipeSection(data: any) {

    }

    protected addWarning(warnings: any, warningType: ECalculationWarnings) {
        let warning = CalculationWarnings.get(warningType);
        let war = warnings.filter((w) => w.Id === warning.Id);
        if (war.length == 0) {
            warnings.push(warning);
        }
    }

    protected addError(errors: any, errorType: ECalculationErrors) {
        let error = CalculationErrors.get(errorType);
        let err = errors.filter((e) => e.Id === error.Id);
        if (err.length == 0) {
            errors.push(error);
        }
    }

    protected isSegmentIsOnLateral(lengthSum: any, distance_between_laterals: any): boolean {
        var res = lengthSum % distance_between_laterals;
        res = Number(res.toFixed(2));

        return res === 0 || res === distance_between_laterals;
    }

    protected updateSegmentPipeSection(currSegment: Segment) {
        let currSection = currSegment.PipeSection;
        // Update curr Segments Pipe Section data: 
        currSection.MaxPressure = this.setMaxOrMin(currSegment.PipeSection.MaxPressure, currSegment.EndPressure, true);
        currSection.MaxPressure = this.setMaxOrMin(currSegment.PipeSection.MaxPressure, currSegment.InletPressure, true);
        currSection.MinPressure = this.setMaxOrMin(currSegment.PipeSection.MinPressure, currSegment.EndPressure, false);
        currSection.MinPressure = this.setMaxOrMin(currSegment.PipeSection.MinPressure, currSegment.InletPressure, false);
        currSection.MaxFlowRate = this.setMaxOrMin(currSegment.PipeSection.MaxFlowRate, currSegment.FlowRate, true);
    }

    protected setSectionsAggregatedData(calculationData: any) {
        // Set pipes Data:
        this.setPipesData(calculationData);
        // Set slopes Data:
        this.setSlopesData(calculationData);

        return calculationData;
    }

    protected setSlopesData(calculationData: any) {
        // Update slopes:
        calculationData.slopes[0].InletPressure = calculationData.segments[0].InletPressure;
        calculationData.slopes[calculationData.slopes.length - 1].EndPressure = calculationData.segments[calculationData.segments.length - 1].EndPressure;

        if (calculationData.shiftedSeg) {
            // Remove shifted segment:
            const shifted = calculationData.shiftedSeg;
            calculationData.slopes[0].SectionLengthOnMap -= shifted.Length;
            calculationData.slopes[0].SectionRealLength -= shifted.Length;
        }
    }

    private handlePressures(minPressure: number, maxPressure: number, minPressureSegementId: number, maxPressureSegementId: number, currSegment: Segment): any {
        // Update min/max pressure:


        let result: any;
        result = this.setMaxOrMinForPressures(minPressure, currSegment.InletPressure, minPressureSegementId, currSegment.Id, false);
        minPressure = result.pressure;
        minPressureSegementId = result.segmentId;


        result = this.setMaxOrMinForPressures(minPressure, currSegment.EndPressure, minPressureSegementId, currSegment.Id, false);
        minPressure = result.pressure;
        minPressureSegementId = result.segmentId;

        result = this.setMaxOrMinForPressures(maxPressure, currSegment.InletPressure, maxPressureSegementId, currSegment.Id, true);
        maxPressure = result.pressure;
        maxPressureSegementId = result.segmentId;

        result = this.setMaxOrMinForPressures(maxPressure, currSegment.EndPressure, maxPressureSegementId, currSegment.Id, true);
        maxPressure = result.pressure;
        maxPressureSegementId = result.segmentId;
        // let systemGeneralPressureLoss = maxPressure - minPressure;

        return { minPressure, minPressureSegementId, maxPressure, maxPressureSegementId };
    }

    protected resetSegmentsCalcData(segmentsCalcData: any) {
        // Reset Segments:
        for (let index = 0; index < segmentsCalcData.segments.length; index++) {
            const seg = segmentsCalcData.segments[index];
            seg.Id = index + 1;
            seg.AtomicFlowRate = 0;
            seg.EndPressure = 0;
            seg.FlowRate = 0;
            seg.InletPressure = 0;
            seg.Pressureloss = 0;
            if (segmentsCalcData.type != LateralCalculationType.VERIFICATION && segmentsCalcData.type != SubmainCalculationType.PIPES_MAX_LENGTH && segmentsCalcData.calcData.calculator != CalculatorTypes.MAINLINE) {
                seg.PipeSection = null;
            }
        }
        // Reset Slopes:
        segmentsCalcData.slopes.forEach(slope => {
            slope.InletPressure = 0;
            slope.EndPressure = 0;
        });
        // Reset Pipes:
        segmentsCalcData.pipes.forEach(p => {
            p.MaxFlowRate = 0;
            p.MaxPressure = 0;
            p.MaxVelocity = 0;
            p.InletPressure = 0;
            p.EndPressure = 0;
            p.PressureLoss = 0;

            if (segmentsCalcData.type != SubmainCalculationType.VERIFICATION && segmentsCalcData.type != SubmainCalculationType.PIPES_MAX_LENGTH && segmentsCalcData.calcData.calculator != CalculatorTypes.MAINLINE) {
                p.SectionLength = 0;
            }
        });

        // Reset Last segment:
        let lastSegment = segmentsCalcData.segments[segmentsCalcData.segments.length - 1];
        let endPressure = segmentsCalcData.calcData.blockChars.end_pressure;

        lastSegment.FlowRate = segmentsCalcData.lastFlowRate;
        lastSegment.AtomicFlowRate = segmentsCalcData.lastFlowRate;
        lastSegment.EndPressure = endPressure;
        if (segmentsCalcData.type != SubmainCalculationType.VERIFICATION && segmentsCalcData.type != SubmainCalculationType.PIPES_MAX_LENGTH && segmentsCalcData.calcData.calculator != CalculatorTypes.MAINLINE) {
            lastSegment.PipeSection = segmentsCalcData.pipes[segmentsCalcData.pipesIndex];
        }
    }

    protected setPipesToSegments(segments: Segment[], allPipes: PipeSection[], currPipeIndex: number, firstSectionLength: number, submainLength: any): any {
        let currPipeSection = allPipes[currPipeIndex];

        let secondSectionLength = submainLength - firstSectionLength;

        let firstPipeSection = currPipeSection;
        let secondPipeSection = allPipes[currPipeIndex - 1];

        firstPipeSection.SectionLength = firstSectionLength;
        secondPipeSection.SectionLength = secondSectionLength;

        let newPipes = [firstPipeSection, secondPipeSection];
        // Re-init segments:
        this.addPipeSectionsToSegments(segments, newPipes);
        return newPipes;
    }

    public createCalculationSegments(data: any, blockChars: any, topographyChars: any, pipeChars: any, isFlushing: boolean, oldSegments: any = null) {
        let segments: Segment[] = [];
        let submainLength = blockChars.submain_pipe_length || this.calcPipeTotalLength(pipeChars);
        blockChars.totalLength = submainLength;
        let lateralDistance = blockChars.distance_between_laterals;
        let numOfLaterals = blockChars.num_of_laterals || Math.floor(submainLength / lateralDistance);
        let sum = 0;

        // Create lengths segments array:
        let lengthsArr: number[] = this.createLengthsArr(submainLength, numOfLaterals, lateralDistance, pipeChars, topographyChars, isFlushing);
        if (!lengthsArr) {
            throw new ErrorMsgs('Error occured while calculating result - Pipes sections total length should be at least as pipe real length', null, true)
        }
        // Create First Calculation Segments:
        let seg1 = new Segment(1);
        seg1.Length = lengthsArr[0];
        sum += seg1.Length;
        segments.push(seg1);
        // Create Calculation Segments:
        for (let i = 1; i < lengthsArr.length; i++) {
            let seg = new Segment(i + 1);

            if (i < lengthsArr.length) {
                seg.Length = Number((lengthsArr[i] - lengthsArr[i - 1]).toFixed(2));
                sum += seg.Length;
                sum = Number(sum.toFixed(2))
            }

            segments.push(seg);
        }

        return segments;
    }

    public createSlopeSections(slopeSectionsData: any) {
        let numOfSlopeSections = slopeSectionsData.length;
        let slopes: SlopeSection[] = [];

        for (let index = 0; index < numOfSlopeSections; index++) {
            let slope = new SlopeSection();
            slope.Id = index + 1;
            let slopeData = slopeSectionsData[index];
            slope.HeightDiffInMeters = slopeData.height_diff_meters;
            slope.HeightDiffPrecent = slopeData.heigth_diff_precent
            slope.SectionLengthOnMap = slopeData.length_on_map;
            slope.SectionRealLength = slopeData.real_length;

            slopes.push(slope);
        }

        return slopes;
    }

    public createPipeSections(pipeCharsData: any, isLastSectionForFlushing: boolean, segments: Segment[]) {
        let numOfPipeSections = pipeCharsData.length;
        let pipes: PipeSection[] = [];
        let previousSectionflowRate: number
        pipeCharsData = pipeCharsData.sort((a, b) => Number(b.nominal_diameter) - Number(a.nominal_diameter))
        for (let index = 0; index < numOfPipeSections; index++) {
            let pipe = new PipeSection();
            pipe.Id = index + 1;
            let pipeData = pipeCharsData[index];
            let rough = pipeData.pipe_roughness || pipeData.roughness || null;
            if (rough) {
                rough = Number(rough) > Number(AbsCalculationProcess.MAX_ROUGHNESS) ? AbsCalculationProcess.MAX_ROUGHNESS : rough;
            }
            pipe.Type = pipeData.pipe_material || pipeData.type;;
            pipe.Class = pipeData.pipe_class || pipeData.class;
            pipe.NominalDiameter = pipeData.nominal_diameter
            pipe.InternalDiameter = pipeData.internal_diameter
            pipe.SectionLength = pipeData.segment_length || 0;
            pipe.KD = Number(pipeData.kd_local_friction_factor);
            pipe.Roughness = rough || AbsCalculationProcess.PIPES_ROUGHNESS_DEFAULT_VALUE;
            pipe.MaxPressure = 0;
            pipe.MaxAllowedVelocity = pipeData.max_velocity;
            pipe.MaxAllowedPressure = pipeData.max_allowable_design_pressure ? Number(pipeData.max_allowable_design_pressure) : Number(pipeData.max_allowable_technical_pressure);
            pipes.push(pipe);
        }
        pipes.sort((a, b) => Number(b.NominalDiameter) - Number(a.NominalDiameter));
        return pipes;
    }

    protected addPipeSectionsToSegments(segments: Segment[], pipes: PipeSection[]) {
        let meters = 0;

        for (let index = 0; index < segments.length; index++) {
            meters += segments[index].Length;
            meters = Number(meters.toFixed(2));
            let pipeSection = this.getPipeSection(meters, pipes);

            segments[index].PipeSection = pipeSection;
        }
    }

    protected addEmitterSectionsToSegments(segments: Segment[], emitters: EmitterSection[]) {
        let meters = 0;
        let emitter = null;
        if (emitters.length == 1) {
            emitter = emitters[0];
        }

        for (let index = 0; index < segments.length; index++) {
            meters += segments[index].Length;
            meters = Number(meters.toFixed(2));
            let emitterSection = this.getEmitterSection(meters, emitters);

            segments[index].EmitterSection = emitter ? emitter : emitterSection;
        }
    }

    protected addSlopeSectionsToSegments(segments: Segment[], slopes: SlopeSection[]) {
        let meters = 0;

        for (let index = 0; index < segments.length; index++) {
            meters += segments[index].Length;
            meters = Number(meters.toFixed(2));
            let slopeSection = this.getSlopeSection(meters, slopes);
            segments[index].SlopeSection = slopeSection;
        }
    }

    protected createLengthsArr(submainLength: any, numOfLaterals: number, lateralDistance: any, pipeChars: any, topographyChars: any, isFlushingMode: boolean): number[] {
        let arr: number[] = [];
        let lengthSet: Set<number> = new Set();
        let len = 0;
        let pipeslen = 0;
        let pipes = pipeChars.pipes ? pipeChars.pipes : pipeChars;
        let lastLateralPos = numOfLaterals * lateralDistance;
        lastLateralPos = Number(lastLateralPos.toFixed(2));

        if (pipes) {
            pipeslen = 0;
            // Insert pipes segments lengths:
            for (let index = 0; index < pipes.length; index++) {
                pipeslen += pipes[index].segment_length;
                if (pipeslen > 0 && pipeslen <= submainLength) {
                    if (pipeslen < lastLateralPos || isFlushingMode) {
                        lengthSet.add(pipeslen);
                    }
                }
                else {
                    if (pipeslen > 0 && isFlushingMode) {
                        // Add last length: 
                        lengthSet.add(pipeslen);
                    }
                }
            }
        }

        len = 0;
        // Insert topography segments lengths:
        for (let index = 0; index < topographyChars.length; index++) {
            len += topographyChars[index].real_length;
            if (len <= submainLength) {
                if (pipeslen < lastLateralPos || isFlushingMode) {
                    lengthSet.add(len);
                }
            }
            else {
                if (isFlushingMode) {
                    lengthSet.add(len);
                }
            }
        }

        if (pipeslen < len) {
            // pipe sections are less then real pipe length
            return null;
        }

        len = 0;

        // Insert laterals segments lengths:
        for (let index = 0; index < numOfLaterals; index++) {
            len = Number(((index + 1) * lateralDistance).toFixed(2));
            if (len <= submainLength) {
                lengthSet.add(len);
            }
        }
        arr = Array.from(lengthSet);
        // sort arr:
        arr.sort(function (a, b) { return a - b });

        return arr;
    }

    protected setMaxOrMin(sectionPressure: number, pressure: number, isMax: boolean) {
        if (isMax) {
            return sectionPressure >= pressure ? sectionPressure : pressure
        }
        else {
            //is Min:
            return sectionPressure <= pressure ? sectionPressure : pressure
        }
    }
    protected setMaxOrMinForPressures(sectionPressure: number, pressure: number, sectionPressureSegementId: number, currentSegementId: number, isMax: boolean) {
        let result: any;
        if (isMax) {
            if (sectionPressure >= pressure) {
                return { pressure: sectionPressure, segmentId: sectionPressureSegementId }

            } else {
                return { pressure, segmentId: currentSegementId }
            }
        }
        else {
            //is Min:
            if (sectionPressure <= pressure) {
                return { pressure: sectionPressure, segmentId: sectionPressureSegementId }

            } else {
                return { pressure, segmentId: currentSegementId }
            }
        }
    }

    protected calcSegmentPressureLoss(segment: Segment, calcData: any) {
        //                          friction loss              local loss      Topograpic loss
        //  HT-j	=	aa	Hf-j(D-W)	+	bb	Hf-j(H-W)	+	cc	Hd-j	+	dd	Ht-j
        let fLoss = this.getFrictionLoss(calcData, segment);
        let lLoss = this.getLocalHeadLoss(calcData, segment);
        let tLoss = this.getElevationLoss(calcData, segment);

        let segmentPressureLoss = fLoss + lLoss + tLoss;
        segment.Frictionloss = fLoss;
        segment.LocalHeadloss = lLoss;
        segment.Topoloss = tLoss;

        return segmentPressureLoss;
    }

    /**
     * Gets pressure drop caused by friction, using one of the 
     * available formulas.
     * 
     * @param formula 
     * @param data 
     * @returns pressure drop by friction 
     */
    private getFrictionLoss(calcData: any, segment: any): number {

        let pressureDropByFriction;
        switch (calcData.frictionFormula) {
            case FrictionPressureLossFormula.Diskin:
                pressureDropByFriction = this.diskin({ calcData, segment });
                break;

            case FrictionPressureLossFormula.HazenWilliams:
                pressureDropByFriction = this.hazen({ calcData, segment });
                break;

            default:
                throw new Error(`Error - Unknown formula received: ${calcData.frictionFormula}`);

        }

        return pressureDropByFriction;
    }

    private getElevationLoss(calcData: any, segment: any): number {
        let segmentLength = segment.Length;
        let highDiff = segment.SlopeSection.HeightDiffPrecent / 100;

        return segmentLength * highDiff
    }

    /**
     * calcLateralXFlowRateForCalculation
     * calc the X'th lateral Flow Rate for calculation use
     * 
     */
    public calcTrapezLateralFlowRateForCalculation(firstLateralLength, lastLateralLength, lastLateralFlowRate, lateralNumber, numOfLaterals): number {
        // Calc first Lateral Atomic Flow Rate
        let firstLateralAtomicFlowRate = (firstLateralLength * lastLateralFlowRate) / lastLateralLength;
        // Calc diff between lateral lengths:
        let d = (lastLateralFlowRate - firstLateralAtomicFlowRate) / (numOfLaterals - 1);
        // Calc atomic flow rate:
        let atomicFlowRate: number = firstLateralAtomicFlowRate + (lateralNumber - 1) * d;
        return atomicFlowRate;
    }

    // Overrided
    public calcLastLateralFlowRateForCalculation(numOfLaterals: number, isReqtangular: boolean, lastLateralFlowRate: number, totalLateralFlowRate: number = null, lastLateralLength: number = null, firstLateralLength: number = null): number {
        let flowRate: number;
        return flowRate;
    }

    protected getPipeSection(currMeters: number, pipes: PipeSection[]) {
        let sectionsMeter = 0;
        for (let index = 0; index < pipes.length; index++) {
            let pipe = pipes[index];
            sectionsMeter = Math.round((sectionsMeter + pipe.SectionLength) * 1e12) / 1e12
            if (currMeters <= sectionsMeter) {
                return pipe;
            }
        }
    }

    private getEmitterSection(currMeters: number, emitters: EmitterSection[]) {
        let sectionsMeter = 0;
        for (let index = 0; index < emitters.length; index++) {
            let emitter = emitters[index];
            sectionsMeter += emitter.SectionLength;

            if (currMeters <= sectionsMeter) {
                return emitter;
            }
        }
    }

    private getSlopeSection(currMeters: number, slopes: SlopeSection[]) {
        let sectionsMeter = 0;
        for (let index = 0; index < slopes.length; index++) {
            let slope = slopes[index];
            sectionsMeter = Math.round((sectionsMeter + slope.SectionRealLength) * 1e12) / 1e12
            if (currMeters <= sectionsMeter) {
                return slope;
            }
        }
    }

    protected getSegmentLateralNum(lengthSum: number, lateralsLength: number, numOfLaterals: number) {
        for (let index = 1; index <= numOfLaterals; index++) {
            if (lengthSum <= index * lateralsLength) {
                return index;
            }
        }

        // Error:
        return -1;
    }

    protected setPipesData(calculationData: any) {
        // Get selected pipes:
        calculationData.pipes = calculationData.pipes.filter((p) => p.SectionLength > 0);

        // Update pipes:
        calculationData.pipes[0].InletPressure = calculationData.segments[0].InletPressure;
        calculationData.pipes[calculationData.pipes.length - 1].EndPressure = calculationData.segments[calculationData.segments.length - 1].EndPressure;
        calculationData.pipes[0].MaxFlowRate = calculationData.segments[0].FlowRate;

        for (let index = calculationData.pipes.length - 1; index >= 0; index--) {
            let pipe = calculationData.pipes[index];
            let r = pipe.InternalDiameter;
            // Set max pipes velocity:
            pipe.MaxVelocity = AbsCalculationProcess.VELOCITY_COEFFICIENT * (pipe.MaxFlowRate / (r * r))
            // Set Inlet/out pressure:
            if (index < calculationData.pipes.length - 1) {
                pipe.EndPressure = calculationData.pipes[index + 1].InletPressure;

            }
        }

        if (calculationData.shiftedSeg) {
            // Remove shifted segment:
            const shifted = calculationData.shiftedSeg;
            calculationData.pipes[0].SectionLength -= shifted.Length;
        }

    }

    protected summarizeResults(segments: Segment[], pipes: PipeSection[], systemPL: number, recomendedVelocity: number, isFlushingMode: boolean = false, endFlushingVelocity: number = null, segment_index_for_desired_inlet_pressure: number = undefined) {
        let totalPressureLoss = 0;
        let totalMaxPressure: number;
        let totalMinPressure: number;
        let endVelocity: number;
        let lastFlowRate: number;
        let endPressure: number;
        let totalPressureLossForUserInletPressureInput = 0
        let totalMaxPressureForUserInletPressureInput: number;
        let totalMinPressureForUserInletPressureInput: number;
        let maxVelocityForUserInletPressureInput = 0
        let totalLengthForUserInletPressureInput: number = 0
        let endVelocityForUserInletPressureInput: number;
        let lastFlowRateForUserInletPressureInput: number;
        let endPressureForUserInletPressureInput: number;
        let result_for_user_inlet_pressure
        let warnings = [];
        let errors = [];

        // Get selected pipes:
        pipes = pipes.filter((p) => p.SectionLength > 0);
        // Set Total Min/Max Pressure: 
        totalMaxPressure = pipes[0].MaxPressure;
        totalMinPressure = pipes[0].MinPressure;

        let maxVelocity = 0;

        pipes.forEach(pipe => {
            // Max/Min Pressure:
            if (pipe.MaxPressure > totalMaxPressure) {
                totalMaxPressure = pipe.MaxPressure;
            }
            if (pipe.MinPressure < totalMinPressure) {
                totalMinPressure = pipe.MinPressure;
            }
            // Max velocity:
            maxVelocity = this.setMaxOrMin(maxVelocity, pipe.MaxVelocity, true);
            // Max velocity pressure for user's input inlet pressure:
            maxVelocityForUserInletPressureInput = maxVelocity;
            // Warnings:
            if (pipe.MaxPressure > pipe.MaxAllowedPressure) {
                this.addWarning(warnings, ECalculationWarnings.PIPE_MAX_ALLOWED_PRESSURE);
                pipe.max_pressure_warning = true;
            }
            if (Number(pipe.MaxVelocity) > Number(pipe.MaxAllowedVelocity)) {
                this.addWarning(warnings, ECalculationWarnings.PIPE_MAX_ALLOWED_VELOCITY)
                pipe.max_velocity_warning = true;
            }

            pipe.PressureLoss = pipe.MaxPressure - pipe.MinPressure; // Max - Min pressure:
            if (pipe.InletPressure < pipe.MaxPressure) {
                pipe.PressureLoss *= -1;
            }
            pipe.PressureLoss = Number(pipe.PressureLoss.toFixed(2))
            pipe.SectionLength = Number(pipe.SectionLength.toFixed(2));
        });

        totalPressureLoss = systemPL

        // End Pressure :
        endPressure = segments[segments.length - 1].EndPressure

        // End Flow Rate
        lastFlowRate = segments[segments.length - 1].FlowRate

        //End Velocity
        endVelocity = endFlushingVelocity

        // Build Results for flushing with given inlet pressure
        if (segment_index_for_desired_inlet_pressure != undefined) {

            // Max/Min pressure for user's input inlet pressure:
            totalMaxPressureForUserInletPressureInput = segments[segment_index_for_desired_inlet_pressure].InletPressure;
            totalMinPressureForUserInletPressureInput = totalMinPressure

            // total pressure loss for user's input inlet pressure:
            totalPressureLossForUserInletPressureInput = totalMaxPressureForUserInletPressureInput - totalMinPressureForUserInletPressureInput

            // End Pressure for user's input inlet pressure:
            endPressureForUserInletPressureInput = segments[segments.length - 1].EndPressure

            // End Flow Rate for user's input inlet pressure:
            lastFlowRateForUserInletPressureInput = segments[segments.length - 1].FlowRate

            //End Velocity for user's input inlet pressure:
            endVelocityForUserInletPressureInput = endFlushingVelocity

            // calculate total segemnts length
            for (let index = segments.length - 1; index > segment_index_for_desired_inlet_pressure; index--) {
                totalLengthForUserInletPressureInput = Math.round((totalLengthForUserInletPressureInput + segments[index].Length) * 1e12) / 1e12
            }

            result_for_user_inlet_pressure = {
                totalPressureLossForUserInletPressureInput,
                totalMaxPressureForUserInletPressureInput,
                totalMinPressureForUserInletPressureInput,
                maxVelocityForUserInletPressureInput,
                totalLengthForUserInletPressureInput,
                endPressureForUserInletPressureInput,
                lastFlowRateForUserInletPressureInput,
                endVelocityForUserInletPressureInput,
                segment_index_for_desired_inlet_pressure
            }




        }


        return { totalPressureLoss, totalMaxPressure, totalMinPressure, maxVelocity, endPressure, endVelocity, lastFlowRate, result_for_user_inlet_pressure, warnings, errors };
    }

    private setMinMaxFlowRates(flowRates: any) {
        let { minFlowRate, maxFlowRate, flowRate } = flowRates;

        if (flowRate < minFlowRate) {
            flowRates.minFlowRate = flowRate;
        }
        if (flowRate > maxFlowRate) {
            flowRates.maxFlowRate = flowRate;
        }

        return flowRates;
    }


    protected resetSegmentsForFlushing(_segments: any, totalLength: number, data: any, blockChars: any, topographyChars: any, pipeChars: any, isFlushing: boolean): any {
        const dist = _segments[0].Length;
        let total = 0;
        const l = (_segments.length - 1) * dist;
        let segments = [];

        for (let index = 0; index < _segments.length; index++) {
            const s = _segments[index];
            // total += s.Length;
            total = Math.round((total + s.Length) * 1e12) / 1e12
            delete s.EndPressure
            delete s.InletPressure
            delete s.Pressureloss
            s.AtomicFlowRate = 0;

            delete s.FlowRate
            delete s.Frictionloss
            delete s.LocalHeadloss
            delete s.Topoloss
            delete s.TravelTime
            if (index == _segments.length - 1) {
                s.Length = totalLength ? totalLength - l : Math.round((total - l) * 1e12) / 1e12;
            }
            segments.push(s);
        }
        return segments;
    }

    private resetSlopesForFlushing(_slopes: any): any {
        let slopes: any = _slopes.map((s: any) => {
            delete s.EndPressure
            delete s.InletPressure

            return s;
        })

        return slopes;
    }

    private resetEmittersForFlushing(_emitters: any): any {
        let emitters: any = _emitters.map((e: any) => {

            return e;
        })

        return emitters;
    }

    protected resetPipesForFlushing(data: any): any {
        let _pipes = data._pipes

        let pipes: any = _pipes.map((p: any) => {
            delete p.EndPressure
            delete p.InletPressure
            delete p.MinPressure

            p.MaxFlowRate = 0;
            p.MaxPressure = 0;
            p.MaxVelocity = 0;
            p.PressureLoss = 0

            return p;
        })

        return pipes;
    }

    protected calcPipeSumLength(segmentsCalcData) {
        let calcData: any = segmentsCalcData.calcData;
        let segments: Segment[] = segmentsCalcData.segments;
        return segments.length * calcData.blockChars.distance_between_laterals
    }

}