import { AbsValveCalculationProcess } from "./AbsValveCalculationProcess";
import { EUnits } from "../../../../enums/Units.enum";
import { ECalculationErrors } from "../../../errors_and_warnings/errors";

interface DiameterRange {
    id: number;
    range: [number, number]; // Tuple to represent the range as [min, max]
    diameter_mm: string;
    diameter_inch: string; // Using string for mixed fractional/integer values
}

export class ReliefValve extends AbsValveCalculationProcess {
    private readonly DIAMETER_RANGE: DiameterRange[] = [
        { id: 1, range: [0.1, 20], diameter_mm: '20', diameter_inch: '3/4' },
        { id: 2, range: [20.1, 25], diameter_mm: '25', diameter_inch: '1' },
        { id: 3, range: [25.1, 40], diameter_mm: '40', diameter_inch: '1.5' },
        { id: 4, range: [40.1, 50], diameter_mm: '50', diameter_inch: '2' },
        { id: 5, range: [50.1, 60], diameter_mm: '60', diameter_inch: '2.5' },
        { id: 6, range: [60.1, 80], diameter_mm: '80', diameter_inch: '3' },
        { id: 7, range: [80.1, 100], diameter_mm: '100', diameter_inch: '4' },
        { id: 8, range: [100.1, 150], diameter_mm: '150', diameter_inch: '6' },
        { id: 9, range: [150.1, 200], diameter_mm: '200', diameter_inch: '8' },
        { id: 10, range: [200.1, 250], diameter_mm: '250', diameter_inch: '10' },
        { id: 11, range: [250.1, 300], diameter_mm: '300', diameter_inch: '12' },
        { id: 12, range: [300.1, 350], diameter_mm: '350', diameter_inch: '14' },
        { id: 13, range: [350.1, 400], diameter_mm: '400', diameter_inch: '16' },
    ];

    public calculate(calculationData: any) {
        calculationData.notes = [];
        calculationData.warnings = [];
        calculationData.errors = [];


        const {
            notes,
            warnings,
            errors,
            reliefFlow,
            reliefPressure,
            units
        } = calculationData;

        const requestParameters = {
            reliefFlow,
            reliefPressure,
            units
        };

        const valveSize = this.calculateValveSize(calculationData);

        const diameterRange = this.findDiameterRange(valveSize);

        if (!diameterRange) {
            this.addError(errors, ECalculationErrors.QUICK_RELEASE_VALVE_SIZE_NO_VALVE_FOUND);
        }

        const calcResults = {
            calculationResults: {
                notes,
                warnings,
                errors,
                requestParameters,
                diameter_mm: diameterRange?.diameter_mm,
                diameter_inch: diameterRange?.diameter_inch,
            }
        };

        return { calcResults };
    }

    private calculateValveSize(calculationData: any) {
        const { reliefFlow, reliefPressure, units } = calculationData;
        let valveSize = 0;
        switch (units) {
            case EUnits.US_UNITS:
                valveSize = this.calculateValveSizeUS(reliefFlow, reliefPressure);
                break;
            case EUnits.METRIC:
                valveSize = this.calculateValveSizeMetric(reliefFlow, reliefPressure);
                break;
        }
        return valveSize;
    }

    private calculateValveSizeUS(reliefFlow: number, reliefPressure: number): number {
        return Math.sqrt((0.109 * reliefFlow) / Math.sqrt(reliefPressure)) * 25.4;
    }

    private calculateValveSizeMetric(reliefFlow: number, reliefPressure: number): number {
        return Math.sqrt((250 * reliefFlow) / Math.sqrt(reliefPressure));
    }

    private findDiameterRange(diameter: number): DiameterRange {
        const roundedDiameter = Math.round(diameter * 10) / 10;
        return this.DIAMETER_RANGE.find((range) => roundedDiameter >= range.range[0] && roundedDiameter <= range.range[1]);
    }

}