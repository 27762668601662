
export enum EConvertionTypes {
    NONE = 0,
    PRESSURE = 1,
    VELOCITY,
    LENGTH,
    DIAMETER,
    CUBIC_FLOW_RATE,
    LITER_FLOW_RATE
}
export enum CalculatorTypes {
    NONE = 0,
    SUBMAIN,
    LATERAL,
    MAINLINE
}

export class UnitsConverter {

    public static readonly METERS_TO_PSI_RATE = 1.4219;
    public static readonly METERS_PER_SEC_TO_FT_PER_SEC_RATE = 3.2808;
    public static readonly METERS_TO_FT_RATE = 3.2808;
    public static readonly CUBIC_TO_GPM_RATE = 4.402868;
    public static readonly LETERS_PER_HOUR_TO_GPM_RATE = 0.0044;
    public static readonly MM_TO_INCH_RATE = 0.03937;

    public static convertCalculationInputsToUSUnits(inputs) {
        inputs.forEach(calcInput => {
            calcInput = this.convertUnits(calcInput, true);
        });

        return inputs;
    }

    public static convertCalculationResultsToUSUnits(result: any): any {
        let converted = result;
        let isReverse = false;
        let calculator_id = result.calculator
        // Calculation results:
        let calcResults = converted.calcResults;
        let isLateralCalculation = calcResults.emitter && calcResults.emitter.model;
        if (calcResults) {
            // calculationResults:
            let summary = calcResults.calculationResults;
            if (summary) {
                let isErrors = summary.errors && summary.errors.length > 0;
                if (!isErrors) {
                    // emittersAvgFlowRate:
                    let emittersAvgFlowRate = summary.emittersAvgFlowRate || null;
                    if (emittersAvgFlowRate) {
                        emittersAvgFlowRate = UnitsConverter.literToGalon(emittersAvgFlowRate, isReverse, true);
                        summary.emittersAvgFlowRate = emittersAvgFlowRate;
                    }

                    // endPressure:
                    let endPressure = summary.endPressure || null;
                    if (endPressure) {
                        endPressure = UnitsConverter.metersToPSI(endPressure, isReverse, true);
                        summary.endPressure = endPressure;
                    }

                    // inletPressure:
                    let inletPressure = summary.inletPressure || null;
                    if (inletPressure) {
                        inletPressure = UnitsConverter.metersToPSI(inletPressure, isReverse, true);
                        summary.inletPressure = inletPressure;
                    }


                    // max velocity:
                    let maxVelocity = summary.maxVelocity || null;
                    if (maxVelocity) {
                        maxVelocity = UnitsConverter.metersPerSecondToFtPerSecond(maxVelocity, isReverse, true);
                        summary.maxVelocity = maxVelocity;
                    }

                    // total length:
                    let totalLength = summary.totalLength || null;
                    if (totalLength) {
                        totalLength = UnitsConverter.metersToFt(totalLength, isReverse, true);
                        summary.totalLength = totalLength;
                    }

                    // total max pressure:
                    let totalMaxPressure = summary.totalMaxPressure || null;
                    if (totalMaxPressure) {
                        totalMaxPressure = UnitsConverter.metersToPSI(totalMaxPressure, isReverse, true);
                        summary.totalMaxPressure = totalMaxPressure;
                    }
                    // total min pressure:
                    let totalMinPressure = summary.totalMinPressure || null;
                    if (totalMinPressure) {
                        totalMinPressure = UnitsConverter.metersToPSI(totalMinPressure, isReverse, true);
                        summary.totalMinPressure = totalMinPressure;
                    }
                    // total pressure loss:
                    let totalPressureLoss = summary.totalPressureLoss || null;
                    if (totalPressureLoss) {
                        totalPressureLoss = UnitsConverter.metersToPSI(totalPressureLoss, isReverse, true);
                        summary.totalPressureLoss = totalPressureLoss;
                    }
                }

                calcResults.calculationResults = summary;
            }

            // Pipes:
            let pipes = calcResults.pipes;
            if (pipes && pipes.length > 0) {
                pipes.forEach(pipe => {
                    // endPressure:
                    let endPressure = pipe.EndPressure || null;
                    if (endPressure) {
                        endPressure = UnitsConverter.metersToPSI(endPressure, isReverse, true);
                        pipe.endPressure = endPressure;
                    }
                    // Inlet pressure:
                    let InletPressure = pipe.InletPressure || null;
                    if (InletPressure) {
                        InletPressure = UnitsConverter.metersToPSI(InletPressure, isReverse, true);
                        pipe.InletPressure = InletPressure;
                    }
                    // Internal Diameter
                    let InternalDiameter = pipe.InternalDiameter || null;
                    if (InternalDiameter) {
                        InternalDiameter = UnitsConverter.mmToInch(InternalDiameter, isReverse, true);
                        pipe.InternalDiameter = InternalDiameter;
                    }
                    // Nominal Diameter
                    let NominalDiameter = pipe.NominalDiameter || null;
                    if (NominalDiameter) {
                        NominalDiameter = UnitsConverter.mmToInch(NominalDiameter, isReverse, true);
                        pipe.NominalDiameter = NominalDiameter;
                    }
                    // max allowed pressure:
                    let MaxAllowedPressure = pipe.MaxAllowedPressure || null;
                    if (MaxAllowedPressure) {
                        MaxAllowedPressure = Number(MaxAllowedPressure);
                        MaxAllowedPressure = UnitsConverter.metersToPSI(MaxAllowedPressure, isReverse, true);
                        pipe.MaxAllowedPressure = MaxAllowedPressure.toString();
                    }
                    // max allowed velocity:
                    let MaxAllowedVelocity = pipe.MaxAllowedVelocity || null;
                    if (MaxAllowedVelocity) {
                        MaxAllowedVelocity = UnitsConverter.metersPerSecondToFtPerSecond(MaxAllowedVelocity, isReverse, true);
                        pipe.MaxAllowedVelocity = MaxAllowedVelocity;
                    }
                    // MaxFlowRate:
                    let MaxFlowRate = pipe.MaxFlowRate || null;
                    if (MaxFlowRate) {
                        MaxFlowRate = UnitsConverter.lateralFlowRateConversion(MaxFlowRate, isReverse); // Pipes flow rate conversion
                        pipe.MaxFlowRate = MaxFlowRate;
                    }
                    // max pressure:
                    let MaxPressure = pipe.MaxPressure || null;
                    if (MaxPressure) {
                        MaxPressure = UnitsConverter.metersToPSI(MaxPressure, isReverse, true);
                        pipe.MaxPressure = MaxPressure;
                    }
                    // max velocity:
                    let MaxVelocity = pipe.MaxVelocity || null;
                    if (MaxVelocity) {
                        MaxVelocity = UnitsConverter.metersPerSecondToFtPerSecond(MaxVelocity, isReverse, true);
                        pipe.MaxVelocity = MaxVelocity;
                    }
                    // min pressure:
                    let MinPressure = pipe.MinPressure || null;
                    if (MinPressure) {
                        MinPressure = UnitsConverter.metersToPSI(MinPressure, isReverse, true);
                        pipe.MinPressure = MinPressure;
                    }
                    // Pressure loss:
                    let PressureLoss = pipe.PressureLoss || null;
                    if (PressureLoss) {
                        PressureLoss = UnitsConverter.metersToPSI(PressureLoss, isReverse, true);
                        pipe.PressureLoss = PressureLoss;
                    }
                    // Section length:
                    let SectionLength = pipe.SectionLength || null;
                    if (SectionLength) {
                        SectionLength = UnitsConverter.metersToFt(SectionLength, isReverse, true);
                        pipe.SectionLength = SectionLength;
                    }

                    // Atomic Flow Rate:
                    let AtomicFlowRate = pipe.AtomicFlowRate || null;
                    if (AtomicFlowRate) {
                        AtomicFlowRate = UnitsConverter.cubicToGallon(AtomicFlowRate, isReverse, true);
                        pipe.AtomicFlowRate = AtomicFlowRate;
                    }

                    // Accumulated Flow Rate:
                    let accumulatedFlowRate = pipe.accumulatedFlowRate || null;
                    if (accumulatedFlowRate) {
                        accumulatedFlowRate = UnitsConverter.cubicToGallon(accumulatedFlowRate, isReverse, true);
                        pipe.accumulatedFlowRate = accumulatedFlowRate;
                    }
                });

                calcResults.pipes = pipes;


            }

            // Slopes:
            let slopes = calcResults.slopes;
            if (slopes && slopes.length > 0) {
                slopes.forEach(slope => {
                    // endPressure:
                    let endPressure = slope.EndPressure || null;
                    if (endPressure) {
                        endPressure = UnitsConverter.metersToPSI(endPressure, isReverse, true);
                        slope.EndPressure = endPressure;
                    }
                    // Inlet pressure:
                    let InletPressure = slope.InletPressure || null;
                    if (InletPressure) {
                        InletPressure = UnitsConverter.metersToPSI(InletPressure, isReverse, true);
                        slope.InletPressure = InletPressure;
                    }

                    // Section real length:
                    let SectionRealLength = slope.SectionRealLength || null;
                    if (SectionRealLength) {
                        SectionRealLength = UnitsConverter.metersToFt(SectionRealLength, isReverse, true);
                        slope.SectionRealLength = SectionRealLength;
                    }
                    // Section length on map:
                    let SectionLengthOnMap = slope.SectionLengthOnMap || null;
                    if (SectionLengthOnMap) {
                        SectionLengthOnMap = UnitsConverter.metersToFt(SectionLengthOnMap, isReverse, true);
                        slope.SectionLengthOnMap = SectionLengthOnMap;
                    }

                    // Section heigth diff in meters:
                    let HeightDiffInMeters = slope.HeightDiffInMeters || null;
                    if (HeightDiffInMeters) {
                        HeightDiffInMeters = UnitsConverter.metersToFt(HeightDiffInMeters, isReverse, true);
                        slope.HeightDiffInMeters = HeightDiffInMeters;
                    }
                });

                calcResults.slopes = slopes;
            }

            // Segments:
            let segments = calcResults.segments;
            if (segments && segments.length > 0) {
                segments.forEach(segment => {
                    // endPressure:
                    let endPressure = segment.EndPressure || null;
                    if (endPressure) {
                        endPressure = UnitsConverter.metersToPSI(endPressure, isReverse, false);
                        segment.EndPressure = endPressure;
                    }
                    // Inlet pressure:
                    let InletPressure = segment.InletPressure || null;
                    if (InletPressure) {
                        InletPressure = UnitsConverter.metersToPSI(InletPressure, isReverse, false);
                        segment.InletPressure = InletPressure;
                    }
                    // Atomic FlowRate:
                    let AtomicFlowRate = segment.AtomicFlowRate || null;
                    if (AtomicFlowRate) {
                        AtomicFlowRate = calculator_id == CalculatorTypes.LATERAL ? UnitsConverter.lateralFlowRateConversion(AtomicFlowRate, isReverse, false) : UnitsConverter.cubicToGallon(AtomicFlowRate, isReverse, true);
                        segment.AtomicFlowRate = AtomicFlowRate;
                    }
                    // Segment length:
                    let Length = segment.Length || null;
                    if (Length) {
                        Length = UnitsConverter.metersToFt(Length, isReverse, false);
                        segment.Length = Length;
                    }

                    //Accumlated FlowRate:
                    let FlowRate = segment.FlowRate || null;
                    if (AtomicFlowRate) {
                        FlowRate = calculator_id == CalculatorTypes.LATERAL ? UnitsConverter.lateralFlowRateConversion(FlowRate, isReverse, false) : UnitsConverter.cubicToGallon(FlowRate, isReverse, true);
                        segment.FlowRate = FlowRate;
                    }
                });
            }
            let endPressure = calcResults.segments[calcResults.segments.length - 1].EndPressure || null;
            if (endPressure) {
                endPressure = UnitsConverter.metersToPSI(endPressure, isReverse, true);
                calcResults.segments[calcResults.segments.length - 1].EndPressure = endPressure;
            }

            converted.calcResults = calcResults;
        }

        // Flushing results:
        let flushing = converted.flushing;
        if (flushing) {
            // totalPressureLoss
            let totalPressureLoss = flushing.flushingResults.totalPressureLoss || null;
            if (totalPressureLoss) {
                totalPressureLoss = UnitsConverter.metersToPSI(totalPressureLoss, isReverse, true);
                flushing.flushingResults.totalPressureLoss = totalPressureLoss;
            }
            // totalMaxPressure
            let totalMaxPressure = flushing.flushingResults.totalMaxPressure || null;
            if (totalMaxPressure) {
                totalMaxPressure = UnitsConverter.metersToPSI(totalMaxPressure, isReverse, true);
                flushing.flushingResults.totalMaxPressure = totalMaxPressure;
            }
            // maxVelocity
            let maxVelocity = flushing.flushingResults.maxVelocity || null;
            if (maxVelocity) {
                maxVelocity = UnitsConverter.metersToPSI(maxVelocity, isReverse, true);
                flushing.flushingResults.maxVelocity = maxVelocity;
            }

            // flushingSegments[0].InletPressure:
            let inletPressure = flushing.flushingSegments[0] ? flushing.flushingSegments[0].InletPressure : null;
            if (inletPressure) {
                inletPressure = UnitsConverter.metersToPSI(inletPressure, isReverse, true);
                flushing.flushingSegments[0].InletPressure = inletPressure;
            }
            // flushingSegments[0].FlowRate:
            let FlowRate = flushing.flushingSegments[0] ? flushing.flushingSegments[0].FlowRate : null;
            if (FlowRate) {
                let flow = Number((FlowRate * 1000).toFixed(2));
                FlowRate = UnitsConverter.lateralFlowRateConversion(flow, isReverse);
                flushing.flushingSegments[0].FlowRate = FlowRate / 1000;
            }

            // end pressure
            let flushingEndPressure = flushing.flushingResults.endPressure || null;;
            if (flushingEndPressure) {
                flushingEndPressure = UnitsConverter.metersToPSI(flushingEndPressure, isReverse, true);
                flushing.flushingResults.endPressure = flushingEndPressure;
            }
            // flushing end velocity
            let flushing_end_velocity = flushing.flushingResults.endVelocity
            if (flushing_end_velocity) {
                flushing_end_velocity = UnitsConverter.metersPerSecondToFtPerSecond(flushing_end_velocity, isReverse, true);
                flushing.flushingResults.endVelocity = flushing_end_velocity;
            }

            // last flow rate
            let lastFlowRate = flushing.flushingResults.lastFlowRate ? flushing.flushingResults.lastFlowRate : null;
            if (lastFlowRate) {
                let flow = Number((lastFlowRate * 1000).toFixed(2));
                FlowRate = UnitsConverter.lateralFlowRateConversion(flow, isReverse);
                flushing.flushingResults.lastFlowRate = FlowRate / 1000;
            }

            // Flushing with given inlet pressure results:

            if (flushing.flushingResults.result_for_user_inlet_pressure) {
                // totalPressureLoss with given inlet pressure
                let totalPressureLoss = flushing.flushingResults.result_for_user_inlet_pressure.totalPressureLossForUserInletPressureInput || null;
                if (totalPressureLoss) {
                    totalPressureLoss = UnitsConverter.metersToPSI(totalPressureLoss, isReverse, true);
                    flushing.flushingResults.result_for_user_inlet_pressure.totalPressureLossForUserInletPressureInput = totalPressureLoss;
                }
                // totalMaxPressure with given inlet pressure
                let totalMaxPressure = flushing.flushingResults.result_for_user_inlet_pressure.totalMaxPressureForUserInletPressureInput || null;
                if (totalMaxPressure) {
                    totalMaxPressure = UnitsConverter.metersToPSI(totalMaxPressure, isReverse, true);
                    flushing.flushingResults.result_for_user_inlet_pressure.totalMaxPressureForUserInletPressureInput = totalMaxPressure;
                }
                // maxVelocity with given inlet pressure
                let maxVelocity = flushing.flushingResults.result_for_user_inlet_pressure.maxVelocityForUserInletPressureInput || null;
                if (maxVelocity) {
                    maxVelocity = UnitsConverter.metersToPSI(maxVelocity, isReverse, true);
                    flushing.flushingResults.result_for_user_inlet_pressure.maxVelocityForUserInletPressureInput = maxVelocity;
                }

                // inlet flow rate with given inlet pressure
                let FlowRate = flushing.flushingSegments[flushing.flushingResults.result_for_user_inlet_pressure.segment_index_for_desired_inlet_pressure] ? flushing.flushingSegments[flushing.flushingResults.result_for_user_inlet_pressure.segment_index_for_desired_inlet_pressure].FlowRate : null;
                if (FlowRate) {
                    let flow = Number((FlowRate * 1000).toFixed(2));
                    FlowRate = UnitsConverter.lateralFlowRateConversion(flow, isReverse);
                    flushing.flushingSegments[flushing.flushingResults.result_for_user_inlet_pressure.segment_index_for_desired_inlet_pressure].FlowRate = FlowRate / 1000;
                }

                // end pressure with given inlet pressure
                let flushingEndPressure = flushing.flushingResults.result_for_user_inlet_pressure.endPressureForUserInletPressureInput || null;;
                if (flushingEndPressure) {
                    flushingEndPressure = UnitsConverter.metersToPSI(flushingEndPressure, isReverse, true);
                    flushing.flushingResults.result_for_user_inlet_pressure.endPressureForUserInletPressureInput = flushingEndPressure;
                }

                // flushing end velocity with given inlet pressure
                let flushing_end_velocity = flushing.flushingResults.result_for_user_inlet_pressure.endVelocityForUserInletPressureInput
                if (flushing_end_velocity) {
                    flushing_end_velocity = UnitsConverter.metersPerSecondToFtPerSecond(flushing_end_velocity, isReverse, true);
                    flushing.flushingResults.result_for_user_inlet_pressure.endVelocityForUserInletPressureInput = flushing_end_velocity;
                }

                // last flow rate with given inlet pressure
                let lastFlowRate = flushing.flushingResults.result_for_user_inlet_pressure.lastFlowRateForUserInletPressureInput || null;
                if (lastFlowRate) {
                    let flow = Number((lastFlowRate * 1000).toFixed(2));
                    FlowRate = UnitsConverter.lateralFlowRateConversion(flow, isReverse);
                    flushing.flushingResults.result_for_user_inlet_pressure.lastFlowRateForUserInletPressureInput = FlowRate / 1000;
                }

                // total length with given inlet pressure
                let totalLengthForUserInletPressureInput = flushing.flushingResults.result_for_user_inlet_pressure.totalLengthForUserInletPressureInput || null;
                if (totalLengthForUserInletPressureInput) {
                    totalLengthForUserInletPressureInput = UnitsConverter.metersToFt(totalLengthForUserInletPressureInput, isReverse, true);
                    flushing.flushingResults.result_for_user_inlet_pressure.totalLengthForUserInletPressureInput = totalLengthForUserInletPressureInput;
                }
            }

            converted.flushing = flushing;
        }




        return converted;
    }

    public static convertEmitterFiltersToMetric(filters: any): any {
        let convertedFilters = filters;
        let isReverse = true;

        // Nominal Diameter
        let nominalDiameter = filters.nominalDiameter || null;
        if (nominalDiameter) {
            nominalDiameter = Number(nominalDiameter);
            nominalDiameter = UnitsConverter.mmToInch(nominalDiameter, isReverse, false);
            convertedFilters.nominalDiameter = nominalDiameter.toString();
        }
        // FlowRate:
        let flowRate = filters.flowRate || null;
        if (flowRate) {
            flowRate = Number(flowRate);
            flowRate = UnitsConverter.lateralFlowRateConversion(flowRate, isReverse, true);
            convertedFilters.flowRate = flowRate;
        }

        return convertedFilters;
    }

    public static convertSubmainBlockFlowRateDataToMetric(data: any): any {
        let isReverse = true;
        let converted = data;

        // firstLateralLength:
        let firstLateralLength = data.firstLateralLength || null;
        if (firstLateralLength) {
            firstLateralLength = UnitsConverter.metersToFt(firstLateralLength, isReverse, false);
            converted.firstLateralLength = firstLateralLength;
        }
        // lastLateralLength:
        let lastLateralLength = data.lastLateralLength || null;
        if (lastLateralLength) {
            lastLateralLength = UnitsConverter.metersToFt(lastLateralLength, isReverse, false);
            converted.lastLateralLength = lastLateralLength;
        }
        // lastLateralFlowRate:
        let lastLateralFlowRate = data.lastLateralFlowRate || null;
        if (lastLateralFlowRate) {
            lastLateralFlowRate = UnitsConverter.literToGalon(lastLateralFlowRate, isReverse, false);
            data.lastLateralFlowRate = lastLateralFlowRate;
        }
        // totalLateralFlowRate:
        let totalLateralFlowRate = data.totalLateralFlowRate || null;
        if (totalLateralFlowRate) {
            totalLateralFlowRate = UnitsConverter.cubicToGallon(totalLateralFlowRate, isReverse, false);
            data.totalLateralFlowRate = totalLateralFlowRate;
        }

        return converted;
    }

    public static converFlushingVelocityTable(table: any): any {
        table.forEach(velocity => {
            velocity.flushing_velocity = this.metersPerSecondToFtPerSecond(velocity.flushing_velocity, false);
        });

        return table;
    }

    public static convertFlowRate(blockFlowRate: number, isTotalFlowRate: boolean): number {
        if (isTotalFlowRate) {
            return this.cubicToGallon(blockFlowRate, false);
        }
        else {
            // Last lateral flow rate:
            return this.literToGalon(blockFlowRate, false);
        }
    }

    public static convertCalculationInputsFromUSUnits(inputs) {
        inputs.forEach(calcInput => {
            calcInput = this.convertUnits(calcInput, false);
        });

        return inputs;
    }

    public static getConversionRates() {
        let conversion_rates = {
            meters_to_psi: UnitsConverter.METERS_TO_PSI_RATE,
            meters_per_sec_to_ft_per_sec: UnitsConverter.METERS_PER_SEC_TO_FT_PER_SEC_RATE,
            meters_to_ft: UnitsConverter.METERS_TO_FT_RATE,
            cubic_to_gpm: UnitsConverter.CUBIC_TO_GPM_RATE,
            leters_per_hour_to_gpm: UnitsConverter.LETERS_PER_HOUR_TO_GPM_RATE,
            mm_to_inch: UnitsConverter.MM_TO_INCH_RATE
        }

        return conversion_rates;
    }

    public static convertPipesToUSUnits(pipes: any) {
        pipes.forEach(pipe => {
            pipe = this.convertSinglePipeToUSUnits(pipe);
        });
    }

    public static convertValvesToUSUnits(valves: any) {
        valves.forEach(valve => {
            valves = this.convertSingleValveToUSUnits(valve);
        });
    }

    public static convertEmittersToUSUnits(emitters: any, isInlineEmitter: any) {
        if (isInlineEmitter) {
            UnitsConverter.convertInlineEmittersToUSUnits(emitters);
        }
        else {
            // Online emitters:
            UnitsConverter.convertOnlineEmittersToUSUnits(emitters);
        }
    }

    public static convertInlineEmittersToUSUnits(emitters: any) {
        emitters.forEach(emitter => {
            emitter = this.convertSingleInlineEmitterToUSUnits(emitter);
        });
    }

    public static convertOnlineEmittersToUSUnits(emitters: any) {
        emitters.forEach(emitter => {
            emitter = this.convertSingleOnlineEmitterToUSUnits(emitter);
        });
    }

    public static convertSingleOnlineEmitterToUSUnits(emitter: any): any {
        emitter.flow_rate = emitter.flow_rate_US;
        delete emitter.flow_rate_US;
        emitter.max_design_pressure = emitter.max_design_pressure_US;
        delete emitter.max_design_pressure_US;
        emitter.max_technical_pressure = emitter.max_technical_pressure_US;
        delete emitter.max_technical_pressure_US;
        emitter.min_design_pressure = emitter.min_design_pressure_US;
        delete emitter.min_design_pressure_US;
        emitter.min_technical_pressure = emitter.min_technical_pressure_US;
        delete emitter.min_technical_pressure_US;

        return emitter;
    }

    public static convertSingleInlineEmitterToUSUnits(emitter: any): any {
        emitter.flow_rate = emitter.flow_rate_US;
        delete emitter.flow_rate_US;

        emitter.max_design_pressure = emitter.max_design_pressure_US;
        delete emitter.max_design_pressure_US;
        emitter.max_technical_pressure = emitter.max_technical_pressure_US;
        delete emitter.max_technical_pressure_US;
        emitter.min_design_pressure = emitter.min_design_pressure_US;
        delete emitter.min_design_pressure_US;
        emitter.min_technical_pressure = emitter.min_technical_pressure_US;
        delete emitter.min_technical_pressure_US;

        emitter.nominal_diameter = emitter.nominal_diameter_US;
        delete emitter.nominal_diameter_US;
        emitter.internal_diameter = emitter.internal_diameter_US;
        delete emitter.internal_diameter_US;

        return emitter;
    }

    public static convertSingleValveToUSUnits(valve: any): any {
        const {minimum_flow_rate_us,maximum_flow_rate_us}=valve
       valve.minimum_flow_rate = minimum_flow_rate_us ? Number((minimum_flow_rate_us * 1).toFixed(2)): valve.minimum_flow_rate_us
       valve.maximum_flow_rate = maximum_flow_rate_us? Number((maximum_flow_rate_us * 1).toFixed(2)): valve.maximum_flow_rate_us
         return valve;
    }

    public static convertSinglePipeToUSUnits(pipe: any): any {
        pipe.nominal_diameter = pipe.nominal_diameter_US;
        pipe.internal_diameter = pipe.internal_diameter_US;
        pipe.max_allowable_design_pressure = pipe.max_allowable_design_pressure_US;
        pipe.max_velocity = pipe.max_velocity_US;

        return pipe;
    }

    public static convertSinglePipeToMetricUnits(pipeData: any): any {
        let isRound = true;
        let isReverse = true;
        pipeData.nominalDiameter = this.mmToInch(pipeData.nominalDiameter, isReverse, isRound);
        pipeData.internalDiameter = this.mmToInch(pipeData.internalDiameter, isReverse, isRound);
        pipeData.maxVelocity = this.metersPerSecondToFtPerSecond(pipeData.maxVelocity, isReverse, isRound);
        pipeData.maxAllowableDesignPressure = this.metersToPSI(pipeData.maxAllowableDesignPressure, isReverse, isRound);

        return pipeData;
    }

    public static convertFiltersToMetricUnits(filters: any): any {
        let isRound = true;
        let isReverse = true;

        if (filters.nominalDiameter) {
            filters.nominalDiameter = this.mmToInch(Number(filters.nominalDiameter), isReverse, isRound);
        }
        if (filters.diameter) {
            filters.diameter = this.mmToInch(Number(filters.diameter), isReverse, isRound);
        }
        if (filters.internalDiameter) {
            filters.internalDiameter = this.mmToInch(Number(filters.internalDiameter), isReverse, isRound);
        }
        if (filters.maxVelocity) {
            filters.maxVelocity = this.metersPerSecondToFtPerSecond(filters.maxVelocity, isReverse, isRound);
        }
        if (filters.maxAllowableDesignPressure) {
            filters.maxAllowableDesignPressure = this.metersToPSI(filters.maxAllowableDesignPressure, isReverse, isRound);
        }

        return filters;
    }

    // ---------------------------------------- PRIVATE METHODS --------------------------------------

    private static convertUnits(calcInput: any, isToUSUnits: boolean) {
        let type: EConvertionTypes = calcInput.conversion_type;

        switch (type) {
            case EConvertionTypes.PRESSURE:
                return this.convertPressureInput(calcInput, !isToUSUnits);

            case EConvertionTypes.VELOCITY:
                return this.convertVelocityInput(calcInput, !isToUSUnits);

            case EConvertionTypes.LENGTH:
                return this.convertLengthInput(calcInput, !isToUSUnits);

            case EConvertionTypes.DIAMETER:
                return this.convertDiameterInput(calcInput, !isToUSUnits);

            case EConvertionTypes.CUBIC_FLOW_RATE:
                return this.convertCubicFlowRateInput(calcInput, !isToUSUnits);

            case EConvertionTypes.LITER_FLOW_RATE:
                return this.convertLiterFlowRateInput(calcInput, !isToUSUnits);

            default:
                return null;
        }
    }

    private static convertCubicFlowRateInput(calcInput: any, isReverse: boolean) {
        calcInput.min_value = this.cubicToGallon(calcInput.min_value, isReverse);
        calcInput.max_value = this.cubicToGallon(calcInput.max_value, isReverse);
        calcInput.default_value = this.cubicToGallon(calcInput.default_value, isReverse);
    }

    private static convertLiterFlowRateInput(calcInput: any, isReverse: boolean) {
        calcInput.min_value = this.literToGalon(calcInput.min_value, isReverse);
        calcInput.max_value = this.literToGalon(calcInput.max_value, isReverse);
        calcInput.default_value = this.literToGalon(calcInput.default_value, isReverse);
    }

    private static convertLengthInput(calcInput: any, isReverse: boolean) {
        calcInput.min_value = this.metersToFt(calcInput.min_value, isReverse);
        calcInput.max_value = this.metersToFt(calcInput.max_value, isReverse);
        calcInput.default_value = this.metersToFt(calcInput.default_value, isReverse);
    }

    private static convertDiameterInput(calcInput: any, isReverse: boolean) {
        calcInput.min_value = this.mmToInch(calcInput.min_value, isReverse);
        calcInput.max_value = this.mmToInch(calcInput.max_value, isReverse);
        calcInput.default_value = this.mmToInch(calcInput.default_value, isReverse);
    }

    private static convertPressureInput(calcInput: any, isReverse: boolean) {
        calcInput.min_value = this.metersToPSI(calcInput.min_value, isReverse);
        calcInput.max_value = this.metersToPSI(calcInput.max_value, isReverse);
        calcInput.default_value = this.metersToPSI(calcInput.default_value, isReverse);
    }

    private static convertVelocityInput(calcInput: any, isReverse: boolean) {
        calcInput.min_value = this.metersPerSecondToFtPerSecond(calcInput.min_value, isReverse);
        calcInput.max_value = this.metersPerSecondToFtPerSecond(calcInput.max_value, isReverse);
        calcInput.default_value = this.metersPerSecondToFtPerSecond(calcInput.default_value, isReverse);
    }

    public static metersToPSI(meters: number, isReverse: boolean, isToRound: boolean = true): number {
        if (meters != null) {
            let psi = isReverse == false ? meters * 1.4219 : meters / 1.4219
            psi = isToRound ? Number(psi.toFixed(2)) : Number(psi);

            return psi;
        }
    }

    public static metersPerSecondToFtPerSecond(val: number, isReverse: boolean, isToRound: boolean = true): number {
        if (val != null && val > 0) {
            let converted = isReverse == false ? val * 3.2808 : val / 3.2808
            converted = isToRound ? Number(converted.toFixed(2)) : Number(converted);

            return converted;
        }
    }

    public static metersToFt(val: number, isReverse: boolean, isToRound: boolean = true): number {
        if (val != null && val > 0) {
            let converted = isReverse == false ? val * 3.2808 : val / 3.2808
            converted = isToRound ? Number(converted.toFixed(2)) : Number(converted);

            return converted;
        }
    }
    
    public static metersToInch(val: number, isReverse: boolean, isToRound: boolean = true): number {
        if (val != null && val > 0) {
            let converted = isReverse == false ? val *39.3701 : val /39.3701
            converted = isToRound ? Number(converted.toFixed(2)) : Number(converted);
            return converted;
        }
    }

    public static mmToInch(val: number, isReverse: boolean, isToRound: boolean = true): number {
        if (val != null && val > 0) {
            let converted = isReverse == false ? val * 0.03937 : val / 0.03937
            converted = isToRound ? Number(converted.toFixed(2)) : Number(converted);

            return converted;
        }
    }

    public static cubicToGallon(val: number, isReverse: boolean, isToRound: boolean = true): number {
        if (val != null && val > 0) {
            let converted = isReverse == false ? val * 4.402868 : val / 4.402868
            // converted = Number(converted.toFixed(2));
            converted = isToRound ? Number(converted.toFixed(2)) : Number(converted);
            return converted;
        }
    }

    public static literToGalon(val: number, isReverse: boolean, isToRound: boolean = true): number {
        // Liter to gallon per minute
        if (val != null && val > 0) {
            let converted = isReverse == false ? val * 0.00440287*60 : val / 0.00440287/60;
            converted = isToRound ? Number(converted.toFixed(2)) : Number(converted);

            return converted;
        }
    }

    public static lateralFlowRateConversion(val: number, isReverse: boolean, isToRound: boolean = true): number {
        if (val != null && val > 0) {
            let converted = isReverse == false ? val * 0.2642 : val / 0.2642
            converted = isToRound ? Number(converted.toFixed(2)) : Number(converted);

            return converted;
        }
    }
}