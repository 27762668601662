enum ECalculationNotes {
  NONE = 0,
  NO_ANGLES_INCLUDED_IN_CALCULATION,
  NO_VALVE_FOUND_VALVE_DIAMETER_CALCULATION,
  NO_CAVITATION_RISK,
  CAVITATION_RISK_THERE_MIGHT_BE_A_CAVITATION_RISK,

}

let notes = [
  { id: ECalculationNotes.NONE, warning: `NONE`, id_translation: -1 },
  {
    id: ECalculationNotes.NO_ANGLES_INCLUDED_IN_CALCULATION,
    note: `Head loss calculation is only for the valve itself. It doesn't include the angles before and/or after the valve.`,
    // random id by me. need to update
    id_translation: 803,
  },
  {
    id: ECalculationNotes.NO_VALVE_FOUND_VALVE_DIAMETER_CALCULATION,
    note: `There is no Valve match your search criteria.`,
    // random id by me. need to update
    id_translation: 804,
  },
  {
    id: ECalculationNotes.NO_CAVITATION_RISK,
    note: `OK - There is no cavitation risk.`,
    id_translation: 911,
  },
  { Id: ECalculationNotes.CAVITATION_RISK_THERE_MIGHT_BE_A_CAVITATION_RISK, warning: `There might be a cavitation risk. Contact us for a solution`, id_translation: 912 },
];

class CalculationNotes {
  public static get(noteId: ECalculationNotes) {
    return notes[noteId];
  }
}

export { CalculationNotes, ECalculationNotes };
