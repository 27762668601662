import { IExternalTool } from "../../hydrocalc-code/db/interfaces/externalTools.interface";
import { AbsDAL } from "./Base/AbsDAL";

const AWS = require("aws-sdk");

export class ExternalToolsDAL extends AbsDAL {
  private static readonly EXTERNAL_TOOLS_TABLE_NAME = "ExternalTools";

  docClient = new AWS.DynamoDB.DocumentClient({
    region: "eu-west-1",
    apiVersion: "2012-08-10",
  });

  table = ExternalToolsDAL.EXTERNAL_TOOLS_TABLE_NAME;

  async getAllExternalTools(): Promise<IExternalTool[]> {
    try {
      const params = {
        TableName: this.table,
      };
      const res = await this.docClient.scan(params).promise();
      const sortedExternalTools = res.Items.sort(
        ({ id: id1 }, { id: id2 }) => id1 - id2
      );
      return sortedExternalTools;
    } catch (err) {
      throw err;
    }
  }

  async getSelectedExternalTools(): Promise<IExternalTool[]> {
    try {
      const allTools: IExternalTool[] = await this.getAllExternalTools();
      return allTools.filter(({ isSelected }) => isSelected);
    } catch (err) {
      throw err;
    }
  }

  async getExternalToolsByIds(ids: number[]): Promise<IExternalTool[]> {
    try {
      const tools: IExternalTool[] = await this.getAllExternalTools();
      return ids.reduce((acc, id) => {
        const match = tools.find(({ id: _id }) => id === _id);
        acc.push(match);
        return acc;
      }, [] as IExternalTool[]);
    } catch (err) {
      throw err;
    }
  }

  async updateExternalTools(toolsToUpdate: IExternalTool[]): Promise<boolean> {
    try {
      const res = await Promise.all(
        toolsToUpdate.map(async (tool) => {
          const updatedTool = await this.updateExternalTool(tool);
          return updatedTool;
        })
      );
      return true;
    } catch (err) {
      throw err;
    }
  }

  private async updateExternalTool(
    tool: IExternalTool
  ): Promise<IExternalTool> {
    try {
      const { id: toolId, isSelected } = tool;
      const params = {
        TableName: this.table,
        Key: { id: toolId },
        UpdateExpression: "set isSelected = :val1",
        ExpressionAttributeValues: { ":val1": isSelected },
        ReturnValues: "ALL_NEW",
      };

      let res = await this.docClient.update(params).promise();
      const updatedTool = res.Attributes;
      return updatedTool;
    } catch (err) {
      throw err;
    }
  }
}
