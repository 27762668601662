import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TextService } from "src/app/services/text.service";
import { DataService } from "src/app/services/data.service";
import { PdfService } from "src/app/services/pdf.service";
import { OfflineService } from "src/app/services/offline.service";
import { EtransaltionGroups } from 'src/app/shared/enum';
import { CalculatorService } from 'src/app/services/calculator.service';
import { NavigationEnd, Router } from '@angular/router';
import { utils, writeFile } from "xlsx";
import * as html2pdf from "html2pdf.js";
import { BreadcrumbItem } from "src/app/components/breadcrumbs/breadcrumbs.component"

@Component({
  selector: 'app-product-finder',
  templateUrl: './product-finder.component.html',
  styleUrls: ['./product-finder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductFinderComponent implements OnInit {
  @ViewChild('pdf_to_export') pdfToExport: ElementRef
  EtransaltionGroups = EtransaltionGroups;
  tableButtons = []
  tableColumns = [];
  searchInputs = [];
  valves = [];
  descriptionGuideLink: string = "";
  dateDisplay: string = "";
  isExportingEnabled: boolean = false;
  mySubscription: any;
  user_id;
  breadcrumbs: BreadcrumbItem[];

  constructor(
    public textService: TextService,
    public dataService: DataService,
    public calculatorService: CalculatorService,
    public offlineService: OfflineService,
    public pdfService: PdfService,
    public router: Router
  ) {
    this.dataService.resetValveCalculator.subscribe((res) => {
      this.router.navigateByUrl("calc/valve/product-finder");
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnInit(): void {
    this.getPageData();
    this.setTableButtons();
    this.setTableColumns();
    this.buildDateStr();
    this.setBreadcrumbs();
  }

  private async getPageData() {
    this.dataService.showLoader = true
    this.calculatorService.getProductFinderData().subscribe((resp) => {
      if (resp.success) {
        this.setResponsePageData(resp);
        this.getValveCalculatorData();
      }
    },
      (error) => {
        this.dataService.showLoader = false;
        if (error.message) {
          this.dataService.error_result_msg = error.message;
        }
        if (error.ErrorToClient) {
          this.dataService.error_result_msg = error.ErrorToClient;
        }
        this.dataService.showErrorPopup = true;
      }
    );
  };

  private setBreadcrumbs() {
    this.breadcrumbs = [
      {
        label: this.textService.getText(
          EtransaltionGroups.HOME,
          "home_valve"
        ),
        link: "/calc/valve"
      },
      {
        label: this.textService.getText(
          EtransaltionGroups.VALVE,
          "valves_product_finder_title"
        ),
      }
    ]
  }

  private setResponsePageData(resp) {
    this.valves = resp.result.valves || [];
    this.isExportingEnabled = this.valves.length > 0 && this.searchInputs.some((input) => input.searchTerm);
    this.descriptionGuideLink = resp.result.descriptionGuide || "";
  }

  private async getValveCalculatorData() {
    if (this.offlineService.isItOfflineMode) {
    } else {
      this.calculatorService.getCalculatorPageDataValve().subscribe((resp) => {
        this.setResponseValveCalculatorPageData(resp);
      });
    }
    this.getValves();
  }

  private setResponseValveCalculatorPageData(resp) {
    if (resp.success) {
      this.user_id = resp.result.page_data.user.id;
      // this.analyticsEvent();
      this.dataService.user_name =
        resp.result.page_data.user.first_name +
        " " +
        resp.result.page_data.user.last_name;

      this.dataService.regions_list = resp.result.page_data.regions;
      this.dataService.selected_units = this.dataService.units_list.find(
        (value) => {
          return value.value === Number(resp.result.page_data.user.units);
        }
      );
      this.dataService.selected_region = this.dataService.regions_list.find(
        (value) => {
          return resp.result.page_data.user.region === value.region_id;
        }
      );
      this.dataService.languages_list = resp.result.page_data.languages;
      this.dataService.selected_language = this.dataService.languages_list.find(
        (value) => {
          return (
            value.row_id === resp.result.page_data.user.language.toString()
          );
        }
      );
      this.getTexts();
    }
  }

  getTexts() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = this.textService.getResourceTextOffline();
      this.setTextResponse({
        result: response,
        success: response ? true : false,
      });
      if (this.valves) {
        this.dataService.showLoader = false;
      }
    } else {
      this.textService
        .getResourceText(this.dataService.selected_language.row_id)
        .subscribe((res) => {
          if (this.valves) {
            this.dataService.showLoader = false;
          }
          this.setTextResponse(res);
        });
    }
  }

  setTextResponse(res) {
    if (res.success) {
      this.textService.translation_text = res.result.translations
        ? res.result.translations
        : res.result;
      localStorage.setItem(
        "resource_txt",
        JSON.stringify(this.textService.translation_text)
      );
      this.textService.setUnitsDictionary();
      this.dataService.logic_text_or = this.textService
        .getText(this.EtransaltionGroups.SUBMAIN, "submain_or_1")
        .toString();
    }
  }

  private setTableButtons() {
    this.tableButtons = [
      {
        text: this.textService.getText(EtransaltionGroups.VALVE, "reset_search"),
        click: this.resetSearch.bind(this),
        isShowOnMobile: true
      },
      {
        text: this.textService.getText(EtransaltionGroups.VALVE, "valve_results_export_pdf"),
        click: this.exportToPDF.bind(this),
        isShowOnMobile: true
      },
      {
        text: this.textService.getText(EtransaltionGroups.VALVE, "product_finder_export_excel"),
        click: this.exportToExcel.bind(this),
        isShowOnMobile: false
      }
    ];
  }

  private setTableColumns() {
    this.tableColumns = [
      {
        display: this.textService.getText(EtransaltionGroups.VALVE, "product_finder_material_description"),
        key: "material_description",
        searchable: true,
        isPreciseSearch: false
      },
      { display: this.textService.getText(EtransaltionGroups.VALVE, "product_finder_model"), key: "valve_model", searchable: true, isPreciseSearch: false },
      { display: this.textService.getText(EtransaltionGroups.VALVE, "product_finder_diameter_mm"), key: "diameter_mm", searchable: true, isPreciseSearch: true },
      { display: this.textService.getText(EtransaltionGroups.VALVE, "product_finder_diameter_inch"), key: "diameter_inch", searchable: true, isPreciseSearch: true },
      { display: this.textService.getText(EtransaltionGroups.VALVE, "product_finder_material"), key: "valve_material", searchable: true, isPreciseSearch: false },
      { display: this.textService.getText(EtransaltionGroups.VALVE, "product_finder_conection_type"), key: "connection_type", searchable: true, isPreciseSearch: false },
      { display: this.textService.getText(EtransaltionGroups.VALVE, "product_finder_function"), key: "common_function", searchable: true, isPreciseSearch: false },
      { display: this.textService.getText(EtransaltionGroups.VALVE, "product_finder_family"), key: "valve_family", searchable: true, isPreciseSearch: false },
    ];

    this.searchInputs = [
      { inputName: "materialDescription", searchTerm: "", type: "text" },
      { inputName: "model", searchTerm: "", type: "text" },
      { inputName: "diameterMm", searchTerm: "", type: "text" },
      { inputName: "diameterInch", searchTerm: "", type: "text" },
      { inputName: "material", searchTerm: "", type: "text" },
      { inputName: "connectionType", searchTerm: "", type: "text" },
      { inputName: "commonFunction", searchTerm: "", type: "text" },
      { inputName: "family", searchTerm: "", type: "text" },
    ];
  }

  private buildDateStr() {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    this.dateDisplay = `${day}-${months[month]}-${year}`;
  }


  private getSearchFilters() {
    return this.searchInputs.reduce((acc, input) => {
      if (input.searchTerm) {
        acc[input.inputName] = input.searchTerm.replace(/''|["“”]/g, '"');
      }
      return acc;
    }, {});
  }

  private getValves(filters = {}) {
    this.calculatorService.getProductFinderData({ filters }).subscribe((resp) => {
      this.dataService.showLoader = false;
      if (resp.success) {
        this.setResponsePageData(resp);
      }
    },
      (error) => {
        this.dataService.showLoader = false;
        if (error.message) {
          this.dataService.error_result_msg = error.message;
        }
        if (error.ErrorToClient) {
          this.dataService.error_result_msg = error.ErrorToClient;
        }
        this.dataService.showErrorPopup = true;
      }
    );
  }

  exportToExcel() {
    const content: Element = this.pdfToExport.nativeElement;
    let worksheet = utils.table_to_sheet(content);
    let workbook = utils.book_new();
    workbook.SheetNames.push("product-finder");
    workbook.Sheets["product-finder"] = worksheet;
    writeFile(workbook, "product-finder.xlsx")
  }

  exportToPDF() {
    this.dataService.showLoader = true;
    const inlineAllStyles = (element: HTMLElement) => {
      const allElements = element.querySelectorAll('*') as NodeListOf<HTMLElement>;
      const stylesToCopy = [
        'min-height', 'width', 'padding', 'margin', 'display', 'background-color',
        'color', 'font-size', 'font-weight', 'line-height', 'text-align',
        'text-overflow', 'white-space', 'word-wrap', 'flex-direction',
        'justify-content', 'align-items', 'align-self', 'text-wrap',
        'flex', 'flex-direction', 'align-items', 'justify-content', 'align-self',
        'table-layout', 'overflow', 'border-collapse',
        'height', 'max-width', 'min-width', 'padding-right', 'padding-left',
        'padding-top', 'padding-bottom', 'max-height', 'width', 'gap',
        'background', 'border', 'border-bottom', 'box-shadow',
        'font-family', 'text-align', 'text-overflow', 'text-wrap'
      ];


      allElements.forEach((elem) => {
        const computedStyles = window.getComputedStyle(elem);
        stylesToCopy.forEach((style) => {
          elem.style.setProperty(style, computedStyles.getPropertyValue(style));
        });
      });
    }

    const pdfElement = this.pdfToExport.nativeElement;

    // Inline all styles to ensure they are captured
    inlineAllStyles(pdfElement);

    const options = {
      filename: 'product-finder-results.pdf',
      image: { type: 'png', quality: 1.0 },
      html2canvas: {
        scale: 2,             // Improve quality
        useCORS: true,        // Enable cross-origin
        backgroundColor: null, // Capture background styles (e.g., shadows, gradients)
        x: 0,   // Horizontal offset
        y: 0    // Vertical offset to avoid extra space
      },
      jsPDF: {
        orientation: this.dataService.isItMobile ? 'portrait' : 'landscape',
        unit: 'mm',
        format: 'a4'
      },
      pagebreak: {
        avoid: '.pdf-table-row',
      },
    };

    // Generate PDF using html2pdf.js
    html2pdf().set(options).from(pdfElement).save();
    this.dataService.showLoader = false;
  }

  resetSearch() {
    this.searchInputs.forEach((input) => {
      input.searchTerm = "";
    });
    this.isExportingEnabled = false;
    this.search();
  }

  search() {
    this.dataService.showLoader = true;
    const filters = this.getSearchFilters();
    this.getValves(filters);
  }


}
